<template>
  <svg width="23" height="15" viewBox="-13 254 22 15">
    <g>
      <path
        class="view-count-fill"
        d="M8.8,261.3c-1.9-4.3-6.1-7.3-11-7.3c-4.9,0-9,3-11,7.3c-0.1,0.1-0.1,0.3,0,0.4c1.9,4.3,6.1,7.3,11,7.3    c4.9,0,9-3,11-7.3C8.9,261.6,8.9,261.5,8.8,261.3z M-2.1,266.7c-2.7,0-5-2.3-5-5.2c0-2.9,2.2-5.2,5-5.2c2.7,0,5,2.3,5,5.2    C2.8,264.4,0.6,266.7-2.1,266.7z M-2.1,258.2c-1.8,0-3.2,1.5-3.2,3.3s1.4,3.3,3.2,3.3c1.8,0,3.2-1.5,3.2-3.3S-0.4,258.2-2.1,258.2    z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
svg {
  display: inline-block;
  margin: 0 auto;
  .view-count-fill {
    fill: #cccccc;
  }
}
</style>
