<template>
  <section class="pros-cons-section">
    <div class="good-bad-wrapper">
      <div id="the_good" class="good-bad gb-type-good">
        <h3 id="pros" class="gb-w-title" data-section-visiblity="1">
          <svg width="23" height="23" class="good-bad-icon" viewBox="4.7 5.2 24 24">
            <use xlink:href="#gb-positive-icon"></use>
          </svg>
          {{ $t('generic.pros') }}
        </h3>
        <ul>
          <li v-for="pro in pros" v-html="pro"></li>
        </ul>
      </div>
      <div id="the_bad" class="good-bad gb-type-bad">
        <h3 id="cons" class="gb-w-title" data-section-visiblity="1">
          <svg width="23" height="23" class="good-bad-icon" viewBox="37.1 5.2 24 24">
            <use xlink:href="#gb-negative-icon"></use>
          </svg>
          {{ $t('generic.cons') }}
        </h3>
        <ul>
          <li v-for="con in cons" v-html="con"></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pros: {
      type: Array,
      required: true,
    },
    cons: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.pros-cons-section {
  position: relative;
  margin-top: 20px;

  .good-bad-wrapper {
    display: flex;
    flex-direction: row;
  }

  .good-bad {
    width: 50%;

    .gb-w-title {
      position: relative;
      font-size: 24px;
      margin-top: 20px;
      margin-bottom: 5px;
      padding-left: 30px;

      .good-bad-icon {
        width: 23px;
        height: 23px;
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 1px;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding-left: 15px;
    text-indent: -0.4em;
  }

  li:before {
    content: '•';
    color: #000;
    font-size: 18px;
    padding-top: 4px;
    position: relative;
    top: 1px;
    left: -7px;
  }

  @media (min-width: $break_sm_upper) {
    .good-bad-wrapper {
      gap: 20px;
    }

    .good-bad {
      width: calc(50% - 10px);
    }
  }
}
</style>
