// import timeOutOnClient from '~/utils/timeOutOnClient';

const DELETED_OPTIONS = ['badminton-shoes', 'approach-shoes', 'baseball-cleats', 'wrestling-shoes'];

export const useRankingFilterStore = defineStore('rankingFilter', {
  state: () => ({
    groups: [],
    facets: {},
    selectedOptions: [],
    selectedOptionsUpdatedSilently: false,
    filterId: null,
    query: '',
    fillingGroups: false, // just when group are already empty;
    lastOptionChanged: null,
  }),

  getters: {
    visibleGroups(state) {
      return state.groups
        .filter(group => {
          if (group.slug.slice(0, 4) === 'size') {
            return group.visible;
          }
          if (group.conditions.length) {
            return group.conditions.every(c => {
              const checked = !!state.selectedOptions.find(cd => cd.id === c.filter_group_option_id);
              return !!c.option_status === checked ? c.group_status : !c.group_status;
            });
          }

          if (state.facets[group.slug]) {
            return Object.keys(state.facets[group.slug])
              .map(filterOption => state.facets[group.slug][filterOption].count)
              .some(count => count === null || count > 0);
          }

          return true;
        })
        .map(group => {
          if (group.slug === 'category') {
            return {
              ...group,
              options: group.options.filter(option => !DELETED_OPTIONS.includes(option.slug)),
            };
          }
          return group;
        });
    },
    visibleGroupsWithoutHiddenClass(state) {
      return this.visibleGroups.filter(
        visibleGroup => !visibleGroup.classes.split(' ').includes('hidden'),
      );
    },
    selectedOptionsNames(state) {
      return state.selectedOptions.map(option => option.name);
    },
    isOptionSelected(state) {
      return option => !!state.selectedOptions.find(o => o.id === option.id);
    },
    optionCounter(state) {
      return option => state.facets?.[option.group.slug]?.[option.slug]?.count || null;
    },
    optionUrl(state) {
      return option => state.facets?.[option.group.slug]?.[option.slug]?.url || '';
    },
    optionIsNoFollow(state) {
      return option => state.facets?.[option.group.slug]?.[option.slug]?.nofollow || false;
    },
    relPhrase(state) {
      return option => this.optionIsNoFollow(option) ? 'nofollow' : null;
    },
    optionCounterPhrase(state) {
      return option => {
        const counter = this.optionCounter(option);
        const filter = this.optionFilter(option);
        if (!filter || filter.slug === 'category') return '';
        const selectedOptions = this.selectedFilterOptions(filter.slug);
        const prefix =
          filter && filter.options_operator === 'should' && filter.choices !== 'single' ? '+' : '';
        return selectedOptions && selectedOptions.length ? prefix + counter : counter;
      };
    },
    genderFilterGroup(state) {
      return state.groups.find(group => group.slug === 'gender');
    },
    priceFilterGroup(state) {
      return state.groups.find(group => group.slug === 'price');
    },
    showOptionFilterLabel(state) {
      return option => {
        const filter = this.optionFilter(option);
        return filter ? filter.show_label : false;
      };
    },
    optionFilter(state) {
      return option => {
        return (state.groups || []).find(group => {
          return group.id === option.group.id;
        });
      };
    },
    selectedFilterOptions(state) {
      return (filterSlug, match = 'equal') => {
        return state.selectedOptions.filter(option => {
          return match === 'equal'
            ? option.group.slug === filterSlug
            : option.group.slug.match(filterSlug);
        });
      };
    },
    allGroupsOptions(state) {
      return [].concat(...state.groups.map(group => group.options));
    },
    selectedOptionsToDisplay(state) {
      return state.selectedOptions.filter(o => o.rewrite_path);
    },
  },

  actions: {
    setFacets(facets) {
      // this.facets = Object.freeze(facets);
      this.facets = markRaw(facets);
    },
    resetQuery() {
      this.query = '';
    },
    addSelectedOption({ option, silent = false, source }) {
      this.selectedOptions.push(option);

      if (!silent) {
        const filterName = option.group && option.group.name ? option.group.name : option.name;
        useNuxtApp().$gaEvents.rankingFilterChanged(filterName, option.slug, source);
      }

      // Mark that this option was added as restore state, so that we don't
      // react on change and update the items.
      this.selectedOptionsUpdatedSilently = silent;
      if (option?.reportOptionChange !== false) {
        this.lastOptionChanged = option;
      }
    },
    removeSelectedOption(option) {
      const index = this.selectedOptions.findIndex(o => o.id === option.id);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
        if (option?.reportOptionChange !== false) {
          this.lastOptionChanged = option;
        }
      }
    },
    removeSelectedOptionByRef(option) {
      const index = this.selectedOptions.findIndex(o => o === option);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      }
    },
    resetSelectedOptions() {
      this.selectedOptions.splice(0, this.selectedOptions.length);
      // this.selectedOptions = initialState.selectedOptions;
      this.selectedOptionsUpdatedSilently = true;
    },
    // setSelectedOptionsFromIds(ids) {
    //   ids.forEach(id => {
    //     const option = this.allGroupsOptions.find(option => option.id === id)
    //     if (option) {
    //       this.selectedOptions.push(option);
    //     }
    //   });
    // },
    setFilterGroupVisibility({ group, visible }) {
      const index = this.groups.findIndex(g => g === group);
      if (index > -1) {
        this.groups[index].visible = visible;
      }
    },
    setFillingGroups(filling) {
      this.fillingGroups = filling && !this.groups.length;
    },
    fill({ id, groups, facets, selectedOptions }) {
      if (id) {
        this.filterId = id;
      }
      if (groups) {
        this.setFillingGroups(true);
        // timeOutOnClient(() => {
        this.groups = groups;
        this.setFillingGroups(false);
        // });
      }
      if (facets) {
        // timeOutOnClient(() => {
        this.setFacets(facets);
        // });
      }
      if (selectedOptions) {
        this.resetSelectedOptions();
        // timeOutOnClient(() => {
        selectedOptions.forEach(id => {
          const option = this.allGroupsOptions.find(o => o.id === id);
          if (option) {
            this.addSelectedOption({ option, silent: true });
          }
        });
        // })
      }
    },
    removeSelectedFilterOptionsByRef(filter) {
      if (!filter?.id) {
        return;
      }
      const options = this.selectedOptions.filter(option => option.group.id === filter.id);
      options.forEach(option => {
        this.removeSelectedOptionByRef(option);
      });
    },
    removeFilterOptions(filter) {
      const options = this.selectedOptions.filter(option => option.group.slug === filter.slug);
      options.forEach(option => this.removeSelectedOption(option));
    },
    toggleOptionBySlug({ filter, slug }) {
      const option = filter.options.find(option => option.slug === slug);
      this.toggleOption({ filter, option });
    },
    toggleOption({ filter, option, source }) {
      if (filter.choices === 'single') {
        this.toggleSingle({ filter, option, source });
      } else {
        this.toggleMultiple({ filter, option, source });
      }
    },
    toggleMultiple({ option, source }) {
      if (this.isOptionSelected(option)) {
        this.removeSelectedOption(option);
      } else {
        this.addSelectedOption({ option, source });
      }
    },
    toggleSingle({ filter, option, source }) {
      if (!this.isOptionSelected(option)) {
        this.removeFilterOptions(filter);
        this.addSelectedOption({ option, source });
      }
    },
    toggleFilterGroupVisibility(group) {
      this.setFilterGroupVisibility({ group, visible: !group.visible });
    },
  },
});
