export default {
  get(key) {
    const cookie = useCookie('runrepeat', {
      maxAge: 2147483647,
      path: '/',
    });

    return cookie.value ? cookie.value[key] : null;
  },
  update(key, value) {
    const cookie = useCookie('runrepeat', {
      maxAge: 2147483647,
      path: '/',
    });

    const cookieValue = cookie.value || {};

    cookieValue[key] = value;
    cookie.value = cookieValue;
  },
  getSingle(name) {
    return useCookie(name).value;
  },
  setSingle(name, value, ttl = 2147483647) {
    if (!process.client) {
      return;
    }

    useCookie(name, {
      maxAge: ttl,
      path: '/',
    }).value = value;
  },
}