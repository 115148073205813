import { DEFAULT_GENDER } from '@/constants/global';
import { useSizesStore } from "~/stores/sizes";
import { useMainStore } from "~/stores/main";

export const useCalculateSizeStore = defineStore('calculateSize', {
  state: () => ({
    open: false,
    sizeStats: {
      votes: '',
      asExpected: 0,
      somewhatSmall: 0,
      tooSmall: 0,
      somewhatLarge: 0,
      tooLarge: 0,
    },
    relatedProduct: null,
    sizeComments: [],
    recommendedSize: {
      name: '',
      value: '',
    },
    brands: [],
    category: null,
    brand: null,
    size: '',
    gender: DEFAULT_GENDER,
    step: 1,
    brandSizeFromMillimeter: null,
    currentBrand: {
      name: '',
      slug: '',
    },
  }),

  getters: {
    sizeSystem(state) {
      const sizeParts = (state.size || '').split('_');
      return sizeParts.length > 1 ? sizeParts[0] : '';
    },
    sizeValue(state) {
      return parseInt(state.size.split('_')[1], 10) / 10;
    },
    sizesOptions(state) {
      const sizes = useSizesStore();
      const brandSizes = state.brand ? sizes.allBrandsSizes[state.brand.slug] : null;
      return brandSizes ? sizes.brandSizesForGrid(brandSizes, state.gender, true) : [];
    },
    usersVotes(state) {
      // const coef = this.sizeFitsCoefficient;
      // if (coef > 0.375 && coef < 0.625) {
      //   return Math.round(state.sizeStats.asExpected * state.sizeStats.votes);
      // }
      // if (coef <= 0.375) {
      //   return Math.round(
      //     (state.sizeStats.tooSmall + state.sizeStats.somewhatSmall) * state.sizeStats.votes,
      //   );
      // }
      // return Math.round(
      //   (state.sizeStats.tooLarge + state.sizeStats.somewhatLarge) * state.sizeStats.votes,
      // );
      return state.sizeStats.votes;
    },
    sizeFitsCoefficient(state) {
      return this.smallPercentage / 100 * 0 + this.trueToSizePercentage / 100 * 0.5 + this.largePercentage / 100 * 1;
    },
    sizeFitsCoefficientPercent(state) {
      return this.sizeFitsCoefficient * 100;
    },
    smallPercentage(state) {
      return Math.round(
        parseFloat((state.sizeStats.somewhatSmall + state.sizeStats.tooSmall) * 100, 10),
      );
    },
    largePercentage(state) {
      return Math.round(
        parseFloat((state.sizeStats.somewhatLarge + state.sizeStats.tooLarge) * 100, 10),
      );
    },
    trueToSizePercentage(state) {
      return Math.round(parseFloat(state.sizeStats.asExpected * 100, 10));
    },
  },

  actions: {
    toggle() {
      this.open = !this.open;
    },
    setCurrentBrand({ name, slug }) {
      this.currentBrand = {
        name: name,
        slug: slug,
      };
    },
    fill({ brands, sizeStats, relatedProduct, sizeComments, brand }) {
      this.brands = brands || [];
      this.sizeStats = sizeStats;
      this.relatedProduct = relatedProduct;
      this.sizeComments = sizeComments;
      this.setCurrentBrand(brand);
    },
    applyRecommendedSize() {
      if (this.recommendedSize) {
        useMainStore().setSize(this.recommendedSize.value);
        this.toggle();
      }
    },
    calculate() {
      this.goStep(2);

      const sizes = useSizesStore();

      let sizeWasFound = false;
      // this.resetRecommendation();

      const selectedSize = sizes.allBrandsSizes[this.brand.slug].find(
        size => this.size === size[`${this.sizeSystem + this.gender}_slug`],
      );

      // get the mondo point
      const selectedSizeValue = selectedSize ? getSizeValue(selectedSize) : 0;

      const sizeOptions = sizes.allBrandsSizes[this.currentBrand.slug];

      let convertedSize;

      try {
        for (const indexString in sizeOptions) {
          const index = parseInt(indexString);

          const currentSize = sizeOptions[index];
          const previousSize = sizeOptions[index - 1];
          const nextSize = sizeOptions[index + 1];

          // get the mondo point
          const currentSizeValue = getSizeValue(currentSize);
          const previousSizeValue = previousSize ? getSizeValue(previousSize) : 0;
          const nextSizeValue = nextSize ? getSizeValue(nextSize) : 0;

          const nextDiff = Math.abs(nextSizeValue - selectedSizeValue);
          const prevDiff = Math.abs(previousSizeValue - selectedSizeValue);

          if (currentSizeValue >= selectedSizeValue && previousSizeValue < selectedSizeValue) {
            if (
              this.sizeStats.is_large
              && previousSize
              && prevDiff <= 5
              && previousSize[this.sizeSystem + this.gender]
            ) {
              convertedSize = previousSize;
              break;
            }

            if (this.sizeStats.is_small
              && nextSize
              && nextDiff <= 5
              && nextSize[this.sizeSystem + this.gender]
            ) {
              convertedSize = nextSize;
              break;
            }

            convertedSize = currentSize;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }

      this.recommendedSize = {
        value: convertedSize ? convertedSize[`${this.sizeSystem + this.gender}_slug`] : '',
        name: convertedSize ? parseFloat(convertedSize[this.sizeSystem + this.gender]) : '',
        active: !!convertedSize,
      };
    },
    calculateBrandSizeFromMeasurement({ measurement, brandSlug }) {
      const sizes = useSizesStore();
      const brandSizes = sizes.allBrandsSizes[brandSlug];

      let size;

      brandSizes.forEach(brandSize => {
        const value = getSizeValue(brandSize)
        if (!size && value >= measurement) {
          size = brandSize;
        }
      });

      if (size) {
        this.brandSizeFromMillimeter = size;
      }
    },
    goStep(step) {
      this.step = step;
    },
  },
})

export function getSizeValue(size) {
  if (size.foot) {
    return size.foot;
  }

  let diff = 6;

  if (size.mm > 270) {
    diff = 7;
  }

  if (size.mm > 282) {
    diff = 8;
  }

  if (size.mm > 295) {
    diff = 9;
  }

  return (size.mm - diff);
}
