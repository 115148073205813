export const useBecomeExpertStore = defineStore('becomeExpert', {
  state: () => ({
    becomeExpertRequest: {
      category: {
        name: '',
        slug: '',
        id: null,
      },
    },
    allCountries: [],
  }),

})
