const imageSizesMap = {
  tn: '60',
  xs: '120',
  sm: '250',
  md: '380',
  lg: '720',
  xl: '1080',
  '2xl': '1440',
  full: 'main',
};

const imagePlaceholders = {
  tn: {
    url: 'https://cdn.runrepeat.com/img/no_image_60.png',
    width: 60,
    height: 28,
  },
  xs: {
    url: 'https://cdn.runrepeat.com/img/no_image_60.png',
    width: 60,
    height: 28,
  },
  sm: {
    url: 'https://cdn.runrepeat.com/img/no_image_380.png',
    width: 250,
    height: 115,
  },
  md: {
    url: 'https://cdn.runrepeat.com/img/no_image_380.png',
    width: 380,
    height: 175,
  },
  lg: {
    url: 'https://cdn.runrepeat.com/img/no_image_600.jpg',
    width: 600,
    height: 300,
  },
  xl: {
    url: 'https://cdn.runrepeat.com/img/no_image_600.jpg',
    width: 600,
    height: 300,
  },
  '2xl': {
    url: 'https://cdn.runrepeat.com/img/no_image_600.jpg',
    width: 600,
    height: 300,
  },
  full: {
    url: 'https://cdn.runrepeat.com/img/no_image_600.jpg',
    width: 600,
    height: 300,
  },
};

export default {
  getImageUrl(image, size) {
    const width = size === 'full' ? 'main' : this.getImageWidth(image, size);
    return width && image && image.url
      ? image.url.replace('{SIZE}', width)
      : imagePlaceholders[size].url;
  },
  getWistiaImageUrl(url, size, ratio = 3 / 2) {
    if (size === 'full') {
      const width = 2880
      return url
        .replace('{x}', width)
        .replace('{y}', Math.round(width / ratio))
    }

    return url
      .replace('{x}', parseInt(imageSizesMap[size]))
      .replace('{y}', Math.round(parseInt(imageSizesMap[size]) / ratio))
  },
  getImageWidth(image, size) {
    if (size === 'lg' && image && image.size) {
      return image.size;
    }
    if (size === 'xl') {
      return image && image.size
        ? 1.5 * image.size
        : 1.5 * imageSizesMap['lg'];
    }
    if (size === '2xl') {
      return image && image.size
        ? 2 * image.size
        : 2 * imageSizesMap['lg'];
    }
    if (size === 'full') {
      if (!image || (!image.size && !image.full_width)) {
        return 4 * imageSizesMap['lg'];
      }
      return image.full_width ? image.full_width : 4 * image.size;
    }
    return imageSizesMap[size];
  },
};
