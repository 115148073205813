import { useMainStore } from "~/stores/main";

export function loadMediavine() {
  const main = useMainStore();

  if (!main.isProd || main.botContinent) {
    return;
  }

  (function () {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = 'async';
    script['data-noptimize'] = '1';
    script['data-cfasync'] = 'false';
    script.src = 'https://scripts.mediavine.com/tags/runrepeat.js';
    document.head.appendChild(script);
  })();
}