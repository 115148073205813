export const useCategoryGroupsStore = defineStore('categoryGroups', {
  state: () => ({
    currentCategory: {
      slug: '',
      name: '',
    },
    dropdownList: [],
  }),

  getters: {
    categoriesForSelect(state) {
      return state.dropdownList.map(category => {
        return {
          value: category.value,
          label: category.name,
        };
      });
    },
    currentCategoryUserDefinition(state) {
      return number => {
        return number > 1
          ? state.currentCategory.user_definition_pl
          : state.currentCategory.user_definition;
      };
    },
    currentCategoryLink(state) {
      return state.currentCategory && state.currentCategory.slug
        ? this.localePath({name: 'catalog-slug', params: {slug: state.currentCategory.slug}})
        : '';
    },
    blackFridayLink(state) {
      // const baseLink = this.localePath({name: 'catalog-slug', params: {slug: 'cyber-monday-shoes-deals'}});
      const baseLink = this.localePath({name: 'catalog-slug', params: {slug: 'black-friday-shoes-deals'}});
      return state.currentCategory && state.currentCategory.slug
        ? baseLink + '?category=' + state.currentCategory.slug
        : baseLink;
    },
  },
});
