export default function processOffers(offers, seoGroup) {
  if (!offers || !offers.length || !seoGroup || seoGroup === '0') {
    return offers;
  }

  return offers.map(offer => {
    if (offer && (offer.shop_id === 1 || offer.shop_id === 49 || offer.shop_id === 147)) {
      return {
        ...offer,
        affiliate_link: offer.affiliate_link.replace(
          /runrepcom-[a-z]+-2/iu,
          `runrepcom-c${seoGroup}-2`
        ),
      };
    }
    return offer;
  });
}
