<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <title>Email</title>
    <path
      d="M12.207 5.207L6.26 11.154 4.846 9.74l5.947-5.947L9 2h5v5l-1.793-1.793zM7 2v1H3v10h10V9h1v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4z"
      fill="currentColor"
      fill-rule="nonzero"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: relative;
  top: 2px;
}
</style>
