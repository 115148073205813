import {
  DEFAULT_GENDER,
  DEFAULT_SYSTEM,
  DEFAULT_COUNTRY,
  DEFAULT_WIDTH,
  COUNTRY_LOCALES,
} from '~/constants/global';
import { useColorsStore } from '~/stores/colors';
import persistedState from '~/utils/persistedState';

// Countries to show RR hiring message
const COUNTRIES = [
  { name: 'Ukraine', code: 'ua' },
  { name: 'the Philippines', code: 'ph' },
  { name: 'Serbia', code: 'rs' },
];

export const useMainStore = defineStore('main', {
  state: () => ({
    locale: '',
    botContinent: '',
    countryCode: DEFAULT_COUNTRY,
    headerTransparent: false,
    wideContainer: false,
    fixedContainer: false,
    defaultSizeSystem: DEFAULT_SYSTEM,
    size: '',
    sizeConverted: false,
    sizeHash: '',
    gender: DEFAULT_GENDER,
    forcedGender: false,
    width: DEFAULT_WIDTH,
    stickyPc: false,
    stickyPcPosition: {
      left: 0,
      top: 0,
    },
    partnerId: '',
    isApiCacheDisabled: false,
    isProd: true,
    breadcrumbs: [],
    bottomAdsHeight: '0',
  }),

  getters: {
    sizeSystem: state => {
      const sizeParts = (state.size || '').split('_');
      return sizeParts.length > 1 ? sizeParts[0] : state.defaultSizeSystem;
    },
    genderFullName: state => {
      return state.gender === 'm' ? 'Men' : 'Women';
    },
    countryIsPortugal: state => {
      return state.countryCode.toLowerCase() === 'pt';
    },
    countryToDisplayJobAd: state => {
      return [
        // { code: 'AT', name: 'Austria' },
        // { code: 'BE', name: 'Belgium' },
        // { code: 'BG', name: 'Bulgaria' },
        // { code: 'HR', name: 'Croatia' },
        // { code: 'CY', name: 'Cyprus' },
        // { code: 'CZ', name: 'Czech Republic' },
        // { code: 'DK', name: 'Denmark' },
        // { code: 'EE', name: 'Estonia' },
        // { code: 'FI', name: 'Finland' },
        // { code: 'FR', name: 'France' },
        // { code: 'DE', name: 'Germany' },
        // { code: 'GR', name: 'Greece' },
        // { code: 'HU', name: 'Hungary' },
        // { code: 'IE', name: 'Ireland' },
        // { code: 'IT', name: 'Italy' },
        // { code: 'LV', name: 'Latvia' },
        // { code: 'LT', name: 'Lithuania' },
        // { code: 'LU', name: 'Luxembourg' },
        // { code: 'MT', name: 'Malta' },
        // { code: 'NL', name: 'Netherlands' },
        // { code: 'PL', name: 'Poland' },
        // { code: 'PT', name: 'Portugal' },
        // { code: 'RO', name: 'Romania' },
        // { code: 'SK', name: 'Slovakia' },
        // { code: 'SI', name: 'Slovenia' },
        // { code: 'ES', name: 'Spain' },
        // { code: 'SE', name: 'Sweden' },
        // { code: 'US', name: 'United States' },
        // { code: 'GB', name: 'United Kingdom' },
        // { code: 'CA', name: 'Canada' },
        // { name: 'Spain', code: 'es' },
        // { name: 'Portugal', code: 'pt' },
        // { name: 'the Philippines', code: 'ph' },
      ].find(country => country.code.toLowerCase() === state.countryCode.toLowerCase());
    },
    sizeGenderHash(state) {
      return state.sizeHash + state.gender;
    },
    sizeGenderWidthHash(state) {
      return state.sizeHash + state.gender + state.width;
    },
    fullParamsHash(state) {
      return state.gender + state.sizeHash + state.width + useColorsStore().current.id;
    },
  },

  actions: {
    setSize(size) {
      const sizeChanged = this.size !== size;

      if (typeof size === 'string') {
        this.size = size;
        this.sizeConverted = false;
      } else {
        // Sometime we change size just to convert system.
        // So, it must be registered.
        this.sizeConverted = size.hasOwnProperty('converted') ? size.converted : false;
        this.size = size.hasOwnProperty('value') ? size.value : '';
      }

      if (sizeChanged && !this.sizeConverted) {
        this.sizeHash = this.size + Math.random().toString(36).substring(7);
      }

      persistedState.setSingle('size', this.size);
    },
    setGender(gender) {
      this.gender = gender?.value || gender;
      this.forcedGender = gender?.forced ?? false;

      if (!this.forcedGender) {
        persistedState.setSingle('gender', this.gender);
      }
    },
    setWidth(width) {
      this.width = width;
      persistedState.setSingle('width', this.width);
    },
    disableApiCache() {
      this.isApiCacheDisabled = true;
    },
    setBreadcrumbs(list) {
      this.breadcrumbs = list && Array.isArray(list) && list.length > 0 ? list : [];
    },
    setLocale(locale) {
      this.locale = locale;

      if (locale && this.botContinent === 'EU' && COUNTRY_LOCALES[locale]) {
        this.countryCode = COUNTRY_LOCALES[locale];
      }
    },
    updateBottomAdsHeight() {
      const bottomAdsEl = document.querySelector('#fixed_container_bottom > .adhesion_wrapper');
      if (bottomAdsEl) {
        this.bottomAdsHeight = bottomAdsEl ? `${bottomAdsEl.offsetHeight}px` : '0';
      }
    },
  },
});
