import { useOffersStore } from "~/stores/offers";

export const useShopsStore = defineStore('shops', {
  state: () => ({
    all: [],
  }),

  getters: {
    shopById(state) {
      return id => state.all.find(shop => shop.id == id) || { name: '', ranking: 0 };
    },
    shopNameById(state) {
      return id => {
        const shop = this.shopById(id);
        return shop ? shop.name : '';
      };
    },
    shopsWithoutOffers(state) {
      return state.all.filter(
        shop => useOffersStore().shopsIdsWithOffer().indexOf(shop.id) === -1,
      );
    },
  },

  actions: {
    setShops(shops) {
      this.all = shops || [];
    },
  },
})
