import { useMainStore } from "~/stores/main";
import { useCategoryGroupsStore } from "~/stores/categoryGroups";
import timeOutOnClient from '~/utils/timeOutOnClient';
import { NotFoundException } from '~/utils/exceptions';
import serverTiming from "~/utils/serverTiming";
import { useBecomeExpertStore } from "~/stores/becomeExpert";
import { useRankingStore } from "~/stores/ranking";
import { useRankingSlimStore } from "~/stores/rankingSlim";
import { useSizesStore } from "~/stores/sizes";
import { useCurrencyStore } from "~/stores/currency";
import { useProductsStore } from "~/stores/products";
import { useColorsStore } from "~/stores/colors";
import { useOffersStore } from "~/stores/offers";
import { useShopsStore } from "~/stores/shops";
import { useWidthsStore } from "~/stores/widths";
import { useProductRankingsStore } from "~/stores/productRankings";
import { useCalculateSizeStore } from "~/stores/calculateSize";
import { useFactsStore } from "~/stores/facts";
import { useAuthorStore } from "~/stores/author";
import { useGuideStore } from "~/stores/guide";
import { useGuideMatrixStore } from "~/stores/guideMatrix";
import { useGuideBarsStore } from "~/stores/guideBars";
import { usePostsStore } from "~/stores/posts";
import { useSizeChartsStore } from "~/stores/sizeCharts";
import { useGenericSizesStore } from "~/stores/genericSizes";
import { useSimpleComparisonStore } from "~/stores/simpleComparison";

export const usePageStore = defineStore('page', {
  state: () => ({
    type: '',
    // Hold a hint of what page will be next,
    // Using this we will not wait for the backend.
    // Lastly, this can be defined on </nuxt-link> click.
    expectedType: 'product',
    global: {
      show_deals: true,
      nav_menu: [],
      footer_links: [],
    },
    meta: {
      title: '',
      description: '',
      canonical: '',
      og: {},
      twitter: {},
      locales: [],
      noindex: false,
      scripts: {},
      prev: '',
      next: '',
      has_video: false,
    },
    ga: {
      page: 'default',
      category: 'general',
      author: 'Runrepeat',
    },
    referer: '',
    redirectTo: '',
    loading: false,
    layout: '',
    seoGroup: null,
    isSurveyActive: false,
    searchVolume: 0,
    isPreview: false,
  }),

  getters: {
    localeUrls(state) {
      const urls = new Map();
      state.meta.locales.forEach(locale => {
        urls.set(locale.locale, locale.url);
      });
      return urls;
    },
  },

  actions: {
    setPageMeta(meta) {
      if (meta.hasOwnProperty('title')) {
        this.meta.title = meta.title;
      }

      if (meta.hasOwnProperty('description')) {
        this.meta.description = meta.description;
      }

      if (meta.hasOwnProperty('og')) {
        this.meta.og = meta.og;
      }

      if (meta.hasOwnProperty('twitter')) {
        this.meta.twitter = meta.twitter;
      }

      if (meta.hasOwnProperty('canonical')) {
        this.meta.canonical = meta.canonical;
      }

      if (meta.hasOwnProperty('locales')) {
        this.meta.locales = meta.locales;
      }

      if (meta.hasOwnProperty('scripts')) {
        this.meta.scripts = meta.scripts;
      }

      if (meta.hasOwnProperty('noindex') && meta.noindex) {
        this.meta.noindex = meta.noindex;
      }

      if (meta.hasOwnProperty('next') && meta.next) {
        this.meta.next = meta.next;
      }

      if (meta.hasOwnProperty('prev') && meta.prev) {
        this.meta.prev = meta.prev;
      }

      if (meta.hasOwnProperty('has_video') && meta.has_video) {
        this.meta.has_video = meta.has_video;
      }
    },
    setGaPage(page) {
      if (page) {
        this.ga.page = page;
      }
    },
    setGaCategory(category) {
      if (category) {
        this.ga.category = category;
      }
    },
    setGaAuthor(author) {
      if (author) {
        this.ga.author = author;
      }
    },
    savePageResponse(response) {
      serverTiming.stop('init');
      serverTiming.stop('api');
      serverTiming.start('render', 'render');

      // Set the page type (that is set just by the backend)
      this.type = response.page_type;
      // set the page type (that is set by backend & client)
      this.expectedType = response.page_type;

      if (response.page_type === 'not_found') {
        throw new NotFoundException(`We can't find this page`);
      }

      if (response.page_data) {
        const main = useMainStore();

        useCategoryGroupsStore().currentCategory = response.page_data.category;

        if (response.page_data.global) {
          this.global = response.page_data.global;

          // Save global sizes data.
          if (response.page_data.global.sizes) {
            useSizesStore().fill(response.page_data.global.sizes);
          }

          if (response.page_data.global.currency) {
            useCurrencyStore().fill(response.page_data.global.currency);
          }

          // Experiment ID is set just from init.js
          // if (response.page_data.global.experiment_id) {
          //   useExperimentStore().id = response.page_data.global.experiment_id;
          // }
        }

        main.breadcrumbs = response.page_data.breadcrumbs || [];

        if (response.page_type === 'redirect' && response.page_data.to) {
          this.redirectTo = response.page_data.to;
        }

        // SeoGroup is set in init.js from cookie (if its present)
        if (this.seoGroup == null) {
          this.seoGroup =
            response.page_type === 'ranking' && response.page_data.page.seo_group
              ? response.page_data.page.seo_group
              : 0;
        }
      }

      if (response.page_meta && typeof response.page_meta === 'object') {
        this.setPageMeta(response.page_meta);

        if (true || response.page_meta.is_survey_active) {
          this.isSurveyActive = true;
        }
      }
    },
    fillHomePageData(data) {
      this.savePageResponse(data);
      this.setGaPage('homepage');
    },
    fillSlugPageData(data) {
      this.savePageResponse(data);
      this.setGaPage(data.page_type === 'product' ? 'shoe' : data.page_type);

      if (data.page_type === 'product' || data.page_type === 'product_upcoming') {
        const products = useProductsStore();
        const colors = useColorsStore();
        const categoryGroups = useCategoryGroupsStore();

        this.setGaCategory(data.page_data.category.ga_slug);

        products.current = {
          ...data.page_data.product,
          lastVersion: data.page_data.last_version,
        };
        const layouts = { 0: 'meta', 1: 'lab', 2: 'indepth', 3: 'new-default' };
        this.layout = layouts[data.page_data.product.layout_type] || '';
        this.searchVolume = data.page_data.product.search_volume || 0;
        products.slug = data.page_data.product.slug;

        // useOffersStore().fillInitialData(data.page_data);

        if (data.page_data?.content?.images) {
          colors.fillContentImages(data.page_data.content.images);
        }

        // dispatch('compareParameters/fill', data.page_data.bar_charts, { root: true });
        // dispatch('productsSwiper/fill', data.page_data.similar_deals, { root: true });
        // timeOutOnClient(() => {
        products.setTopRatedInCategory(data.page_data.top_rated_in_category);
        // });

        timeOutOnClient(() => {
          useWidthsStore().fill({
            widths: data.page_data.global.widths,
          });

          if (data.page_data.rankings) {
            useProductRankingsStore().fill(data.page_data.rankings);
          }

          useSizesStore().fill({
            brandSizes: data.page_data.brand_sizes,
          });

          useCalculateSizeStore().fill({
            brands: data.page_data.brands,
            sizeStats: data.page_data.size_stats,
            relatedProduct: data.page_data.related_product,
            sizeComments: data.page_data.size_comments,
            brand: {
              name: data.page_data.product.brand_name,
              slug: data.page_data.product.brand_slug,
            },
          });

          if (data.page_data.category) {
            categoryGroups.currentCategory = data.page_data.category;
          }

          if (data.page_data.all_categories) {
            categoryGroups.dropdownList = data.page_data.all_categories;
          }

          if (data.page_data.comparison) {
            useSimpleComparisonStore().updateProducts(data.page_data.comparison);
          }

          if (
            data.page_data.features &&
            data.page_data.scale_ranks &&
            data.page_data.score_distribution
          ) {
            useFactsStore().fill({
              features: data.page_data.features,
              scaleRanks: data.page_data.scale_ranks,
              scoreDistribution: data.page_data.score_distribution,
            });
          }

          if (data.page_data.author) {
            useAuthorStore().fill(data.page_data.author);
          }
        });
      } else if (
        data.page_type === 'redirect' ||
        data.page_type === 'post' ||
        data.page_type === 'page'
      ) {
      } else {
        throw new Error(`page not found ${data.page_type}`);
      }
    },
    async fillRanking(data) {
      this.expectedType = data.page_type;
      this.type = data.page_type;

      timeOutOnClient(() => this.savePageResponse(data));

      if (data.page_type === 'not_found' || !data.page_data || data.page_data.hasOwnProperty('to')) {
        this.loading = false;
        return;
      }

      if (data.page_type === 'ranking') {
        useMainStore().wideContainer = true;
      }

      this.setGaPage('shoes_filter');
      this.setGaCategory(data.page_data.category.ga_slug);

      useRankingStore().fill(data.page_data);

      if (useRankingStore().listType === 'slim' && data.page_type === 'ranking') {
        useRankingSlimStore().initFullPageLoad();
      }

      this.loading = false;
    },
    fillGuidePageData(data) {
      this.savePageResponse(data);
      this.setGaPage(data.page_type);

      if (data.page_type === 'redirect') {
        return;
      }

      if (data.page_type === 'buying_guide') {
        useGuideStore().fill(data.page_data);
        useGuideMatrixStore().fill(data.page_data);
        useGuideBarsStore().fill(data.page_data);
        this.setGaCategory(data.page_data.category.ga_slug);
        this.searchVolume = data.page_data.article.search_volume || 0;

        if (data.page_data.comparison) {
          useSimpleComparisonStore().products = data.page_data.comparison;
        }
      } else {
        this.expectedType = data.page_type;
      }
    },
    fillGuidesPageData(data) {
      this.savePageResponse(data);
      this.setGaPage(data.page_type);
    },
    fillNewsPageData(data) {
      this.savePageResponse(data);
      usePostsStore().fillPosts(data.page_data);
      this.setGaPage('news');
    },
    fillSizeChartIndexPageData(data) {
      timeOutOnClient(() => this.savePageResponse(data));
      this.expectedType = data.page_type;
      this.setGaPage(data.page_type);
    },
    fillSizeChartPageData(data) {
      timeOutOnClient(() => this.savePageResponse(data));
      this.expectedType = data.page_type;
      this.setGaPage(data.page_type);

      const sizeCharts = useSizeChartsStore();
      const calculateSize = useCalculateSizeStore();

      useGenericSizesStore().brandSizes = data.page_data.sizes;
      sizeCharts.categoryLinks = data.page_data.category_links;
      calculateSize.brands = data.page_data.brands;
      calculateSize.setCurrentBrand(data.page_data.brand);
      useCategoryGroupsStore().dropdownList = data.page_data.all_categories;
      sizeCharts.guides = data.page_data.related_guides || [];
      sizeCharts.categories = data.page_data.brand_categories || [];
    },
    fillAuthorsPageData(data) {
      timeOutOnClient(() => this.savePageResponse(data));
      this.expectedType = data.page_type;
      this.setGaPage(data.page_type);
    },
    fillAuthorPageData(data) {
      timeOutOnClient(() => this.savePageResponse(data));
      this.expectedType = data.page_type;
      this.setGaPage(data.page_type);
    },
    fillSitemapPageData(data) {
      this.savePageResponse(data);
      this.setGaPage('sitemap');
    },
    fillRedirectPageData(data) {
      this.savePageResponse(data);
      this.setGaPage('redirect');
    },
    fillBecomeExpert(data) {
      const becomeExpert = useBecomeExpertStore();

      if (data && data.becomeExpertRequest) {
        becomeExpert.becomeExpertRequest = data.becomeExpertRequest;
        becomeExpert.allCountries = data.all_countries;
      } else {
        throw new NotFoundException(`We can't find this page`);
      }
    },
  },
});
