export const TOP_HEADER_OFFSET = 65;
export const DEFAULT_SYSTEM = 'us';
export const DEFAULT_GENDER = 'm';
export const DEFAULT_WIDTH = 'standard';
export const DEFAULT_COUNTRY = 'US';
export const COUNTRY_LOCALES = {
  uk: 'GB',
  es: 'ES',
};

export const GA_PROPERTY_ID = 'UA-53709775-1';
export const GA_OPTIMIZE_ID = 'GTM-MX3T9C3';
export const GA_GTAG_ID = 'G-MDD9SB6P3N';

export const GA_EXPERIMENT_COOKIE = 'wistia_video_experiment';
export const NPS_COOKIE = 'nps_submitted';

export const VUETABLE_CSS = {
  table: {
    tableWrapper: '',
    tableClass: 'table table-striped table-bordered',
    tableHeaderClass: 'fixed',
    tableBodyClass: 'fixed',
    loadingClass: 'loading',
    ascendingIcon: 'fa fa-chevron-up',
    descendingIcon: 'fa fa-chevron-down',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'fa fa-sort',
    handleIcon: 'fa fa-bars text-secondary',
    renderIcon(classes, options) {
      return `<i class="${classes.join(' ')}" ${options}></span>`;
    },
  },
  pagination: {
    wrapperClass: 'pagination',
    activeClass: 'active',
    disabledClass: 'disabled',
    pageClass: 'page-item',
    linkClass: 'page-link',
    paginationClass: 'pagination',
    paginationInfoClass: 'float-left',
    dropdownClass: 'form-control',
    icons: {
      first: 'fa fa-chevron-left',
      prev: 'fa fa-chevron-left',
      next: 'fa fa-chevron-right',
      last: 'fa fa-chevron-right',
    },
  },
};
