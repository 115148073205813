import { TOP_HEADER_OFFSET } from '@/constants/global';

class StickyRankingFiler {
  constructor(el, syncWithElement, vm) {
    this.el = el;
    this.syncWithElement = syncWithElement;
    this.vm = vm;
    this.isSticky = false;
    this.stickyPosition = false;
  }

  check() {
    const scrollTop = window.scrollY;
    const initialFilterTop = this.el.parentElement.offsetTop;
    const filterHeight = this.el.firstChild.offsetHeight;

    // we need a query selector here since sometimes constructor has zero height element
    const productListEl = document.querySelector(this.syncWithElement);
    if (!productListEl) { // make sure the list exists
      return;
    }
    const productListHeight = productListEl.offsetHeight;
    const productListTop = productListEl.offsetTop;

    // Filter is higher than product list
    if (filterHeight > productListHeight || window.width < 768) {
      if (this.isSticky || this.stickyPosition) {
        this.disableSticky();
      }
      return;
    }

    // Scrolled past filter top
    if (scrollTop > initialFilterTop) {
      if (!this.isSticky) {
        this.enableSticky();
      }
    } else if (this.isSticky) {
      this.disableSticky();
    }

    if (this.isSticky) {
      if (scrollTop + filterHeight - TOP_HEADER_OFFSET > productListTop + productListHeight + 55) {
        if (!this.stickyPosition) {
          this.fixBottomPosition(productListHeight);
        }
      } else if (this.stickyPosition) {
        this.unfixBottomPosition();
      }
    }
  }

  applyPositionZero() {
    this.el.style.top = null;
    this.el.style.maxHeight = null;
  }

  enableSticky() {
    this.isSticky = true;
    this.stickyPosition = false;
    this.emit('sticky-position', { stickyPosition: this.stickyPosition });
    this.emit('sticky', { sticky: this.isSticky });
  }

  disableSticky() {
    this.applyPositionZero();
    this.isSticky = false;
    this.stickyPosition = false;
    this.emit('sticky-position', { stickyPosition: this.stickyPosition });
    this.emit('sticky', { sticky: this.isSticky });
  }

  fixBottomPosition(productListHeight) {
    this.stickyPosition = true;
    this.el.style.top = `${productListHeight + 55 - this.el.scrollHeight}px`;
    this.el.style.maxHeight = `${this.el.scrollHeight}px`;
    this.emit('sticky-position', { stickyPosition: this.stickyPosition });
  }

  unfixBottomPosition() {
    this.applyPositionZero();
    this.stickyPosition = false;
    this.emit('sticky-position', { stickyPosition: this.stickyPosition });
  }

  emit(eventName, detail = {}) {
    this.el.dispatchEvent(new CustomEvent(eventName, { detail }));
  }
}

let sticky = null;

const onScrollHanlder = () => {
  if (sticky) {
    sticky.check();
  }
};

export default {
  mounted(el, binding, vnode) {
    if (!binding.arg) return;
    sticky = new StickyRankingFiler(el, binding.value, binding.instance);
    sticky.check();
    window.addEventListener('scroll', onScrollHanlder);
  },
  unmounted() {
    window.removeEventListener('scroll', onScrollHanlder);
  },
};
