import { nextTick } from 'vue';
import { usePageStore } from "~/stores/page";
import { useMainStore } from "~/stores/main";

export default {
  getHead(bodyClass = '', withMetaLinks = true, forceNoIndex = false) {
    const { meta } = usePageStore();
    const main = useMainStore();
    const title = meta.title ? `${meta.title} | RunRepeat` : `RunRepeat`;

    const result = {
      title: title,
      htmlAttrs: {
        prefix: 'og: http://ogp.me/ns#',
        lang: forceNoIndex ? 'en' : useI18n()?.localeProperties?.value?.language,
      },
      bodyAttrs: {
        class: bodyClass + (main.countryToDisplayJobAd ? ' header-announcement' : ''),
      },
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:title', content: meta.og.title },
        { property: 'og:description', content: meta.og.description },
        { property: 'og:locale', content: meta.og.locale },
        { property: 'og:type', content: meta.og.type },
        { property: 'og:site_name', content: meta.og.site_name },
        { property: 'og:image', content: meta.og.image },
        { property: 'og:url', content: meta.og.url },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: meta.twitter.title },
        { name: 'twitter:description', content: meta.twitter.description },
        { name: 'twitter:image', content: meta.twitter.image },
        { name: 'rr-country-code', content: main.countryCode },
      ],
      link: [{ rel: 'canonical', href: meta.canonical }],
      script: [
        this.getWebPageSchema(),
      ],
    };

    if (main.breadcrumbs.length > 0) {
      result.script.push(this.getBreadcrumbsSchema());
    }

    if (withMetaLinks && meta.locales && meta.locales.length > 0) {
      meta.locales.forEach(locale => {
        result.link.push({
          rel: 'alternate',
          href: locale.url,
          hreflang: locale.locale,
        });
      });
    }

    if (withMetaLinks && meta.prev) {
      result.link.push({ rel: 'prev', href: meta.prev });
    }

    if (withMetaLinks && meta.next) {
      result.link.push({ rel: 'next', href: meta.next });
    }

    if (this.isIndexable() && !forceNoIndex) {
      result.meta.push({ name: 'robots', content: 'max-image-preview:large' });
    } else {
      result.meta.push(this.getNoindexMeta());
    }

    if (meta.has_video) {
      result.link.push({
        rel: 'preconnect',
        href: 'https://fast.wistia.com',
        crossorigin: '',
      });
      result.link.push({
        rel: 'dns-prefetch',
        href: 'https://fast.wistia.com',
      });
      result.script.push({
        async: true,
        src: 'https://fast.wistia.com/assets/external/E-v1.js'
      })
    }

    return result;
  },
  isIndexable() {
    const page = usePageStore();
    return !page.meta.noindex;
  },
  getNoindexMeta(follow = false) {
    const followStr = follow ? 'follow' : 'nofollow';
    return { name: 'robots', content: `noindex, ${followStr}` };
  },
  getWebPageSchema() {
    const { meta } = usePageStore();
    return {
      body: true,
      innerHTML: JSON.stringify({
        '@context': `https://schema.org`,
        '@type': 'WebPage',
        name: meta.title,
        description: meta.description,
      }),
      type: 'application/ld+json',
    };
  },
  getBreadcrumbsSchema() {
    const main = useMainStore();
    const list = main.breadcrumbs.map((link, index) => {
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: link.name,
        item: link.url.match(/^http/) ? link.url : `https://runrepeat.com${link.url}`,
      };
    });

    return {
      body: true,
      innerHTML: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: list,
      }),
      type: 'application/ld+json',
    };
  },

  getUpcomingProductSchema(pageData) {
    const { meta } = usePageStore();
    const productSchema = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: pageData.product.name,
      brand: {
        '@type': 'Brand',
        name: pageData.product.brand_name,
      },
      image: 'https://cdn.runrepeat.com/storage/uploads/upcoming_shoe.jpg',
      description: meta.description,
      offers: {
        '@type': 'Offer',
        price: pageData.product?.price ?? '',
        priceCurrency: pageData.global.currency.code,
      },
    };

    return {
      body: true,
      innerHTML: JSON.stringify(productSchema),
      type: 'application/ld+json',
    };
  },
  sendPageview(referrer) {
    if (process.client) {
      const nuxtApp = useNuxtApp();
      nextTick(() => {
        if (document.readyState === 'complete') {
          nuxtApp.$gaEvents.sendPageview(referrer);
        } else {
          window.addEventListener('load', () => {
            nuxtApp.$gaEvents.sendPageview(referrer);
          })
        }
      });
    }
  },
};
