<template>
  <span>
    <input :id="id" type="checkbox" :name="name" :checked="checked" />
    <span class="checkbox" @click.prevent />
  </span>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: [String],
      default: '',
    },
    id: {
      type: [String],
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
label.checkbox-label,
span.checkbox-label {
  display: inline-block;
  line-height: 26px;
  text-align: center;
  cursor: pointer;

  input {
    visibility: hidden;
    display: block !important;
    height: 0;
    width: 0;
    position: absolute;
    overflow: hidden;
  }

  span.checkbox {
    /* <-- style the artificial checkbox */
    height: 26px;
    width: 26px;
    border-radius: 2px;
    border: 1px solid $grey-dark;
    display: inline-block;
    position: relative;
    float: left;
    margin: 0 5px 1px 0;

    &:hover {
      cursor: pointer;
    }
  }

  [type='checkbox']:checked + span.checkbox {
    /* <-- style its checked state..with a ticked icon */
    background-color: $orange-background;
    border: 1px solid $border-color;

    &:before {
      content: '';
      color: #fff;
      @include icon-svg-checkmark-white;
      position: absolute;
      top: calc(50% - 5.5px);
      left: calc(50% - 7px);
      height: 11.1px;
      width: 14px;
    }
  }
}
</style>
