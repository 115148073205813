<template>
  <div v-if="open" class="rr-modal-dialog-wrapper" :class="customClasses" @keyup.esc="close">
    <div class="rr-modal-dialog-spacer">
      <div v-on-click-outside="closeIfNotPopover" class="rr-modal-dialog">
        <div class="rr-modal-dialog-header">
          <CrossIcon class="rr-modal-dialog-close" @click.native="close" />
          <h2 v-if="h2Title" v-html="title" />
          <h3 v-else v-html="title" />
        </div>
        <div class="rr-modal-dialog-content">
          <LazyHydrate :when-triggered="open" when-visible>
            <slot />
          </LazyHydrate>
        </div>
      </div>
    </div>
    <div class="rr-modal-dialog-background" @click="close" />
  </div>
</template>

<script>
import CrossIcon from '@/components/icons/awesome-font/CrossIcon';

export default {
  components: {
    CrossIcon,
  },
  props: {
    customClasses: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    h2Title: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:isOpen'],
  data() {
    return {
      open: false,
    };
  },
  watch: {
    isOpen(value) {
      this.open = value;
    },
  },
  created() {
    this.open = this.isOpen;
  },
  methods: {
    close() {
      this.open = false;
      this.$emit('update:isOpen', this.open);
    },
    closeIfNotPopover(event) {
      // TODO: maybe check for events: ['dblclick', 'click'],
      if (event.target.className !== 'popover-content') {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rr-modal-dialog-wrapper {
  .rr-modal-dialog-spacer {
    position: fixed;
    top: 50%;
    left: 50%;
    // chrome bugfix: https://bugs.chromium.org/p/chromium/issues/detail?id=521364#c86
    // important: do not remove 0px
    transform: translateX(calc(-50% - 0px)) translateY(calc(-50% - 0px));
    padding: 15px;
    z-index: 10000;
    overflow-y: visible;
    width: 480px;
    max-width: 100%;
    max-height: 100%;
    @media (max-width: $break_sm_under) {
      top: 60px;
      // chrome bugfix: https://bugs.chromium.org/p/chromium/issues/detail?id=521364#c86
      // important: do not remove 0.5px
      transform: translateX(calc(-50% - 0.5px)) translateY(0);
    }
  }
  .rr-modal-dialog {
    width: 100%;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px 15px;

    .rr-modal-dialog-content {
      height: 100%;
      overflow-y: scroll;
      overflow-x: visible;
      max-height: calc(100vh - 200px);

      @media (max-width: $break_sm_under) {
        @include compact_scrollbar;
      }
    }
    .rr-modal-dialog-close {
      float: right;
      line-height: 25px;
      text-align: center;
      &:hover,
      &:focus {
        border-color: $orange-background;
        cursor: pointer;
      }
    }

    .rr-modal-dialog-header {
      h3,
      h2 {
        margin-top: 10px;
        width: calc(100% - 40px);
        display: inline-block;
        font-size: 26px;
        margin-bottom: 20px;
      }
    }
  }
  .rr-modal-dialog-background {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
