<template>
  <svg width="16" height="16" style="display: inline-block; margin: 0 auto" viewBox="32 254 16 15">
    <g>
      <path
        class="youtube-duration-fill"
        d="M39.5,254c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5C47,257.4,43.6,254,39.5,254z   M40,261.8c0,0.3-0.2,0.5-0.5,0.5h-4c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5H39v-5.5c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5  V261.8z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.youtube-duration-fill {
  fill: #cccccc;
}
</style>
