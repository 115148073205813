<template>
  <figure
    class="local-video"
    :style="{ width: width || '100%' }"
    v-intersection-observer="[
        onIntersection,
        { rootMargin: $device.isMobileOrTablet ? '800px' : '400px' },
      ]"
  >
    <!-- <div v-if="imagePreview && !playingState" class="local-video__cover" @click="play()">
      <img :src="imagePreview" />
      <span class="local-video__cover__play"
        ><span class="local-video__cover__triangle"></span
      ></span>
    </div> -->
    <video
      v-element-visibility="toggle"
      :poster="posterImage"
      :loop="loop === 'true'"
      class="local-video__video"
      :controls="Boolean(controls || !autoload)"
      muted
      :preload="autoload && wasIntersected ? 'auto' : 'none'"
      ref="videoEl"
      playsinline
    >
      <source v-if="webmSrc" :src="webmSrc" type="video/webm">
      <source :src="src" type="video/mp4">
    </video>
    <figcaption v-if="description" class="local-video__description" v-html="description"></figcaption>
  </figure>
</template>

<script setup>
const props = defineProps({
  src: {
    type: String,
    default: '',
  },
  poster: {
    type: String,
    default: null,
  },
  loop: {
    type: String,
    default: 'true',
  },
  width: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
    default: '405px',
  },
  imagePreview: {
    type: String,
    default: '',
  },
  controls: {
    type: Boolean,
    default: false,
  },
  description: {
    type: String,
    default: '',
  },
});

const videoEl = ref();
const wasIntersected = ref(false);
const playingState = ref();
const posterImage = ref();

// const autoload = ref(!props.poster);
const autoload = ref(true);

const webmSrc = computed(() =>
  // false &&
  props.src.includes('runrepeat.com/storage') && props.src.includes('.mp4')
    ? props.src.replace('.mp4', '.webm')
    : ''
);

function play() {
  if (videoEl.value) {
    playingState.value = videoEl.value.play();
  }
}

function stop() {
  if (playingState.value && videoEl.value) {
    playingState.value.then(() => {
      videoEl.value.pause();
      playingState.value = null;
    });
  }
}

function toggle(visible) {
  if (props.poster && !autoload.value) {
    if (visible) {
      posterImage.value = props.poster;
    }
    return;
  }

  if (visible) {
    play();
  } else {
    stop();
  }
}

function onIntersection([{ isIntersecting }]) {
  if (isIntersecting) {
    wasIntersected.value = true;
  }
}

function addLoadingState() {
  if (!videoEl.value || !autoload.value) {
    return;
  }
  videoEl.value.addEventListener('loadstart', event => {
    event.target.classList.add('loading');
  });
  videoEl.value.oncanplay = event => {
    event.target.classList.remove('loading');
  };
}

onMounted(() => {
  addLoadingState();
});
</script>

<style lang="scss">
.local-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  .local-video__video {
    width: 100%;
    object-position: 50% 0;
  }
  .local-video__video.loading {
    @extend %loading-placehodler;
    width: 100%;
    height: 100%;
    border: none;
  }
  .local-video__cover {
    position: relative;
    cursor: pointer;
    .local-video__cover__play {
      background-color: #f55310;
      display: inline-block;
      width: 90px;
      height: 90px;
      border-radius: 45px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      .local-video__cover__triangle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-left: 35px solid #fff;
        border-right: 0px solid transparent;
        border-top: 25px solid transparent;
        border-bottom: 25px solid transparent;
        margin-left: 7px;
      }
    }
  }
  .local-video__description {
    text-align: center;
    margin-top: 20px;
  }
}
</style>
