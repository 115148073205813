/**
 * Map the facts groups names from filter groups to
 * sort slugs, So:  *  for price filter group,
 * its sort options are:
 *      ['lowest_price', 'highest_price', 'discount'],
 */
const CORRESPONDING_SORT_FOR_FACTS = {
  price: ['lowest_price', 'highest_price', 'discount'],
  score: ['score'],
  'heel-to-toe-drop': ['lowest_drop', 'highest_drop'],
};

export const useRankingSortStore = defineStore('rankingSort', {
  state: () => ({
    selectedSort: '',
    selectedSortUpdatedSilently: false,
    sortingOptions: {},
  }),

  getters: {
    sortingOptionsFormatted(state) {
      return Object.keys(state.sortingOptions)
        .map(key => {
          return state.sortingOptions[key].enable
            ? {
              id: state.sortingOptions[key].value,
              label: state.sortingOptions[key].name,
              value: state.sortingOptions[key].value,
              img: null,
              description: state.sortingOptions[key].description || '',
            }
            : null;
        })
        .filter(option => option);
    },
    selectedSortFormatted(state) {
      return {
        label: this.selectedSortOption.name,
        value: this.selectedSortOption.value,
      };
    },
    selectedSortOption(state) {
      const key = Object.keys(state.sortingOptions).find(
        key => state.selectedSort === state.sortingOptions[key].value,
      );
      return key ? state.sortingOptions[key] : { name: '', value: '' };
    },

    formattedOptionsCurrentFact(state) {
      return factSlug =>
        this.sortingOptionsFormatted.filter(formattedOption =>
          CORRESPONDING_SORT_FOR_FACTS?.[factSlug]?.includes(formattedOption.value),
        );
    },
  },

  actions: {
    setSortingOptions(options) {
      this.sortingOptions = options || {};
    },
    fill({ sortingOptions, sorting }) {
      this.setSortingOptions(sortingOptions);
      if (sorting) {
        this.setSelectedSort({ sort: sorting, silently: true });
      }
    },
    setSelectedSort({ sort, silently = false }) {
      if (this.sortingOptions[sort] && this.sortingOptions[sort].enable) {
        this.selectedSort = sort;
        this.selectedSortUpdatedSilently = silently;
      }
    },
    listTypeChanged(newListType) {
      if (
        (newListType === 'deals' && this.selectedSort === 'recommended_rank') ||
        (newListType !== 'deals' && this.selectedSort === 'discount')
      ) {
        this.selectedSort = newListType === 'deals' ? 'discount' : 'recommended_rank';
        this.selectedSortUpdatedSilently = true;
      }
    },
  },

});