// TODO: define cancel token logic if needed
import { usePageStore } from "~/stores/page";
import { useMainStore } from "~/stores/main";
import { useShopsStore } from "~/stores/shops";
import { useSimpleComparisonStore } from "~/stores/simpleComparison";

let getUpdateToPicksSource = null;

export const useGuideStore = defineStore('guide', {
  state: () => ({
    slug: '',
    breadcrumbs_title: '',
    topProducts: [],
    topPicksData: {},
    additionalProductsLists: [],
    mostPopularProducts: [],
    bestInCategory: [],
    bestInCategoryTitle: '',
    otherGuides: [],
    category: {
      slug: '',
      name: '',
    },
    matchingCategory: {
      slug: '',
      name: '',
      count: 0,
    },
    scoreDistribution: [],
    contentSections: [],
    priceReviewsDataMatrix: [],
    primaryImage: { url: '' },
    guideGender: null,
    updatingTopPicks: true,
  }),

  getters: {
    guideTitleWithoutBest(state) {
      return state.breadcrumbs_title.toLowerCase().replace('best', '') ?? '';
    },
    numberColors(state) {
      // Do not include 'any color'
      return shoe => Object.keys(state.topPicksData[shoe.id]?.offers?.by_color ?? {}).filter(k => k !== '0').length;
    },
    currentAnyColorListPrice(state) {
      return shoe => state.topPicksData[shoe.id]?.offers?.by_color?.['0']?.[0]?.list_price_formatted;
    },
    currentAnyColorOffer(state) {
      return shoe => state.topPicksData[shoe.id]?.offers?.by_color?.['0']?.[0];
    },
    currentAnyColorPrice(state) {
      return shoe => this.currentAnyColorOffer(shoe)?.price_formatted;
    },
    currentAnyColorPriceShop(state) {
      return shoe => state.topPicksData[shoe.id]?.shops?.[this.currentAnyColorOffer(shoe)?.shop_id]?.name ?? '';
    },
    currentAnyColorLink(state) {
      return shoe => this.currentAnyColorOffer(shoe)?.affiliate_link;
    },
  },

  actions: {
    fill({
           article,
           best_in_category,
           other_guides_list,
           top_list,
           category,
           matching_catalog_page,
           most_popular,
           score_distribution,
           toc,
           primary_image,
           additional_products_lists,
           shops
         }) {

      if (best_in_category) {
        this.bestInCategory = best_in_category.items;
        this.bestInCategoryTitle = best_in_category.title;
      }
      this.otherGuides = other_guides_list;

      if (top_list) {
        this.topProducts = top_list;
      }

      if (most_popular) {
        this.mostPopularProducts = most_popular;
      }

      this.category = category;
      this.matchingCategory = matching_catalog_page;

      if (toc) {
        this.contentSections = toc;
      }

      if (score_distribution) {
        this.scoreDistribution = score_distribution;
      }

      if (primary_image) {
        this.primaryImage = primary_image;
      }

      if (additional_products_lists && additional_products_lists.length > 0) {
        this.additionalProductsLists = additional_products_lists.filter(list => {
          return list.products && list.products.length > 0;
        });
      }

      if (shops) {
        useShopsStore().setShops(shops);
      }

      if (article.page_gender) {
        this.guideGender = article.page_gender;
        useMainStore().gender = article.page_gender;
      }

      if (article.slug) {
        this.slug = article.slug;
      }

      if (article.breadcrumbs_title) {
        this.breadcrumbs_title = article.breadcrumbs_title;
      }
    },
    async updateTopPicks() {
      try {
        if (getUpdateToPicksSource) {
          getUpdateToPicksSource.cancel();
        }

        // getUpdateToPicksSource = CancelToken.source();

        this.updatingTopPicks = true;

        const { top_picks, comparison_prices } = await $api(
          `/api/pages/guide/${this.slug}/top-picks`,
          // {
          //   cancelToken: getUpdateToPicksSource.token,
          // },
        );

        top_picks.forEach(topPick => {
          topPick.products.forEach(product => {
            this.topPicksData[product.id] = product;
          });
        })

        if (comparison_prices) {
          useSimpleComparisonStore().updateProductPrices(comparison_prices);
        }

        this.updatingTopPicks = false;
      } catch (error) {
        // if (this.$axios.isCancel(error)) {
        //   console.log('request aborted...');
        // }
      }
    },
  },
})
