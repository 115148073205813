<template>
  <div class="product-top-author">
    <a v-if="author.url" class="author-image" :href="author.url" :title="author.name">
      <img v-if="author.img" loading="lazy" :src="author.imgSm" :alt="author.name" />
    </a>
    <div v-else-if="author.img" class="author-image">
      <img loading="lazy" :src="author.imgSm" :alt="author.name" height="32" width="32" />
    </div>
    <div class="author-text-container">
      <div class="author-name">
        <a v-if="author.url" :href="author.url">{{ author.name }}</a>
        <span v-else>{{ author.name }}</span>
        {{ $t('author.on_date', { date: '' }).toLowerCase() }}
        <time :datetime="datePublished.published_at">{{ datePublished.published_at_text }}</time>
      </div>
      <div class="methodology-link" v-if="methodologyVersion">
        <span class="methodology-link-spacer">|</span>
        <span v-html="$t('author.methodology', { version: methodologyVersion, link: localePath({ name: 'slug', params: { slug: $t('url.testing_methodology') } }) })"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    author: {
      type: Object,
      required: true,
    },
    datePublished: {
      type: Object,
      required: true,
    },
    methodologyVersion: {
      type: String,
      default: '1.6',
    },
  },
};
</script>

<style lang="scss" scoped>
.product-top-author {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
  width: 100%;

  .author-image {
    display: block;

    img {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background: #fff;
    }
  }
  .author-text-container {
    padding-left: 4px;
  }

  @media (min-width: $break_xs_upper) {
    .author-name,
    .methodology-link {
      display: inline-block;
    }
  }

  @media (max-width: $break_xs_under) {
    .methodology-link-spacer {
      display: none;
    }
  }
}
</style>
