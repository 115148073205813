<template>
  <div class="accordion">
    <div>
      <div class="heading">
        <h2 :id="titleId">
          <button type="button" @click.prevent="toggle">
            <span class="caret"></span> <span v-html="title"></span>
          </button>
        </h2>
      </div>
      <Collapse v-model="visible" v-html="html" />
    </div>
  </div>
</template>
<script>
import { Collapse } from 'uiv';

export default {
  components: {
    Collapse,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    titleId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: this.open,
      html: '',
    };
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
  },
  mounted() {
    // Create a new div element
    const tmpElement = document.createElement('div');
    tmpElement.innerHTML = this.content;
    const heading = tmpElement.querySelector('.heading');
    if (heading) {
      heading.parentNode.removeChild(heading);
    }
    this.html = tmpElement.innerHTML;
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  :deep() {
    @extend %accordion;
  }
}
</style>
