import factHelper from '~/utils/fact';
import { useRankingStore } from "~/stores/ranking";

const INITIALLY_EXCLUDED_FACTS = ['msrp_formatted'];

export const useRankingSlimStore = defineStore('rankingSlim', {
  state: () => ({
    selectedFacts: [],
    factsMapped: {},
    factFilterOpen: null,
    ready: false,
    contentReady: false,
    emptyFacts: [],
    syncScrollCounter: 0
  }),
  getters: {
    factsMappedOrdered(state) {
      return Object.keys(state.factsMapped).length
        ? state.selectedFacts.map(selectedFact => state.factsMapped[selectedFact]).filter(fact => fact)
        : [];
    },
    selectedFactsNonEmpty(state) {
      return state.selectedFacts.filter(fact => !state.emptyFacts.includes(fact));
    },
    sortFactSlugsByPopularity(state) {
      return slugs => {
        // already sorted?
        if (state.ready) {
          return slugs;
        }

        return slugs
          .map(
            slug =>
              useRankingStore()?.items?.[0]?.facts?.[slug] || {
                position: 0,
                slug: '',
              },
          )
          .sort(factHelper.orderByPopularity)
          .map(fact => fact.slug);
      };
    },
  },

  actions: {
    addSelectedFact(fact) {
      this.selectedFacts.push(fact.slug);
    },
    removeSelectedFact(fact) {
      const index = this.selectedFacts.findIndex(selectedFact => selectedFact.slug === fact.slug);
      if (index) {
        this.selectedFacts.splice(index, 1);
      }
    },
    moveFactTo({ originFact, destinationFact }) {
      const keys = Object.keys(this.factsMapped);
      const indexDestination = keys.findIndex(key => key === destinationFact.slug);
      const indexOrigin = keys.findIndex(key => key === originFact.slug);
      keys.splice(indexOrigin, 1);
      keys.splice(indexDestination, 0, originFact.slug);
      const factsMapped = {};
      [...new Set(keys)].forEach(key => {
        factsMapped[key] = this.factsMapped[key];
      });
      this.factsMapped = factsMapped;
    },
    setFactFilterOpen(factSlug) {
      if (this.factsMapped[factSlug] || factSlug === null) {
        this.factFilterOpen = factSlug;
      }
    },
    setNewScrollSync() {
      this.syncScrollCounter += 1;
    },
    initFullPageLoad() {
      useRankingStore().setMode('fullscreen');
      this.mapFacts();
      this.ready = true;
      this.contentReady = true;
    },
    setSelectedFacts(facts) {
      if (!facts || !facts.length) {
        this.selectedFacts = [];
        return;
      }

      // Make sure the order of facts are same as the mapped ones.
      this.selectedFacts = Object.keys(this.factsMapped)
        .map(factSlug => facts.find(fact => fact === factSlug))
        .filter(fact => fact);
    },
    mapFacts() {
      const ranking = useRankingStore();
      // const { t } = useNuxtApp().$i18n;

      // console.log(ranking.items[0].facts);
      // fixed primary facts: score, users score
      const PRIMARY_FACTS = [
        {
          slug: 'score',
          name: 'Audience score', //t('generic.audience_score'),
        },
        // {
        //   slug: 'expert_score',
        //   name: 'Expert rating',
        // },
        // {
        //   slug: 'users_score',
        //   name: 'User rating',
        // },
        {
          slug: 'msrp_formatted',
          name: 'MSRP',
        },
      ];

      // facts
      const factsSlugs = [
        ...PRIMARY_FACTS.map(primaryFat => primaryFat.slug),
        'price',
        ...this.sortFactSlugsByPopularity(
          [
            ...new Set(
              ...ranking.items.map(product => {
                return Object.keys(product.facts || {});
              }),
            ),
          ].filter(fact => {
            // const { value: factValue } = ranking.items[0].facts[fact];

            // Do not include boolean values (such as features cushioned=true/false)
            // Should be under features.
            // Plus do not include primary facts.
            return !['category_id', 'id', 'is_new', ...PRIMARY_FACTS.map(p => p.slug)].includes(fact);
          }),
        ),
      ];

      const factsMapped = {};
      factsSlugs.forEach(factSlug => {
        const shoe = ranking.items[0];
        const primaryFact = PRIMARY_FACTS.find(primaryFact => primaryFact.slug === factSlug);
        if (factSlug === 'price') {
          // Add best deal price.
          factsMapped.price = {
            name: 'Sales price',
            slug: 'price',
            values: ranking.items.map(item => item.deals?.[0]?.price_formatted || 'N/A'),
          };
        } else if (primaryFact) {
          factsMapped[factSlug] = {
            name: primaryFact.name || '',
            slug: primaryFact.slug,
            values: ranking.items.map(item =>
              item?.[primaryFact.slug] ? factHelper.formatValue(primaryFact.slug, item?.[primaryFact.slug]) : 'N/A',
            ),
          };
        } else {
          const fact = ranking.items[0].facts[factSlug];
          factsMapped[factSlug] = {
            name: fact.name,
            slug: fact.slug,
            values: ranking.items
              .map(item => {
                const fact = item.facts?.[factSlug] || { value: null };

                // Some product do not have value about some facts.
                if (fact.value === null) {
                  return 'N/A';
                }

                // If several values take the first one.
                const valueToDisplay = factHelper.buildFactValueToDisplay(fact);
                return factHelper.formatValue(
                  factSlug,
                  Array.isArray(valueToDisplay)
                    ? valueToDisplay.map(v => v).join(', ') || 'N/A'
                    : fact.value?.name,
                );
              })
              .filter(value => value),
            position: fact?.position,
          };
        }
      });

      // Already ordered?
      // if (!this.ready) {
      //   let factsMappedFinal = {};
      //   let factsOrderedByPopularity = factHelper.orderByPopularity(Object.values(factsMapped));
      //   factsOrderedByPopularity.forEach(fact => (factsMappedFinal[fact.slug] = fact));
      //   factsMapped = factsMappedFinal;
      // }
      // console.log(factsMappedFinal);
      this.emptyFacts = Object.keys(factsMapped)
        .map(slug => {
          if (factsMapped[slug].values.every(value => ['N/A'].includes(value))) {
            return slug;
          }
          return null;
        })
        .filter(slug => slug !== null);

      this.factsMapped = factsMapped;

      if (!this.selectedFacts.length) {
        this.setSelectedFacts(
          // []
          // this.selectedFacts.filter(selectedFact => factsSlugs.includes(selectedFact))
          factsSlugs.filter(fact => !INITIALLY_EXCLUDED_FACTS.includes(fact)),
        );
      }
    },
    openFactFilter(fact) {
      this.setFactFilterOpen(fact);
    },
    closeFactFilter() {
      this.setFactFilterOpen(null);
    },
    toggleFactFilter(fact = null) {
      if (this.factFilterOpen && this.factFilterOpen === fact) {
        this.closeFactFilter();
      } else {
        this.openFactFilter(fact);
      }
    },
  },
})
