import fact from '../utils/fact';
import { useProductsStore } from "~/stores/products";
import { usePageStore } from "~/stores/page";

// TODO: implewment CancelToken
let suggestCancelSource = null;

const NUMBER_OF_FIRSTS_SIMILAR = 4;

export const useSimpleComparisonStore = defineStore('simpleComparison', {
  state: () => ({
    products: [],
    searchResultProducts: [],
    numberOfProductsToCompare: 4,
    loadingSimilarProducts: false,
    searchQuery: '',
    searchModalOpened: false,
    loadingShoes: false,
    sameBrand: false,
    sameCollection: false,
    currentProductId: 0,
  }),

  getters: {
    productsForSelect(state) {
      // Make sure that the search query is for this product.
      // return state.searchResultProducts[product.id] && state.searchResultProducts[product.id].length
      //   ? state.searchResultProducts[product.id].slice(1)
      return state.searchResultProducts.filter(
        option =>
          // Remove the products that are compared "apart"
          // from this given product.
          !state.products.map(p => p.id).includes(option.id),
      );
    },
    allProductsForSelect(state) {
      // Make sure that the search query is for this product.
      // return state.searchResultProducts[product.id] && state.searchResultProducts[product.id].length
      //   ? state.searchResultProducts[product.id].slice(1)
      const currentProduct = useProductsStore().current;
      return state.searchResultProducts.filter(option => option.id !== currentProduct.id);
    },
    formattedProductsForSelect() {
      return product =>
        this.productsForSelect(product)
          // Remove all current products.
          .map(p => this.formatOption(p));
    },
    formatOption() {
      return product => {
        return {
          id: product.id,
          label: product.name,
          value: product.slug,
          img: product.img_url_xs,
        };
      };
    },
    verySimilar(state) {
      return product => {
        const index = state.products.findIndex(p => p.id === product.id);
        return index <= NUMBER_OF_FIRSTS_SIMILAR && index > -1;
      };
    },
    allProductsProps(state) {
      const propSlugs = [...new Set([].concat(...state.products.map(product => Object.keys(product))))];
      let output = propSlugs.map(propSlug => {
        const product = state.products.find(product => product[propSlug]);
        return {
          slug: propSlug,
          name: product?.[propSlug].name,
          position: product?.[propSlug].position,
          type: product?.[propSlug].type,
          units: product?.[propSlug].units,
          value_labels: product?.[propSlug].value_labels,
        };
      });
      return output;
    },
    orderedByPosition() {
      return rows => rows.sort(fact.orderByPopularity);
    },
    // Basically skipping all rows with all columns equal to 'N/A'
    withoutEmptyRows() {
      const { t } = useNuxtApp().$i18n;
      const EMPTY_VALUES = [t('generic.n_a'), t('generic.no')];
      return rows =>
        rows.filter(
          row =>
            !row.columns.every(column =>
              Array.isArray(column.value)
                ? column.value.every(v => EMPTY_VALUES.includes(v))
                : EMPTY_VALUES.includes(column.value),
            ),
        );
    },
    nonFeaturesRows(state) {
      // first, get the non features properties.
      const nonFeatures = this.allProductsProps.filter(prop =>
        state.products.some(
          product =>
            product?.[prop.slug]?.type && !['percentage', 'bool'].includes(product[prop.slug].type),
        ),
      );

      // Build columns, non features value can be object, array, string.
      const buildColumns = nonFeature => {
        return state.products.map(product => ({
          value: fact.buildFactValueToDisplay(product?.[nonFeature]),
        }));
      };

      // Build rows
      return this.withoutEmptyRows(
        nonFeatures.map(nonFeature => ({
          label: nonFeature.name,
          columns: buildColumns(nonFeature.slug),
          type: 'array',
          position: nonFeature?.position,
        })),
      );
    },
    featuresRows(state) {
      const { t } = useNuxtApp().$i18n;
      // Features are of type boolean
      const features = this.allProductsProps.filter(prop =>
        state.products.some(product => product?.[prop.slug]?.type === 'bool'),
      );

      return this.withoutEmptyRows(
        features.map(feature => ({
          type: 'normal',
          columns: state.products.map(product => ({
            value: product?.[feature.slug]?.value ? t('generic.yes') : t('generic.no'),
          })),
          label: feature.name,
          slug: feature.slug,
          position: feature?.position,
        })),
      );
    },
    percentageRows(state) {
      const { t } = useNuxtApp().$i18n;
      // Features are of type boolean
      const percentages = this.allProductsProps.filter(prop =>
        state.products.some(product => product?.[prop.slug]?.type === 'percentage'),
      );

      return this.withoutEmptyRows(
        percentages.map(percentage => ({
          type: 'normal',
          columns: state.products.map(product => ({
            value: product?.[percentage.slug]?.value || t('generic.n_a'),
          })),
          label: percentage.name,
          slug: percentage.slug,
        })),
      );
    },
    allRows(state) {
      // console.log(this.allProductsProps);
      const { t } = useNuxtApp().$i18n;
      const EMPTY_VALUES = [t('generic.n_a'), t('generic.no')];
      const features = this.allProductsProps.filter(prop =>
        state.products.some(
          product => product?.[prop.slug]?.type,
        ),
      );

      // Build columns, non features value can be object, array, string.
      const buildColumns = propSlug => {
        return state.products.map(product => ({
          id: product.id,
          slug: product.slug,
          name: product.name,
          value: product?.[propSlug]?.value,
        }));
      };

      // Build rows
      // return this.withoutEmptyRows(
        return features.map(feature => ({
          label: feature.name,
          columns: buildColumns(feature.slug),
          type: feature?.type,
          position: feature?.position,
          units: feature?.units,
          value_labels: feature?.value_labels,
        }))
          .filter((row) => {
            return !row.columns.every((column) => {
              if (Array.isArray(column.value)) {
                return column.value.length === 0
                  || column.value.every((v) => v === t('generic.n_a'))
                  || column.value.every((v) => v === t('generic.no'))
                  || column.value.every((v) => v === false)
                  || column.value.every((v) => v === null)
              } else {
                return column.value === t('generic.n_a')
                || column.value === t('generic.no')
                || column.value === false
                || column.value === null
              }
            });
          });
      // );
    },
    rowsData(state) {
      const { t } = useNuxtApp().$i18n;

      // const imagesRow = {
      //   label: '',
      //   value: '',
      //   columns: state.products.map(product => ({
      //     ...product,
      //   })),
      //   type: 'images',
      // };

      // const scoreRow = {
      //   label: t('generic.audience_score'),
      //   columns: state.products.map(product => ({
      //     ...product.corescore,
      //     ...product,
      //   })),
      //   type: 'score',
      // };

      // const usersRatingRow = {
      //   label: t('product.comparison.users_rating'),
      //   columns: state.products.map(product => ({
      //     ...product,
      //   })),
      //   type: 'usersRating',
      // };
      //
      // const expertReviewsRow = {
      //   label: t('generic.experts'),
      //   columns: state.products.map(product => ({
      //     ...product,
      //   })),
      //   type: 'expertReviews',
      // };

      // const priceRow = {
      //   label: t('product.comparison.best_price'),
      //   columns: state.products.map(product => ({
      //     formatted: product?.price?.formatted ?? t('generic.n_a'),
      //     msrp: product?.price?.msrp ?? '',
      //     value: product?.price?.value,
      //     slug: product?.slug ?? '',
      //   })),
      //   type: 'price',
      // };

      // const rankingRow = {
      //   label: t('generic.ranking'),
      //   columns: state.products.map(product => ({ ...(product?.ranking || {}) })),
      //   type: 'rank',
      // };

      // const popularityRow = {
      //   label: t('generic.popularity'),
      //   columns: state.products.map(product => ({ ...(product?.popularity || {}) })),
      //   type: 'rank',
      // };
      //
      // const colorwaysRow = {
      //   label: t('product.comparison.colorways_number'),
      //   columns: state.products.map(product => ({ value: product.colorways })),
      //   type: 'normal',
      // };

      return [
        // imagesRow,
        // scoreRow,
        // usersRatingRow,
        // expertReviewsRow,
        // priceRow,
        // All the rest rows should be ordered by position field
        // from api.
        ...this.orderedByPosition([
          // Non features
          // ...this.nonFeaturesRows,
          ...this.allRows
          // Features.
          // ...this.featuresRows,
          // Percentage type.
          // ...this.percentageRows,
          // rankingRow,
          // popularityRow,
          // colorwaysRow,
        ]),
      ];
    },
  },

  actions: {
    setSearchProducts({ products, productId }) {
      this.searchResultProducts = products.filter(p => p.id !== productId); // No, need to have current shoe searched for.
    },
    moveProduct({ product, index }) {
      if (index > 0 && index <= this.numberOfProductsToCompare - 1) {
        this.products.splice(index, 1, product);
      }
    },
    resetSearchProducts() {
      // if (this.searchResultProducts[productId]) {
      this.searchResultProducts = [];
      // }
      this.searchQuery = '';
    },
    closeSearchModal() {
      this.searchModalOpened = false;
    },
    async fetch({
      productId,
      size = this.numberOfProductsToCompare,
      sameCollection = false,
      forceProducts = [],
      forceColor = '',
    }) {
      this.currentProductId = productId;
      this.sameCollection = sameCollection;
      try {
        const products = await $api(
          `/${sameCollection ? 'collection-' : ''}comparison/${productId}`,
          {
            params: {
              size,
              same_brand: this.sameBrand ? 1 : 0,
              'force_product_ids[]': forceProducts,
              force_color: forceColor,
            },
            headers: {
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              Expires: '0',
            },
          },
        );
        return products || [];
      } catch (error) {
        console.log('request failed');
      }
      return [];
    },
    // Fetching initial products to compare.
    async fetchInitial({
      productId,
      sameCollection,
      forceProducts = [],
      forceColor = ''
    }) {
      this.currentProductId = productId;
      this.sameCollection = sameCollection;
      const products = await this.fetch({
        productId,
        size: this.numberOfProductsToCompare,
        sameCollection,
        forceProducts,
        forceColor,
      });

      this.updateProducts(products);
    },
    updateProducts(products) {
      // From Jens: When the shoe is "bad" or "decent"
      // then the 1st shoe that it is compared to *must* be "good", "great" or "superb"
      if (
        products.length > 2 &&
        (products[0].score_color === 'yellow' || products[0].score_color === 'red') &&
        (products[1].score_color === 'yellow' || products[1].score_color === 'red')
      ) {
        let betterProduct = null;
        let betterProductIndex = null;
        products.forEach((product, index) => {
          if (
            !betterProduct &&
            index > 1 &&
            (product.score_color === 'green' || product.score_color === 'light_green')
          ) {
            betterProduct = product;
            betterProductIndex = index;
          }
        });

        if (betterProduct && betterProductIndex) {
          products.splice(betterProductIndex, 1);
          products.splice(1, 0, betterProduct);
        }
      }

      this.products = products.slice(0, this.numberOfProductsToCompare);
    },
    async toggleSameBrand() {
      this.sameBrand = !this.sameBrand;

      await this.fetchInitial({
        productId: this.currentProductId,
        sameCollection: this.sameCollection,
      });

      const page = usePageStore();

      if (page.type === 'product') {
        useNuxtApp().$gaEvents.productSimilarSameBrandClicked();
      }

      if (page.type === 'buying_guide') {
        useNuxtApp().$gaEvents.guideSimilarSameBrandClicked();
      }
    },
    async search({ productId, query = '', size = 10 }) {
      try {
        if (suggestCancelSource) {
          // suggestCancelSource.cancel();
        }
        // suggestCancelSource = CancelToken.source();
        this.loadingShoes = true;
        const products = await $api(
          `comparison/${productId}`,
          {
            params: {
              q: query,
              size: size,
              'exclude_product_ids[]': this.products.map(product => product.id),
            }
          },
          // {
          //   cancelToken: suggestCancelSource.token,
          // },
        );
        this.setSearchProducts({ products, productId });
        this.searchQuery = query;
        this.loadingShoes = false;
      } catch (error) {
        // if (this.$axios.isCancel(error)) {
        //   console.log('request aborted...');
        // }
      }
    },
    addProduct(productId) {
      const product = this.productsForSelect.find(product => product.id === productId);

      if (product) {
        this.products.push(product);
      }
    },
    updateProductByIndex({ productId, index }) {
      const product = this
        .productsForSelect(this.products[index])
        .find(product => product.id === productId);
      if (product) {
        this.moveProduct({ product, index });
      }
    },
    removeProduct({ index, productName }) {
      this.products.splice(index, 1);

      const page = usePageStore();

      if (page.type === 'product') {
        useNuxtApp().$gaEvents.productSimilarProductRemoved(productName, index + 1);
      }

      if (page.type === 'buying_guide') {
        useNuxtApp().$gaEvents.guideSimilarProductRemoved(productName, index + 1);
      }
    },
    openSearchModal(location = '') {
      this.searchModalOpened = true;

      if (!location) {
        return;
      }

      const page = usePageStore();

      if (page.type === 'product') {
        useNuxtApp().$gaEvents.productSimilarSearchClicked(location);
      }

      if (page.type === 'buying_guide') {
        useNuxtApp().$gaEvents.guideSimilarSearchClicked(location);
      }
    },
    updateProductPrices(productPrices) {
      this.products.forEach((product, index) => {
        if (productPrices[product.id] && this.products[index]['price']) {
          Object.keys(productPrices[product.id]).forEach(key => {
            this.products[index]['price'][key] = productPrices[product.id][key];
          })
        }
      });
    }
  },
})
