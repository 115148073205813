import { useMainStore } from "~/stores/main";
import { useColorsStore } from "~/stores/colors";
import { useProductsStore } from "~/stores/products";
import { useSizesStore } from "~/stores/sizes";

export const useWidthsStore = defineStore('widths', {
  state: () => ({
    all: { m: [], w: [] },
    widthsAvailable: {},
    currentWidthAvailabilityKey: null,
  }),

  getters: {
    /**
     * All current global gender widths.
     */
    widthsCurrentGender(state) {
      const main = useMainStore();
      const products = useProductsStore();
      const sizes = useSizesStore();
      const colors = useColorsStore();

      const isUnisex = products.current.is_unisex;
      return main.gender
        ? state.all[main.gender]
          .map(width => {
            const gender = isUnisex ? 'u' : main.gender;

            const currentBrandSizeId = sizes.currentBrandSize.id;

            // unavailable in current size,
            //              but available in others
            const availableJustInOthers =
              main.size &&
              sizes.availableSizes[gender] &&
              sizes.availableSizes[gender][width.value] &&
              sizes.availableSizes[gender][width.value].hasOwnProperty(
                colors.current.id,
              ) &&
              sizes.availableSizes[gender][width.value][
                colors.current.id
                ].indexOf(currentBrandSizeId) === -1;

            // check if current width is active
            const isActive = (gender, width) => {
              return (
                sizes.availableSizes.hasOwnProperty(gender) &&
                sizes.availableSizes[gender].hasOwnProperty(width) &&
                !availableJustInOthers
              );
            };

            const active = isActive(gender, width.value);

            // Change the name without symbol when the the shoe is unisex.
            const name = isUnisex ? width.name.split(' ')[0] : width.name;

            return { ...width, active, semiActive: false, name, label: name };
          })
          .filter(width => this.widthsAvailableInCategory(width))
        : [];
    },
    /**
     * Get width short name for current global with.
     */
    selectedWidthShortName(state) {
      const width = this.widthsCurrentGender.find(width => width.value === useMainStore().width);
      return width ? width.short_name : '';
    },
    selectedWidthName(state) {
      const width = this.widthsCurrentGender.find(width => width.value === useMainStore().width);
      return width?.name.split(' ')?.[0] ?? '';
    },
    /**
     * Some UI elements (size) will not show short name
     * when width is standard.
     */
    selectedWidthShortNameToDisplay(state) {
      return useMainStore().width === 'standard'
        ? ''
        : this.selectedWidthShortName;
    },
    nameForCurrentGender(state) {
      const widths = state.all[useMainStore().gender] || [];
      return widthSlug => {
        const width = widths.find(width => width.value === widthSlug);
        return width ? width.name : '';
      };
    },
    nameWithoutShortPart(state) {
      return widthName => {
        const parts = widthName.split(' ');
        return parts && parts.length > 0 ? parts[0].toLowerCase().replace('x-', 'X-') : '';
      };
    },
    widthsAvailableCurrentKey(state) {
      const main = useMainStore();
      return main.size + main.gender + useColorsStore().current.id;
    },
    widthsAvailableInCategory(state) {
      return widthToCheck =>
        // When widths availability is not set, then return true
        !state.widthsAvailable?.[state.currentWidthAvailabilityKey] ||
        // If, available, then check current width
        (state.widthsAvailable?.[state.currentWidthAvailabilityKey]?.find(
            width => width.value === widthToCheck.value,
          )?.available ??
          false);
    },
    widthAvailableLink(state) {
      return widthToLink =>
        state.widthsAvailable?.[state.currentWidthAvailabilityKey]?.find(
          width => width.value === widthToLink.value,
        )?.url ?? '';
    },
  },

  actions: {
    setGenderWidth({ gender, widths }) {
      this.all[gender] = widths;
    },
    setWidthsAvailable({ sizeGenderColor, widths }) {
      this.widthsAvailable[sizeGenderColor] = widths;
    },
    fill({ widths }) {
      this.setGenderWidth({
        gender: 'm',
        widths: Object.values(widths.m),
      });
      this.setGenderWidth({
        gender: 'w',
        widths: Object.values(widths.w),
      });
    },
    updateWidthsAvailable(widthsAvailable) {
      if (!widthsAvailable) {
        return;
      }
      const main = useMainStore();
      const key = main.size + main.gender + useColorsStore().current.id;
      this.currentWidthAvailabilityKey = key;
      this.setWidthsAvailable({
        sizeGenderColor: key,
        widths: widthsAvailable,
      });
    },
    async getWidthsAvailable(categoryId) {
      const main = useMainStore();
      const colors = useColorsStore();
      const nextKey = main.size + main.gender + colors.current.id;

      // Make sure we didn't already check availability
      if (this.widthsAvailable[nextKey]) {
        this.currentWidthAvailabilityKey = nextKey;
        return;
      }

      const { data } = await $api('/widths-available', {
        params: {
          c_id: categoryId,
          // ...(colors.current.slug ? { color: colors.current.slug } : {}),
        },
      })

      this.setWidthsAvailable({
        sizeGenderColor: nextKey,
        widths: data,
      });

      this.currentWidthAvailabilityKey = nextKey;
    },
  },
})
