const mapShortAndFullNames = {
  men: 'm',
  women: 'w',
};

export default {
  shortNameFromFull(fullName) {
    return mapShortAndFullNames.hasOwnProperty(fullName) ? mapShortAndFullNames[fullName] : '';
  },
  oppositeGender(inputGender) {
    return ['m', 'w'].find(gender => gender !== inputGender);
  },
};
