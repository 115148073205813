export const usePriceHistoryStore = defineStore('priceHistory', {
  state: () => ({
    open: false,
    series: [],
    available: false,
    loading: false,
  }),

  getters: {
    seriesSortedByPrice(state) {
      return [...state.series].sort((a, b) => {
        return a.price_local > b.price_local ? 1 : -1;
      });
    },
    lowestPrice(state) {
      return this.seriesSortedByPrice.length ? this.seriesSortedByPrice[0].price_local : 0;
    },
    highestPrice(state) {
      return this.seriesSortedByPrice.length
        ? this.seriesSortedByPrice[this.seriesSortedByPrice.length - 1].price_local
        : 0;
    },
  },

  actions: {
    toggle() {
      this.open = !this.open;
      if (this.open) {
        useNuxtApp().$gaEvents.productPriceHistoryOpened();
      }
    },
    async getData({ gender, size, width, color, productId }) {
      this.loading = true;
      const response = await $api(`/offers/price-history/${productId}?range=full`, {
        params: { gender, size, width, color },
      });
      this.series = response.history || [];
      this.loading = false;
    },
  },
})
