import { createApp, createVNode, render } from 'vue'

export default async function loadShortcodes(elements, components, appContext) {
  if (!elements || elements.length < 1) {
    return;
  }

  function normalizePropName(name) {
    return name
      .replace(/data-/g, '')
      .replace(/-[a-z]/g, match => match.replace('-', '').toUpperCase());
  }

  function getAttributes(element) {
    const output = {};

    for (let index = 0; index < element.attributes.length; index += 1) {
      const propName = element.attributes[index].name;
      if (propName !== 'data-vue-component') {
        output[normalizePropName(propName)] = element.attributes[index].value;
      }
    }

    return output;
  }

  for (const el of elements) {
    const name = el.getAttribute('data-vue-component');

    if (!Object.prototype.hasOwnProperty.call(components, name)) {
      continue;
    }

    let component = components[name];

    if (typeof component === 'function') {
      component = (await component()).default;
    }

    const props = getAttributes(el);

    // Needed for accordions and other components that need html
    if (component.props && typeof component.props.content !== 'undefined') {
      props.content = el.innerHTML;
    }

    // Since vue 3 renders component inside element (not instead) we can't use straight rendering
    // https://stackoverflow.com/questions/76634546/vue-3-render-function-app-mounts-inside-root-element-via-innerhtml-instead-of-r
    const fragment = document.createDocumentFragment();
    let vnode = createVNode(component, props);
    vnode.appContext = { ...appContext };
    render(vnode, fragment)
    el.parentNode.replaceChild(fragment, el);

    // https://stackoverflow.com/questions/69488256/vue-3-append-component-to-the-dom-best-practice
    // let vnode = createVNode(component, props);
    // vnode.appContext = { ...appContext };
    // render(vnode, el);

    // let app = createApp(component, props);
    // Object.assign(app._context, appContext); // must use Object.assign on _context
    // app.mount(el);

    // We need parent to pass $store to new component
    // new (Vue.extend(component))({
    //   parent: parent,
    //   propsData: props,
    // }).$mount(el);
  }
}
