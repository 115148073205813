import { getHeaders, getBaseUrl } from "~/utils/apiRequests";
import serverTiming from "~/utils/serverTiming";

export const useApiFetch: typeof useFetch = (request, opts = {}) => {
  const headers = getHeaders();
  const baseUrl = getBaseUrl();

  serverTiming.stop('init');
  serverTiming.start('api', 'api');

  return useFetch(request,{
    baseURL: baseUrl,
    // onRequest({ request, options }) {
    //   // Set the request headers
    // },
    // onRequestError({ request, options, error }) {
    //   // Handle the request errors
    // },
    // onResponse({ request, response, options }) {
    //   // Process the response data
    //   return response._data
    // },
    // onResponseError({ request, response, options }) {
    //   // Handle the response errors
    // },
    ...opts,
    headers: {
      ...headers,
      ...opts?.headers,
    },
  });
}
