<template>
  <img
    class="rr-image"
    :src="src"
    :srcset="srcSet"
    :sizes="sizes"
    :alt="altAttribute"
    :loading="lazy ? 'lazy' : 'eager'"
    :width="calculatedWidth"
    :height="calculatedHeight"
    :data-zoom-src="zoom ? src : null"
  />
</template>

<script>
export const profiles = {
  'thumb-tn': {
    size: 'tn',
    srcSet: [
      {size: 'tn'},
      {size: 'xs'},
      {size: 'sm'},
    ],
  },
  'thumb-90': {
    size: 'xs',
    srcSet: [
      {size: 'xs'},
      {size: 'sm'},
    ],
    sizes: '90px',
  },
  'thumb-xs': {
    size: 'xs',
    srcSet: [
      {size: 'xs'},
      {size: 'sm'},
      {size: 'md'},
    ],
  },
  'thumb-sm': {
    size: 'sm',
    srcSet: [
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
  },
  'thumb-md': {
    size: 'md',
    srcSet: [
      {size: 'md'},
      {size: 'lg'},
      {size: 'xl'},
    ],
  },
  'large': {
    size: 'lg',
    srcSet: [
      {size: 'lg'},
      {size: 'xl'},
      {size: '2xl'},
      {size: 'full'},
    ],
    isFullWidth: true,
  },
  'catalog-list': {
    size: 'md',
    srcSet: [
      {size: 'md'},
      {size: 'lg'},
      {size: 'xl'},
    ],
    sizes: '(max-width: 410px) calc(100vw - 30px), (min-width: 411px) and (max-width: 991px) 380px, (min-width: 992px) and (max-width: 1181px) calc(31.25vw - 30px), 380px',
  },
  'catalog-grid': {
    size: 'md',
    srcSet: [
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
    sizes: '(max-width: 767px) calc(50vw - 20px), (min-width: 768px) and (max-width: 991px) calc(37.5vw - 10px), (min-width: 992px) and (max-width: 1181px) calc(25vw - 20px), 295px',
  },
  'catalog-thumb': {
    size: 'tn',
    srcSet: [
      {size: 'tn'},
      {size: 'xs'},
      {size: 'sm'},
    ],
    sizes: '70px',
  },
  'hero': {
    size: 'lg',
    srcSet: [
      {size: 'lg'},
      {size: 'xl'},
      {size: '2xl'},
      {size: 'full'},
    ],
    sizes: '(max-width: 767px) calc(100vw - 30px), (min-width: 768px) and (max-width: 991px) 720px, (min-width: 992px) and (max-width: 1181px) 960px, 1140px',
  },
  'guide-best': {
    size: 'sm',
    srcSet: [
      {size: 'xs'},
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
    sizes: '(max-width: 767px) 138px, (min-width: 768px) and (max-width: 1181px) 118px, 142px',
  },
  'guide-thumb': {
    size: 'sm',
    srcSet: [
      {size: 'xs'},
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
    sizes: '(max-width: 767px) 138px, (min-width: 768px) and (max-width: 1181px) 118px, 250px',
  },
  'guide-product': {
    size: 'lg',
    srcSet: [
      {size: 'lg'},
      {size: 'xl'},
      {size: '2xl'},
      {size: 'full'},
    ],
    sizes: '(max-width: 523px) calc(100vw - 30px), (min-width: 524px) and (max-width: 767px) calc(50vw - 42.5px), (min-width: 768px) and (max-width: 991px) 321px, (min-width: 992px) and (max-width: 1181px) 441px, 428px',
  },
  'guide-full': {
    size: 'lg',
    srcSet: [
      {size: 'lg'},
      {size: 'xl'},
      {size: '2xl'},
      {size: 'full'},
    ],
    sizes: '(max-width: 720px) 100vw, 720px',
  },
  'guide-other': {
    size: 'sm',
    srcSet: [
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
    sizes: '(max-width: 767px) 250px, 212px',
  },
  'author-item': {
    size: 'sm',
    srcSet: [
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
    sizes: '(max-width: 480px) calc(100vw - 30px), 200px',
  },
  'size-chart-deals': {
    size: 'sm',
    srcSet: [
      {size: 'sm'},
      {size: 'md'},
      {size: 'lg'},
    ],
    sizes: '(max-width: 767px) 110px, (min-width: 768px) and (max-width: 991px) 132px, (min-width: 992px) and (max-width: 1181px) 114px, 132px',
  },
  'product': {
    size: 'lg',
    srcSet: [
      {size: 'lg'},
      {size: 'xl'},
      {size: '2xl'},
      {size: 'full'},
    ],
    isFullWidth: true,
  },
};

import imageHelper from '~/utils/image';

export default {
  props: {
    image: {
      required: true,
    },
    profile: {
      type: String,
      default: 'thumb-sm',
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config: profiles[this.profile] ? profiles[this.profile] : profiles['thumb-sm'],
    };
  },
  computed: {
    altAttribute() {
      if (this.alt) {
        return this.alt;
      }

      if (this.title) {
        return this.title;
      }

      return this.image.title || '';
    },
    src() {
      const imageSize = this.config.size === 'lg' ? 'full' : this.config.size;
      return imageHelper.getImageUrl(this.image, imageSize);
    },
    srcSet() {
      if (!this.config.srcSet) {
        return '';
      }

      let srcSet = '';

      this.config.srcSet.forEach(conf => {
        let srcWidth = conf.width ? conf.width : '';

        if (!srcWidth) {
          srcWidth = imageHelper.getImageWidth(this.image, conf.size)+'w';
        }

        if (srcSet) {
          srcSet += ', ';
        }

        srcSet += imageHelper.getImageUrl(this.image, conf.size)+' '+srcWidth;
      });

      return srcSet ? srcSet : null;
    },
    sizes() {
      if (this.config.sizes) {
        return this.config.sizes;
      }

      return this.config.isFullWidth
        ? '(max-width: 767px) calc(100vw - 30px), '+this.calculatedWidth+'px'
        : this.calculatedWidth+'px';
    },
    calculatedWidth() {
      if (this.width) {
        return this.width;
      }

      return imageHelper.getImageWidth(this.image, this.config.size);
    },
    calculatedHeight() {
      if (this.height) {
        return this.height;
      }

      const width = parseInt(this.calculatedWidth);

      if (!width || !this.image || !this.image.height) {
        return undefined;
      }

      return Math.round(width / (this.image.width / this.image.height));
    },
  },
};
</script>
