export default {
  affiliate_link: '',
  expert_reviews_count: 2,
  is_new: false,
  discontinued: true,
  users_score: '',
  score: '',
  shop_id: '',
  score_text: '',
  color_id: '',
  total_votes: '',
  users_votes_count: '',
  price: '',
  product_id: '',
  msrp: '',
  score_color: '',
  name: '',
  brand: { name: 'Zoot', slug: 'zoot' },
  slug: 'zoot-diego',
  expert_score: 89,
  views: 12,
  price_local: 30,
  discount_percentage: 80,
  color: {
    id: '',
    slug: '',
    gender: '',
    name: '',
    images: { md: '' },
  },
  msrp_formatted: '',
  price_formatted: '',
};
