<template>
  <canvas
    class="star-rating"
    :width="width"
    :height="height"
    v-bind="{
      'data-star-width': starWidth,
      'data-stars-count': starsCount,
      'data-cx': cx,
      'data-cy': cy,
      'data-outer-radius': outerRadius,
      'data-x-step': xStep,
      'data-value': rating,
    }"
  >
  </canvas>
</template>

<script>
export default {
  props: {
    rating: {
      type: Number,
      default: 5,
    },
    starsCount: {
      type: Number,
      default: 5,
    },
    starWidth: {
      type: Number,
      default: 18,
    },
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 16,
    },
    cy: {
      type: Number,
      default: 9,
    },
    cx: {
      type: Number,
      default: 10,
    },
    outerRadius: {
      type: Number,
      default: 7,
    },
    xStep: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {
      step: null,
    };
  },
  methods: {
    apply() {
      const value = this.$el.dataset.value / 100;
      this.step = parseFloat(this.$el.dataset.xStep);
      this.starRating(this.$el.getContext('2d'), parseFloat(value.toString()));
    },
    starRating(ctx, value) {
      //draw grey background stars
      for (var i = 1; i <= this.starsCount; i++) {
        this.ds(ctx, {
          cx: i == 1 ? this.step : this.step + (i - 1) * this.starWidth,
          strokeStyle: '#CCC',
        });
      }

      //draw yellow stars
      for (var i = 1; i <= this.starsCount; i++) {
        //full stars
        if (value / (0.2 * i) >= 1) {
          this.ds(ctx, {
            cx: i == 1 ? this.step : this.step + (i - 1) * this.starWidth,
            fillColor: '#FF7F27',
            stroke: true,
            strokeStyle: '#FF7F27',
          });
        }
        //partial star
        else if (value > 0.2 * (i - 1) && value < 0.2 * i) {
          var factor = (value - 0.2 * (i - 1)) / 0.2;
          this.ds(ctx, {
            cx: i == 1 ? this.step : this.step + (i - 1) * this.starWidth,
            fillColor: '#FF7F27',
            factor: factor,
            stroke: false,
            strokeStyle: '#FF7F27',
          });
        }
      }
    },

    ds(c, p) {
      var _p = {
        cx: this.cx,
        cy: this.cy,
        spikes: 5,
        innerRadius: 3,
        outerRadius: this.outerRadius,
        fillColor: '#CCC',
        factor: 1,
        stroke: true,
        strokeStyle: '#FF7F27',
        fill: true,
      };
      for (var i in p) {
        _p[i] = p[i];
      }

      var r = (Math.PI / 2) * 3;
      var x = _p.cx;
      var y = _p.cy;
      var s = Math.PI / _p.spikes;
      var w = _p.cx - _p.outerRadius + 2 * _p.outerRadius * _p.factor;
      c.beginPath();
      c.moveTo(_p.cx, _p.cy - _p.outerRadius);

      for (let i = 0; i < _p.spikes; i++) {
        x = _p.cx + Math.cos(r) * _p.outerRadius;
        x = x > w ? w : x;
        y = _p.cy + Math.sin(r) * _p.outerRadius;
        c.lineTo(x, y);
        r += s;

        x = _p.cx + Math.cos(r) * _p.innerRadius;
        x = x > w ? w : x;
        y = _p.cy + Math.sin(r) * _p.innerRadius;
        c.lineTo(x, y);
        r += s;
      }
      x = _p.cx > w ? w : _p.cx;
      c.lineTo(x, _p.cy - _p.outerRadius);
      c.closePath();

      c.lineWidth = 1;
      c.strokeStyle = _p.strokeStyle;
      if (_p.stroke) c.stroke();
      c.fillStyle = _p.fillColor;
      if (_p.fill) c.fill();
    },
  },
  watch: {
    rating() {
      this.apply();
    },
  },
  mounted() {
    this.apply();
  },
};
</script>

<style scoped>
.star-rating {
  display: inline-block;
}
</style>
