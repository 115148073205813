export const usePostsStore = defineStore('posts', {
  state: () => ({
    currentPage: 1,
    totalPages: 1,
    perPage: 10,
    totalCount: 0,
    subTopic: '',
  }),

  actions: {
    fillPosts(data) {
      if (data.posts && data.posts.current_page) {
        this.currentPage = data.posts.current_page;
      }

      if (data.posts && data.posts.last_page) {
        this.totalPages = data.posts.last_page;
      }

      if (data.posts && data.posts.per_page) {
        this.perPage = data.posts.per_page;
      }

      if (data.posts && data.posts.total) {
        this.totalCount = data.posts.total;
      }

      if (data.current_topic && data.current_topic.slug) {
        this.subTopic = data.current_topic.slug;
      }
    },
  },
})
