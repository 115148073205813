<template>
<div></div>
</template>

<script setup>
import { api as viewerApi } from 'v-viewer/dist/index.mjs'

const viewer = ref(null);

const viewerOptions = {
  navbar: false,
  title: true,
  transition: false,
  // fullscreen: false,
  initialCoverage: 1,
  zoomRatio: 0.1,
  toolbar: {
    flipHorizontal: {
      show: false,
    },
    flipVertical: {
      show: false,
    },
    prev: {
      show: false,
    },
    oneToOne: {
      show: true,
    },
    play: {
      show: false,
    },
    next: {
      show: false,
    },
    reset: {
      show: true,
    },
    rotateLeft: {
      show: true,
    },
    rotateRight: {
      show: true,
    },
    zoomIn: {
      show: true,
    },
    zoomOut: {
      show: true,
    },
  },
  viewed: () => {
    const canvas = document.querySelector('.viewer-container .viewer-canvas');

    if (!canvas) {
      return;
    }

    canvas.addEventListener('pointerdown', (event) => {
      if (event.target.tagName === 'DIV'
        && viewer.value.pointers
        && Object.keys(viewer.value.pointers).length === 1
      ) {
        viewer.value.destroy();
        setTimeout(() => {
          viewer.value = null;
        }, 100);
      }
    })
  },
};

onMounted(() => {
  if (!window) {
    return;
  }

  let elements = document.querySelectorAll('[data-zoom-src]');

  elements.forEach((item) => {
    item.style.cursor = 'zoom-in';

    item.addEventListener('click', (event) => {
      if (viewer.value) {
        viewer.value.destroy();
        viewer.value = null;
        return;
      }

      viewer.value = viewerApi({
        options: viewerOptions,
        images: [{
          src: event.target.dataset.zoomSrc,
          alt: event.target.alt,
        }],
      })
    })
  });
})
</script>

<style lang="scss" scoped>
</style>