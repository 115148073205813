import emptyDeal from "~/utils/emptyDeal";
import processOffers from '~/utils/offerProcessor';
import { usePageStore } from "~/stores/page";
import { useRankingFilterStore } from "~/stores/rankingFilter";
import { useAuthorStore } from "~/stores/author";
import { useRankingSortStore } from "~/stores/rankingSort";
import { useShopsStore } from "~/stores/shops";
import { useWidthsStore } from "~/stores/widths";
import { useMainStore } from "~/stores/main";
import { useRankingFilterSizeStore } from "~/stores/rankingFilterSize";
import persistedState from "~/utils/persistedState";

let getDocCancelSource = null;
let getStatsCancelSource = null;
let getDealsCancelSource = null;

const MODES = ['fullscreen', 'normal'];

export const useRankingStore = defineStore('ranking', {
  state: () => ({
    items: [],
    otherItems: [],
    itemsCount: 0,
    deals: new Array(9).fill(emptyDeal),
    dealsCount: 0,
    loadingItems: false,
    loadingDeals: false,
    isServer: true,
    page: 1,
    size: 30,
    listTypes: [/*'list', 'grid', 'slim'*/],
    listType: 'list',
    showDeals: true,
    content: '',
    image: '',
    topList: [],
    title: '',
    subtitles: [],
    usefulLinks: [],
    showMobileFilter: false,
    showMobileFilterStatePushed: false,
    category: {
      id: null,
      slug: '',
      name: '',
    },
    updateUrl: '',
    goToItem: null,
    goToItemHighlighted: false,
    indexItems: [],
    warningMessage: '',
    forcedStateFromBackend: false,
    fullPath: '',
    guide: {
      slug: '',
      title: '',
    },
    pageChangeFromButton: false,
    scoreDistribution: [],
    mode: MODES[1],
    relatedGuides: [],
    pageGender: false,
    otherShoesFilter: [],
    showOverlay: false,
    type: 'catalog',
  }),

  getters: {
    loading(state) {
      return state.loadingItems || state.loadingDeals;
    },
    showCounters(state) {
      return state.listType === 'products';
    },
    pageCount(state) {
      return Math.ceil(this.count / state.size || 1);
    },
    count(state) {
      return state.itemsCount;
      // return state.listType === 'products' ? state.itemsCount : state.dealsCount;
    },
  },

  actions: {
    setOtherItems({ items }) {
      this.otherItems = items || [];
    },
    appendItems(items) {
      this.items.push(...items);
    },
    setDeals(deals) {
      this.deals = Object.freeze(deals);
    },
    setContent(content) {
      // content just for page one.
      this.content = this.page > 1 ? '' : content;
    },
    setPage(page) {
      this.page = parseInt(page, 10);
    },
    setMode(mode) {
      if (MODES.includes(mode)) {
        this.mode = mode;
      }
    },
    setListType(listType) {
      this.listType = listType;
      persistedState.setSingle('list_type', listType);
    },
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
    hideOverlay() {
      this.showOverlay = false;
    },
    fill({
      global,
      filter,
      products,
      page,
      sortingOptions,
      sorting,
      shops,
      category,
      items,
      score_distribution,
      related_guides,
      other_shoes_filter,
    }) {
      if (filter) {
        useRankingFilterStore().fill(filter);

        if (this.listType === 'deals') {
          this.setDeals(products);
          this.dealsCount = filter.total_count;
        } else {
          this.setItems({
            items: products,
            totalCount: filter.total_count,
            // append: process.client,
            append: false,
          });
        }
        this.warningMessage = filter.warning_message;
      }

      if (other_shoes_filter) {
        this.otherShoesFilter = other_shoes_filter;
      }

      // if (page.hasOwnProperty('listTypes')) {
      //   this.listTypes = page.listTypes;
      // }

      this.title = page.hasOwnProperty('title') ? page.title : '';
      this.subtitles = page.hasOwnProperty('subtitles') ? page.subtitles : [];

      if (page.hasOwnProperty('article')) {
        this.usefulLinks = page.hasOwnProperty('usefulLinks') ? page.usefulLinks : [];
        this.image = page.article.image_url;
        useAuthorStore().fill(page.article.author);
        usePageStore().searchVolume = page.article.search_volume || 0;
        // });
      }

      if (page.buying_guide) {
        this.guide = page.buying_guide;
      }

      this.category = category;
      this.topList = page.hasOwnProperty('topList') ? page.topList : [];
      useShopsStore().setShops(shops);
      useRankingSortStore().fill({ sortingOptions, sorting });
      useWidthsStore().fill({ widths: global.widths });

      if (items) {
        this.indexItems = items;
      }

      if (score_distribution) {
        this.scoreDistribution = score_distribution;
      }

      if (page.slug) {
        this.updateUrl = page.slug;
      }

      if (related_guides) {
        this.relatedGuides = related_guides;
      }

      if (page.page_gender) {
        this.pageGender = page.page_gender;
      }
    },
    setItems({ totalCount, items, append = false }) {
      items.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.deals = processOffers(item.deals, usePageStore().seoGroup);
      });

      this.items = append ? items.slice(0, 3) : items;
      if (append) {
        setTimeout(() => {
          this.appendItems(items.slice(3, items.length));
        });
      }
      if (totalCount) {
        this.itemsCount = totalCount;
      }
    },
    resetGoToItem() {
      this.goToItem = null;
      this.goToItemHighlighted = false;
    },
    toggleShowMobileFilter(buttonName = '') {
      if (!this.showMobileFilter && buttonName) {
        useNuxtApp().$gaEvents.rankingMobileFilterOpened(buttonName);
      }
      this.showMobileFilter = !this.showMobileFilter;
    },
    async update(payload) {
      this.isServer = false;
      await Promise.all([
        this.getItems(payload),
        this.getStatsAndContent(payload),
      ])
    },
    async getOtherItems(payload) {
      // this.loadingItems = true;
      const showDeals = usePageStore().global.show_deals;

      // Apply price tags.
      // when deals countries.
      const filter = [
        ...(showDeals
          ? useRankingFilterStore().priceFilterGroup?.options?.map(o => o.id) ?? []
          : []),
        ...this.otherShoesFilter,
      ];

      let params = {
        from: 0,
        size: this.size,
        'filter[]': filter,
        f_id: payload.filterId,
        c_id: this.category.id,
        orderBy: payload.orderBy,
        show_deals: 1,
        // q: payload.q,
        // page_gender: this.pageGender,
      };

      // if (payload.event?.type && payload.event?.value) {
      //   params.event = payload.event;
      // }

      // 'slim' view requires different set of data.
      if (this.listType === 'slim') {
        params = {
          ...params,
          include: 'facts',
          exclude: 'colors',
        };
      }

      // if (this.goToItem) {
      //   const itemToForceIndex = this.items.findIndex(item => item.slug === this.goToItem);
      //   if (itemToForceIndex > -1) {
      //     params.force_product_id = this.items[itemToForceIndex].product_id;
      //     params.force_product_index = itemToForceIndex;
      //   }
      // }

      // try {
        if (getDocCancelSource) {
          getDocCancelSource.cancel();
        }

        // getDocCancelSource = CancelToken.source();

        const data = await $api('/api/category/documents', {
          params,
          // cancelToken: getDocCancelSource.token,
        });
        this.setOtherItems({ items: data.products });
        // this.loadingItems = false;
      // } catch (error) {
      //   if ($api.isCancel(error)) {
      //     console.log('request aborted...');
      //   }
      // }
    },
    async getItems(payload) {
      this.loadingItems = true;

      let params = {
        from: (this.page - 1) * this.size,
        size: this.size,
        'filter[]': payload.options.map(o => o.id),
        f_id: payload.filterId,
        c_id: this.category.id,
        orderBy: payload.orderBy,
        q: payload.q,
        page_gender: this.pageGender,
      };

      if (payload.event?.type && payload.event?.value) {
        params.event = payload.event;
      }

      // 'slim' view requires different set of data.
      if (this.listType === 'slim') {
        params = {
          ...params,
          include: 'facts',
          exclude: 'colors',
        };
      }

      if (this.goToItem) {
        const itemToForceIndex = this.items.findIndex(item => item.slug === this.goToItem);
        if (itemToForceIndex > -1) {
          params.force_product_id = this.items[itemToForceIndex].product_id;
          params.force_product_index = itemToForceIndex;
        }
      }

      // try {
        if (getDocCancelSource) {
          getDocCancelSource.cancel();
        }

        // getDocCancelSource = CancelToken.source();

        const data = await $api('/api/category/documents', {
          params,
          // cancelToken: getDocCancelSource.token,
        });
        this.setItems({ items: data.products });
        this.loadingItems = false;
      // } catch (error) {
      //   if ($api.isCancel(error)) {
      //     console.log('request aborted...');
      //   }
      // }
    },
    async getStatsAndContent({ options, filterId, orderBy, q, event }) {
      // try {
        if (getStatsCancelSource) {
          getStatsCancelSource.cancel();
        }
        // getStatsCancelSource = CancelToken.source();

        const data = await $api('/api/category/stats', {
          params: {
            from: (this.page - 1) * this.size,
            size: this.size,
            'filter[]': options.map(o => o.id),
            f_id: filterId,
            c_id: this.category.id,
            orderBy,
            q,
            page_gender: this.pageGender,
            event: event?.type && event?.value ? event : null,
            page_type: this.type,
          },
          // cancelToken: getStatsCancelSource.token,
        });

        const page = usePageStore();
        const main = useMainStore();

        const pageData = data.page_data;

        if (!pageData) {
          return;
        }

        // commit('use')

        this.itemsCount = pageData?.filter.total_count ?? 0;
        this.title = pageData.page.title;
        this.subtitles = pageData.page.subtitles;
        page.meta.title = data.page_meta.title;
        main.setBreadcrumbs(pageData.breadcrumbs);
        page.searchVolume = pageData.page.article.search_volume || 0;
        this.setContent(pageData.page.article.content);
        this.usefulLinks = pageData.page.usefulLinks;
        if (pageData.page.related_guides) {
          this.relatedGuides = pageData.page.related_guides;
        }

        if (pageData.page.page_gender) {
          this.pageGender = pageData.page.page_gender;
        }

        if (pageData.page.hasOwnProperty('slug')) {
          this.updateUrl = pageData.page.slug;
        }

        if (pageData.page.buying_guide) {
          this.guide = pageData.page.buying_guide;
        }

        // Warning message is used for informing that there is a low stock.
        if (!this.forcedStateFromBackend) {
          this.warningMessage = pageData.filter.warning_message;
        }

        if (pageData.filter.other_shoes_filter) {
          this.otherShoesFilter = pageData.filter.other_shoes_filter;
        }

        this.forcedStateFromBackend = !!pageData.filter.warning_message;

        useRankingFilterStore().fill({
          groups: pageData.filter?.groups ?? null,
          facets: pageData.filter.facets,
          // Warning message of low stock? add new options, to reload (request).
          selectedOptions: pageData.filter.warning_message
            ? pageData.filter.selectedOptions
            : null,
        });
      // } catch (error) {
      //   if ($api.isCancel(error)) {
      //     console.log('request aborted...');
      //   }
      // }
    },
    async getDeals({ options, filterId, q, orderBy }) {
      // try {
        this.loadingDeals = true;
        if (getDealsCancelSource) {
          getDealsCancelSource.cancel();
        }
        // getDealsCancelSource = CancelToken.source();
        const data = await $api('get-deals', {
          params: {
            from: (this.page - 1) * this.size,
            size: this.size,
            'filter[]': options.map(o => o.id),
            f_id: filterId,
            c_id: this.category.id,
            q,
            orderBy,
            page_gender,
          },
          // cancelToken: getDealsCancelSource.token,
        });
        this.setDeals(data.offers);
        this.dealsCount = total_count;
        this.loadingDeals = false;
      // } catch (error) {
      //   if ($api.isCancel(error)) {
      //     console.log('request aborted...');
      //   }
      // }
    },
    /**
     * Check if gender/size filter gruops
     * are in sync with the global size/gender values
     */
    syncGenderSizeAndWidth() {
      const main = useMainStore();
      const rankingFilterSize = useRankingFilterSizeStore();

      const sizeOption = rankingFilterSize.selectedSizeOption;
      const widthOption = rankingFilterSize.selectedWidthOption;
      const genderOption = rankingFilterSize.selectedGenderOption;

      if (sizeOption && main.size !== sizeOption.slug) {
        main.setSize(sizeOption.slug);
      }
      if (widthOption && main.width !== widthOption.slug) {
        main.setWidth(widthOption.slug);
      }
      if (genderOption && main.gender !== genderOption.slug) {
        main.setGender(genderOption.slug);
      }
    },
    setUpdateUrl({ url, append = false }) {
      this.updateUrl = append ? this.updateUrl + (this.updateUrl.includes('?') ? '&' : '?') + url : url;
    },
    // async updateRelatedGuides(slug) {
    //   const { data } = await $api(`related-guides/${slug}`);
    //   this.relatedGuides = data;
    // },
  },
})
