export const useProductRankingsStore = defineStore('productRankings', {
  state: () => ({
    texts: [],
    scoreTexts: [],
    descriptionInsert: '',
  }),
  actions: {
    fill({ rank_texts, score_texts, description_insert }) {
      this.texts = rank_texts;
      this.descriptionInsert = description_insert;
      this.scoreTexts = score_texts;
    },
  },
})
