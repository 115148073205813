import { usePageStore } from "~/stores/page";
import { useProductsStore } from "~/stores/products";
import { useOffersStore } from "~/stores/offers";

export const useColorsStore = defineStore('colors', {
  state: () => ({
    all: [],
    current: {
      id: 0,
      external_id: 0,
      slug: '',
      images: [],
    },
    mainImagesPreloaded: [],
    contentImages: [],
  }),

  getters: {
    formattedColors(state) {
      const product = useProductsStore().current;
      return state.all.map(color => {
        const name = `${product.name} - ${color.name}`;
        return {
          ...color,
          name,
          disabled:
            !useOffersStore().all.hasOwnProperty(color.id) && usePageStore().global.show_deals,
          label: name,
          value: color.id,
        };
      });
    },
    maxHeight(state) {
      return Math.max(0, ...state.all.map(color => color.image_height));
    },
  },

  actions: {
    fill(colors) {
      // Make sure color is inside colors
      this.all = colors;
    },
    setCurrent(color) {
      if (!color) {
        return;
      }
      const selectedColor = this.all.find(c => c.id === color.id);
      if (selectedColor) {
        this.current = selectedColor;
      }
    },
    resetCurrent() {
      this.current = {
        id: 0,
          slug: '',
          images: [],
      };
    },
    resetAndFill(colors) {
      // current colors had any color?
      const previousColorsHasAnyColors = this.all.length && this.all[0].id === 0;

      const selectedColorWasFirst = this.all.length && this.all[0].id === this.current.id;
      const product = useProductsStore().product;

      const allColors = [];

      colors.forEach(color => {
        const images = [];

        color.images.forEach((image, idx) => {
          const title = idx === 0
            ? `${product.name} - ${color.name}`
            : `${product.name} - ${color.name} - slide ${idx + 1}`;

          images.push({
            ...image,
            active: true,
            title: title,
          });
        });

        allColors.push({
          ...color,
          images: images,
        });
      });

      this.fill(allColors);

      // 1. Reset color when not found in the new colors.
      // 2. Reset to first if previou colors didn't have any color && the first color was selected
      if (
        !this.current.id ||
        (colors.length && !colors.find(color => color && color.id === this.current.id)) ||
        (!previousColorsHasAnyColors && selectedColorWasFirst)
      ) {
        this.setCurrent(colors[0]);
      }
    },
    reset() {
      this.fill([]);
      this.resetCurrent();
    },
    fillContentImages(contentImages) {
      if (!contentImages) {
        return;
      }

      const product = useProductsStore().current;
      const images = [];

      contentImages.forEach((image, idx) => {
        images.push({
          ...image,
          active: true,
          title: image.title || `${product.name} review - slide ${idx + 1}`,
        });
      });

      this.contentImages = images;
    },
  },
})
