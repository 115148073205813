const Width = 'Width';
const Height = 'Height';
const Top = 'Top';
const Left = 'Left';
const scroll = 'scroll';
const client = 'client';
const length = 'length';
const names = {};

const getElements = () => Array.from(document.getElementsByClassName(`sync${scroll}`) || []);

const onDesktop = (callbackFn = () => {}) => {
  if (!document) {
    return;
  }
  const elements = getElements();

  // clearing existing listeners
  let i;
  let j;
  let el;
  let found;
  let name;
  for (name in names) {
    if (names.hasOwnProperty(name)) {
      for (i = 0; i < names[name][length]; i++) {
        names[name][i].removeEventListener(scroll, names[name][i].syn, 0);
      }
    }
  }

  // setting-up the new listeners
  for (i = 0; i < elements[length]; ) {
    found = j = 0;
    el = elements[i++];
    if (!(name = el.getAttribute('name'))) {
      // name attribute is not set
      continue;
    }

    el = el[`${scroll}er`] || el; // needed for intence

    // searching for existing entry in array of names;
    // searching for the element in that entry
    for (; j < (names[name] = names[name] || [])[length]; ) {
      found |= names[name][j++] == el;
    }

    if (!found) {
      names[name].push(el);
    }

    el.eX = el.eY = 0;

    (function (el, name) {
      el.addEventListener(
        scroll,
        (el.syn = function () {
          const elements = names[name];

          let scrollX = el[scroll + Left];
          let scrollY = el[scroll + Top];

          const xRate = scrollX / (el[scroll + Width] - el[client + Width]);
          const yRate = scrollY / (el[scroll + Height] - el[client + Height]);

          const updateX = scrollX != el.eX;
          const updateY = scrollY != el.eY;

          let otherEl;
          let i = 0;

          el.eX = scrollX;
          el.eY = scrollY;

          for (; i < elements[length]; ) {
            otherEl = elements[i++];
            if (otherEl != el) {
              if (
                updateX &&
                Math.round(
                  otherEl[scroll + Left] -
                    (scrollX = otherEl.eX =
                      Math.round(xRate * (otherEl[scroll + Width] - otherEl[client + Width]))),
                )
              ) {
                otherEl[scroll + Left] = scrollX;
              }

              if (
                updateY &&
                Math.round(
                  otherEl[scroll + Top] -
                    (scrollY = otherEl.eY =
                      Math.round(yRate * (otherEl[scroll + Height] - otherEl[client + Height]))),
                )
              ) {
                otherEl[scroll + Top] = scrollY;
              }
            }
          }

          callbackFn();
        }),
        { passive: true },
      );
    })(el, name);
  }
};

/**
 *  For now sync is just in horizontal direction.
 */
const mobileEventHandlers = {};

const onMobile = (callbackFn = () => {}) => {
  if (!document) {
    return;
  }

  let startPosition = { x: 0, y: 0 };
  const elements = getElements();
  const attributeSyncedName = 'data-scroll-synced';

  // Make sure all elements have the same initial scrollLeft
  const scrollLeftTarget = elements?.[0].scrollLeft;
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].scrollLeft = scrollLeftTarget ?? 0;
  }

  const onTouchStart = e => {
    if (e.touches.length > 0) {
      startPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }
  };
  const onTouchMove = e => {
    if (Math.abs(e.touches[0].clientY - startPosition.y) < 5 && e.cancelable) {
      // e.preventDefault();
    }
    for (let i = 0; i < elements.length; i += 1) {
      elements[i].scrollLeft += startPosition.x - e.touches[0].clientX;
    }
    startPosition = { x: e.touches[0].clientX, y: e.touches[0].clientY };

    callbackFn();
  };
  elements.forEach(element => {
    let id = null;

    // First reset events.
    if (element.hasAttribute(attributeSyncedName)) {
      id = element.getAttribute(attributeSyncedName);
      element.removeEventListener('touchstart', mobileEventHandlers[id]?.onTouchStart);
      element.removeEventListener('touchmove', mobileEventHandlers[id]?.onTouchMove);
    }

    element.addEventListener('touchstart', onTouchStart, { passive: true });
    element.addEventListener('touchmove', onTouchMove, { passive: true });
    if (!id) {
      id = Math.random().toString(36).substring(7);
    }
    element.setAttribute(attributeSyncedName, id);
    mobileEventHandlers[id] = { onTouchMove, onTouchStart };
  });
};

export default { onDesktop, onMobile };
