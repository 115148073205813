/**
 * https://medium.com/vuejs-tips/tiny-debounce-for-vue-js-cea7a1513728 
 * @param {*} fn 
 * @param {*} delay 
 */
export default function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
