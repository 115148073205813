<template>
  <dropdown
    ref="dropdown"
    v-model="showDropdown"
    :not-close-elements="els"
    :append-to-body="appendToBody"
    :disabled="disabled"
    :style="containerStyles"
    @keydown.esc="showDropdown = false"
  >
    <div
      class="form-control dropdown-toggle clearfix"
      :class="selectClasses"
      :disabled="disabled ? true : undefined"
      tabindex="0"
      data-role="trigger"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @keydown.prevent.stop.down="goNextOption"
      @keydown.prevent.stop.up="goPrevOption"
      @keydown.prevent.stop.enter="selectOption"
    >
      <div
        class="pull-right"
        style="display: inline-block; vertical-align: middle"
      >
        <span>&nbsp;</span>
        <span class="caret"></span>
      </div>
      <div
        :class="selectTextClasses"
        style="overflow-x: hidden; text-overflow: ellipsis; white-space: nowrap"
        v-html="selectedText"
      ></div>
    </div>
    <template #dropdown>
      <li v-if="filterable" style="padding: 4px 8px">
        <input
          ref="filterInput"
          v-model="filterInput"
          :aria-label="$t('generic.filter')"
          class="form-control input-sm"
          type="text"
          :placeholder="filterPlaceholder || 'Search'"
          @keyup.enter="searchClicked"
          @keydown.prevent.stop.down="goNextOption"
          @keydown.prevent.stop.up="goPrevOption"
          @keydown.prevent.stop.enter="selectOption"
        />
      </li>
      <template v-for="(item, i) in groupedOptions">
        <li
          v-if="item.$group"
          :key="i"
          class="dropdown-header"
          v-text="item.$group"
        ></li>
        <template v-for="(_item, j) in item.options" :key="`${i}_${j}`">
          <li
            :class="itemClasses(_item)"
            style="outline: 0"
            @keydown.prevent.stop.down="goNextOption"
            @keydown.prevent.stop.up="goPrevOption"
            @keydown.prevent.stop.enter="selectOption"
            @click.prevent.stop="toggle(_item, $event)"
            @mouseenter="currentActive = -1"
          >
            <span class="checkbox-label">
              <label>
                <CustomCheckbox
                  :id="_item.value.toString()"
                  :key="_item.value"
                  :name="_item.value.toString()"
                  :checked="isItemSelected(_item)"
                />
                <span class="checkbox-label-text" v-html="_item[labelKey]"></span>
              </label>
            </span>
          </li>
        </template>
      </template>
    </template>
  </dropdown>
</template>

<script>
import { MultiSelect, Dropdown } from 'uiv';

import CustomCheckbox from '@/components/core/CustomCheckbox';

export default {
  name: 'RrMultiselect',
  components: {
    Dropdown,
    CustomCheckbox,
  },
  extends: MultiSelect,
  props: {
    placeholderMulti: {
      type: String,
      default: 'uiv.multiSelect.placeholder_multi',
    },
    placeholderAll: {
      type: String,
      default: '',
    }
  },
  emits: ['focus', 'blur'],
  computed: {
    selectedText() {
      if (this.placeholderAll && this.modelValue.length === this.options.length) {
        return this.placeholderAll;
      }
      if (this.modelValue.length) {
        const { labelValue } = this;
        if (this.collapseSelected) {
          return labelValue.length > 1
            ? this.$t(this.placeholderMulti, { count: labelValue.length})
            : labelValue[0];
        }
        return labelValue.join(this.split);
      }
      return this.placeholder || this.t('uiv.multiSelect.placeholder');
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 100%;

  //We need these styles because default one is overwritten in _common-styles
  :deep() {
    .dropdown-menu {
      display: none;
      height: auto;
      overflow-y: auto;
      overflow-x: auto;
      max-height: 300px;
    }

    li {
      padding: 0 8px;

      .checkbox-label {
        font-weight: 300;

        label {
          font-weight: 300;
        }
      }

      .checkbox-label:hover {
        .checkbox-label-text {
          text-decoration: underline;
        }
      }
    }
  }

  &.open {
    :deep() {
      .dropdown-menu {
        display: block;
      }
    }
  }
}
</style>
