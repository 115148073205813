export const useCurrencyStore = defineStore('currency', {
  state: () => ({
    symbol: '',
    code: '',
    rate: 1,
  }),
  actions: {
    fill({ symbol, code, rate }) {
      this.symbol = symbol;
      this.rate = rate;
      this.code = code;
    },
  },
})
