import { useNuxtApp } from "#app";

export default {
  getProps() {
    return {
      modelValue: {
        type: String,
        validator: value => {
          return ['w', 'm'].indexOf(value.toLowerCase()) > -1;
        },
      },
      unavailable: {
        type: Array,
        default: () => [],
      },
      onlyUnisex: {
        type: Boolean,
        default: () => false,
      },
    };
  },
  getSelected(value, onlyUnisex, unavailable) {
    const genderValue = onlyUnisex ? 'u' : (value || 'm');
    const selected = this.genderOptions(onlyUnisex, unavailable)
      .find(gender => gender.value.toLowerCase() === genderValue);

    return selected || { label: '', value: '' };
  },
  genderOptions(onlyUnisex, unavailable) {
    const { $i18n } = useNuxtApp();

    const options = [
      { label: $i18n.t('generic.men'), value: 'm', id: 'm', available: true },
      { label: $i18n.t('generic.women'), value: 'w', id: 'w', available: true },
    ];

    if (onlyUnisex) {
      options.push({ label: $i18n.t('generic.unisex'), value: 'u', id: 'u', available: true })
    }

    // Set available genders.
    if (unavailable && unavailable.length) {
      options.forEach(gender => {
        if (unavailable.indexOf(gender.value) > -1) {
          gender.available = false;
        }
      });
    }

    return options;
  },
};
