import analyticsVariation from '/utils/analyticsVariation';
import { usePageStore } from "~/stores/page";

const ORIGINAL_VARIATION = 0;
const isServer = typeof window === `undefined`;

export const useExperimentStore = defineStore('experiment', {
  state: () => ({
    isBackend: true,
    id: '',
    forcedVariation: null,
    headers: {},
    // is google analytics ready?
    gaReady: false,
  }),
  getters: {
    variation(state) {
      // Always return the forced variation (backend also forces a variation, see init.js)
      if (typeof state.forcedVariation === `number`) {
        return state.forcedVariation;
      }

      // If no variation is forced and its a backend then choose original.
      if (state.isBackend || isServer) {
        return ORIGINAL_VARIATION;
      }

      // Is a frontend experiment?
      // gaReady helps making the getter reactive
      return state.gaReady ? analyticsVariation(state.id) : ORIGINAL_VARIATION;
    },
    variationReady(state) {
      return state.gaReady || state.forcedVariation !== null;
    },
    isActive(state) {
      return state.id
        && Number.isInteger(state.forcedVariation)
        // TODO: implement frontend experiment later
        && (state.isBackend || state.gaReady);
    },
    ga4Variation(state) {
      const nameLen = 16 - state.variation.toString().length;
      const name = state.id
        .replaceAll('_', '')
        .toUpperCase()
        .slice(0, nameLen)
        .padEnd(nameLen, '0');
      return 'RRT-' + name.slice(0, 8) + '-' + name.slice(8, 16) + state.variation.toString();
    }
  },

  actions: {
    setForcedVariation(variation) {
      this.forcedVariation = parseInt(variation, 10);
    },
    setGaReady() {
      this.gaReady = true;
    },
    disableExperiment() {
      this.id = '';
    },
  },
});
