<template>
  <img
    class="rr-image"
    :src="src"
    :srcset="srcSet"
    :sizes="sizes"
    :alt="altAttribute"
    :loading="lazy ? 'lazy' : 'eager'"
    :width="calculatedWidth"
    :height="calculatedHeight"
  />
</template>

<script>
import imageHelper from '~/utils/image';
import {profiles} from './TheImage';

export default {
  props: {
    url: {
      required: true,
      type: String,
    },
    profile: {
      type: String,
      default: 'thumb-sm',
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    ratio: {
      type: Number,
      default: 3 / 2
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      config: profiles[this.profile] ? profiles[this.profile] : profiles['thumb-sm'],
    };
  },
  computed: {
    altAttribute() {
      if (this.alt) {
        return this.alt;
      }

      if (this.title) {
        return this.title;
      }

      return '';
    },
    src() {
      return imageHelper.getWistiaImageUrl(this.url, this.config.size, this.ratio);
    },
    srcSet() {
      if (!this.config.srcSet) {
        return '';
      }

      let srcSet = '';

      this.config.srcSet.forEach(conf => {
        let srcWidth = conf.width ? conf.width : '';

        if (!srcWidth) {
          srcWidth = imageHelper.getImageWidth(null, conf.size) + 'w';
        }

        if (srcSet) {
          srcSet += ', ';
        }

        srcSet += imageHelper.getWistiaImageUrl(this.url, conf.size, this.ratio) + ' ' + srcWidth;
      });

      return srcSet ? srcSet : null;
    },
    sizes() {
      if (this.config.sizes) {
        return this.config.sizes;
      }

      return this.config.isFullWidth
        ? '(max-width: 767px) calc(100vw - 30px), '+this.calculatedWidth+'px'
        : this.calculatedWidth+'px';
    },
    calculatedWidth() {
      if (this.width) {
        return this.width;
      }

      return imageHelper.getImageWidth(null, this.config.size);
    },
    calculatedHeight() {
      if (this.height) {
        return this.height;
      }

      return Math.round(this.calculatedWidth / this.ratio);
    }
  },
};
</script>
