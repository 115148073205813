<template>
  <div class="video-preview">
    <TheWistiaImage
      :url="image.thumbnail_url"
      profile="thumb-90"
      :ratio="16 / 9"
      :title="title"
    />
    <span class="video-triangle"></span>
  </div>
</template>

<script>
import TheWistiaImage from "~/components/core/TheWistiaImage.vue";

export default {
  components: {TheWistiaImage},
  props: {
    image: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.video-preview {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .video-triangle {
    position: absolute;
    bottom: 8%;
    left: 9%;
    border-left: 12px solid #fff;
    border-right: 0px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
}
</style>
