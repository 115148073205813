import { nextTick } from "vue";

const factSelector = '.fact-body';

class SyncRowsHeight {
  constructor(el, vm) {
    this.el = el;
    this.vm = vm;
  }

  run() {
    const facts = Array.from(this.el.querySelectorAll(factSelector));
    let rowFacts = [];
    // if (facts.length) { this.subscriptionStable.unsubscribe() }
    while ((rowFacts = this._nextRowByClassName(facts))) this._syncRow(rowFacts);
  }

  _nextRow(facts) {
    const numberRows = Math.round(facts.length / this.columnsNumber);
    if (this._currentRow >= numberRows) {
      return null;
    }
    let row = [];
    for (let p = 0; p < this.columnsNumber; p++) {
      const index = p * numberRows + this._currentRow;
      if (typeof facts[index] !== 'undefined') {
        row.push(facts[index]);
      }
    }
    this._currentRow++;
    return row;
  }

  _nextRowByClassName(facts) {
    let row = [];
    if (!facts.length) return null;
    row.push(facts[0]);
    facts.splice(0, 1);
    const className = row[0].classList.item(row[0].classList.length - 1);
    const factsTmp = [...facts];
    factsTmp.forEach(fact => {
      if (fact.classList.contains(className)) {
        row.push(fact);
        facts.splice(facts.indexOf(fact), 1);
      }
    });
    return row;
  }

  _syncRow(facts) {
    // Before syncing make sure the height is default
    facts.forEach(fact => fact.setAttribute('style', 'initial'));

    // Get the longest row cell.
    const maxHeight = Math.max(...facts.map(c => c.offsetHeight));

    // apply this height to all row cells
    facts.forEach(c => (c.style.height = maxHeight + 'px'));
  }
}

export default {
  mounted(el, binding, vnode) {
    if (!binding.arg) {
      return;
    }
    const syncRowsHeight = new SyncRowsHeight(el, binding.instance);
    nextTick(() => syncRowsHeight.run());
    window.addEventListener('resize', () => syncRowsHeight.run());
  },
  updated(el, binding, vnode) {
    if (!binding.arg) {
      return;
    }
    const syncRowsHeight = new SyncRowsHeight(el, binding.instance);
    nextTick(() => syncRowsHeight.run());
  },
};
