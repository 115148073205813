<template>
  <div class="accordion">
    <div>
      <div class="heading">
        <h3>
          <button type="button" @click.prevent="toggle">
            <span class="caret"></span> {{ title }}
          </button>
        </h3>
      </div>
      <collapse v-model="visible">
        <slot />
      </collapse>
    </div>
  </div>
</template>
<script>
import { Collapse } from 'uiv';

export default {
  components: { Collapse },
  props: {
    title: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: this.open,
    };
  },
  watch: {
    open(value) {
      this.visible = value;
    },
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
  },
};
</script>
