<template>
  <div :style="{ height }" class="loading-placeholder" />
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '330px',
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-placeholder {
  @extend %loading-placehodler;
}
</style>
