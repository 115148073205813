import { getHeaders, getBaseUrl } from "~/utils/apiRequests";

export function $api<T>(
  request: Parameters<typeof $fetch<T>>[0],
  opts?: Parameters<typeof $fetch<T>>[1],
) {
  const headers = getHeaders();
  const baseUrl = getBaseUrl();

  return $fetch<T>(request, {
    baseURL: baseUrl,
    ...opts,
    headers: {
      ...headers,
      ...opts?.headers,
    },
  })
}
