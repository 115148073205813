import { nextTick } from "vue";

const syncProductNamesHeight = (selector, vm, perTwo = true) => {
  const names = Array.from(vm.$el.querySelectorAll(selector));

  // Sync per two reviews (one row)
  const syncGroupNumber =
    selector === '.grid-item__product-name' ? (window && window.innerWidth <= 767 ? 2 : 3) : names.length;
  names.forEach(name => (name.style.height = `auto`));

  for (let i = 0; i < names.length; i += syncGroupNumber) {
    if (typeof names[i] === 'undefined' || typeof names[i + 1] === 'undefined') break;
    const namesToSync = names.slice(i, i + syncGroupNumber);
    const maxHeight = Math.max(...namesToSync.map(name => name.getBoundingClientRect().height));
    namesToSync.forEach(name => (name.style.height = `${maxHeight}px`));
  }
};

const sync = (vm, selector) => {
  const syncOnNextTickWithTimeOut = (vm, timeout = 50) => {
    nextTick(() => setTimeout(() => syncProductNamesHeight(selector, vm), timeout));
  };
  if (`requestIdleCallback` in window) {
    requestIdleCallback(() => syncOnNextTickWithTimeOut(vm));
  } else {
    syncOnNextTickWithTimeOut(vm);
  }
};

export default {
  mounted(el, binding, vnode) {
    sync(binding.instance, binding.value || '.grid-item__product-name');
  },
  updated(el, binding, vnode) {
    sync(binding.instance, binding.value || '.grid-item__product-name');
  },
};
