import { useOffersStore } from "~/stores/offers";

export const useProductsStore = defineStore('products', {
  state: () => ({
    slug: '', // Hold a reference to the current product.
    current: {},
    topRatedInCategory: [],
    forceOpenSizeSelect: false,
    openReview: '',
  }),

  getters: {
    product(state) {
      return state.current;
    },
    isNewThenCurrent(state) {
      return productId => state.current.id < productId;
    },
    averageUsersScore(state) {
      return Math.round(state.current.users_score);
    },
    expertScore(state) {
      return Math.round(state.current.expert_score);
    },
    notYetReleased(state) {
      const today = new Date();
      return (
        state.current.precise_released_at &&
        this.releaseDate &&
        this.releaseDate.getTime() > today.getTime()
      );
    },
    releaseDate(state) {
      return state.current.released_at ? new Date(Date.parse(state.current.released_at)) : null;
    },
    layoutIs(state) {
      // Important to keep key:value as such.
      const LAYOUTS = { 0: 'default', 1: 'lab', 2: 'meta', 3: 'new-default' };
      return layoutToVerify => {
        const index = Object.values(LAYOUTS).findIndex(layout => layout === layoutToVerify);
        return index > -1 && parseInt(Object.keys(LAYOUTS)[index], 10) === state.current.layout_type;
      };
    },
    productUpdated(state) {
      return !!state.current.updated_at_iso;
    },
    productPublishedOn(state) {
      return state.current.created_at_iso;
    },
    productUpdatedOn(state) {
      return state.current.updated_at_iso || '';
    },
  },

  actions: {
    setTopRatedInCategory(topRatedInCategory) {
      this.topRatedInCategory = topRatedInCategory ? topRatedInCategory : [];
    },
    async load(slug) {
      const data = await $api(`/api/products/${slug}`);
      this.current = data;
      this.slug = data.slug;
      useOffersStore().fill(data.offers);
    },
  },
})
