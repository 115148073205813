<template>
  <span :style="computedStye" class="skeleton-box" />
</template>

<script>
export default {
  props: {
    maxWidth: {
      default: '100%',
      type: String,
    },
    minWidth: {
      default: null,
      type: String,
    },
    height: {
      default: '22px',
      type: String,
    },
    width: {
      default: '100%',
      type: String,
    },
  },
  computed: {
    computedStye() {
      return {
        height: this.height,
        width: this.width,
        'max-width': this.maxWidth || 'none',
        'min-width': this.minWidth || 'auto',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
span.skeleton-box {
  display: block;
  background-color: #eeeeee;
  animation: loading-animation 1.6s infinite;
  border-radius: 3px;
  @keyframes loading-animation {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
}
</style>
