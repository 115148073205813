<template>
  <div id="__layout">
    <slot />
  </div>
</template>

<script setup>
import { usePageStore } from "~/stores/page";
import { useMainStore } from "~/stores/main";
import pageHead from "~/utils/pageHead";

const main = useMainStore();
const page = usePageStore();

onNuxtReady(() => {
  if (!process.client) {
    return;
  }

  pageHead.sendPageview(document.referrer);

  if (!main.isProd || main.botContinent) {
    return;
  }

  const { scripts } = page.meta;

  if (scripts.mouseflow) {
    window._mfq = window._mfq || [];
    (function () {
      const mf = document.createElement('script');
      mf.type = 'text/javascript';
      mf.async = true;
      mf.src = 'https://cdn.mouseflow.com/projects/6df451f7-1c24-46cc-a9a4-82c10c0501d3.js';
      document.getElementsByTagName('head')[0].appendChild(mf);
    })();
  }

  if (scripts.hotjar) {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/611f5d32d6e7610a49b11a4a/1fdh8842e';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

  if (false && scripts.hotjar) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 1422606, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }

  if (scripts.smartsupp) {
    window._smartsupp = window._smartsupp || {};
    _smartsupp.key = 'a8c2db2e421aba8cb920df21b9a32ca9e1827594';
    window.smartsupp ||
    (function (d) {
      const o = (window.smartsupp = function () {
        o._.push(arguments);
      });
      o._ = [];
      const s = d.getElementsByTagName('script')[0];
      const c = d.createElement('script');
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.async = true;
      c.src = 'https://www.smartsuppchat.com/loader.js?';
      s.parentNode.insertBefore(c, s);
    })(document);
  }
})
</script>
