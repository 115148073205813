export const useGuideMatrixStore = defineStore('guideMatrix', {
  state: () => ({
    currencyCode: '$',
    topRunningShoes: {
      data: [],
      maxPopularity: 160,
      minPopularity: 90,
      maxReviewScore: 100,
      minReviewScore: 0,
      currencyCode: '$',
    },
    priceScoreComparison: {
      maxPrice: 25500,
      minPrice: 18220,
      maxPriceFormatted: '25500<sup>DZD</sup>',
      minPriceFormatted: '18220<sup>DZD</sup>',
      maxReview: 100,
      minReview: 89.775,
      data: [],
    },
  }),

  actions: {
    fill({ products_comparison, products_price_score_comparison }) {
      if (products_comparison) {
        this.topRunningShoes.data = products_comparison.data;
        this.topRunningShoes.maxPopularity = products_comparison.maxPopularity;
        this.topRunningShoes.minPopularity = products_comparison.minPopularity;
        this.topRunningShoes.maxReviewScore = products_comparison.maxReview;
        this.topRunningShoes.minReviewScore = products_comparison.minReview;
      }
      if (products_price_score_comparison) {
        this.priceScoreComparison = products_price_score_comparison;
      }
    },
  },
})
