<template>
  <div>
    <div v-if="play" class="youtube-video-container">
      <div class="video-preview-wrapper">
        <iframe :width="width" :height="height" :src="videoUrl" frameborder="0" allowfullscreen />
      </div>
    </div>

    <div @click.prevent="play = !play">
      <slot>
        <a
          :href="
            'https://www.youtube.com/watch?v=' +
              videoId +
              (durationParams ? '&' + durationParams : '')
          "
          class="embed-youtube-iframe"
          @click.prevent
        >
          <span class="video-title" :class="{ dropup: play }"
            >{{ title }} <span class="caret"
          /></span>
        </a>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    videoId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    durationParams: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      play: false,
    };
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}?autoplay=1&rel=0${
        this.durationParams ? `&${this.durationParams}` : ''
      }`;
    },
  },
};
</script>
