export default {
  getNavigationTimings() {
    if (!window || !window.performance || !performance.getEntriesByType) {
      return null;
    }

    return performance.getEntriesByType('navigation');
  },
  getServerTimings() {
    const timings = new Map();
    const navTimings = this.getNavigationTimings();

    if (!navTimings || navTimings.length < 1) {
      return timings;
    }

    navTimings.forEach(entry => {
      if (entry.serverTiming && entry.serverTiming.length > 0) {
        for (let i = 0; i < entry.serverTiming.length; i++) {
          timings.set(entry.serverTiming[i].name, entry.serverTiming[i]);
        }
      }
    });

    return timings;
  },
  getUserTimings() {
    const timings = new Map();
    const navTimings = this.getNavigationTimings();

    if (!navTimings || navTimings.length < 1) {
      return timings;
    }

    const navTiming = navTimings[0];

    const startTime = navTiming.startTime || 0;

    if (navTiming.loadEventEnd) {
      timings.set('page_load_time', navTiming.loadEventEnd - startTime);
    }

    if (navTiming.responseEnd && navTiming.responseStart) {
      timings.set('page_download_time', navTiming.responseEnd - navTiming.responseStart);
    }

    if (navTiming.domainLookupEnd && navTiming.domainLookupStart) {
      timings.set('dns_time', navTiming.domainLookupEnd - navTiming.domainLookupStart);
    }

    if (navTiming.requestStart && navTiming.responseStart) {
      timings.set('server_response_time', navTiming.responseStart - navTiming.requestStart);
    }

    if (navTiming.connectEnd && navTiming.connectStart) {
      timings.set('tcp_connect_time', navTiming.connectEnd - navTiming.connectStart);
    }

    if (navTiming.fetchStart) {
      timings.set('redirect_response_time', navTiming.fetchStart - startTime);
    }

    if (navTiming.domContentLoadedEventStart) {
      timings.set('content_load_time', navTiming.domContentLoadedEventStart - startTime);
    }

    if (navTiming.domComplete && navTiming.domContentLoadedEventStart) {
      timings.set('dom_loading_time', navTiming.domComplete - navTiming.domContentLoadedEventStart);
    }

    if (navTiming.domInteractive) {
      timings.set('dom_interactive_time', navTiming.domInteractive - startTime);
    }

    if (navTiming.loadEventEnd && navTiming.loadEventStart) {
      timings.set('load_event_time', navTiming.loadEventEnd - navTiming.loadEventStart);
    }

    return timings;
  },
};
