export const useAuthorStore = defineStore('author', {
  state: () => ({
    name: '',
    slug: '',
    url: '',
    bio: '',
    email: '',
    img: '',
    imgSm: '',
    imgLg: '',
    facebook_url: '',
    instagram_url: '',
    twitter_url: '',
    youtube_url: '',
    personal_url: '',
  }),
  
  actions: {
    setAuthor(authorData) {
      const author = authorData || {};
      this.name = author.name || '';
      this.slug = author.slug || '';
      this.url = author.url || '';
      this.bio = author.bio || '';
      this.email = author.email || '';
      this.img = author.img || '';
      this.imgSm = author.imgSm || '';
      this.imgLg = author.imgLg || '';
      this.facebook_url = author.facebook_url || '';
      this.instagram_url = author.instagram_url || '';
      this.twitter_url = author.twitter_url || '';
      this.youtube_url = author.youtube_url || '';
      this.personal_url = author.personal_url || '';
    },
    fill(authorData) {
      this.setAuthor(authorData);
    },
  },
});

