import processOffers from '~/utils/offerProcessor';
import { monthDiff } from '~/utils/date';
import { useMainStore } from "~/stores/main";
import { useColorsStore } from "~/stores/colors";
import { usePageStore } from "~/stores/page";
import { useSizesStore } from "~/stores/sizes";
import { useProductsStore } from "~/stores/products";
import { useShopsStore } from "~/stores/shops";
import { useWidthsStore } from "~/stores/widths";
import { usePriceHistoryStore } from "~/stores/priceHistory";
import { useSimpleComparisonStore } from "~/stores/simpleComparison";

export const useOffersStore = defineStore('offers', {
  state: () => ({
    all: {},
    cheapestVersions: [],
    // Represent the current offers' gender
    // in case the user's gender is not available.
    sameBrandCheaperOffers: [],
    similarCheaperLimit: 1,
    loaded: false,
  }),

  getters: {
    /**
     * Filter offers primarily by color.
     */
    filtered(state) {
      const colors = useColorsStore();
      const key = colors.current.id === null ? '0' : colors.current.id;
      return state.all.hasOwnProperty(key)
        ? processOffers(state.all[key], usePageStore().seoGroup)
        : [];
    },
    inStock(state) {
      return this.filtered.filter(offer => offer.in_stock);
    },
    offerVersion(state) {
      return this.filtered;
    },
    top(state) {
      return this.filtered.filter(offer => !offer.is_hidden);
    },
    availables(state) {
      return this.filtered.filter(offer => offer.in_stock && offer.price > 0);
    },
    notAvailable(state) {
      return this.filtered.filter(offer => !offer.in_stock || offer.price === 0);
    },
    shopsIdsWithOffer(state) {
      return this.availables.map(offer => offer.shop_id);
    },
    best(state) {
      return this.top.length > 0 ? this.top[0] : null;
    },
    sortedByPrice(state) {
      return [...this.inStock].sort((a, b) => (a.similarity_score < b.similarity_score ? -1 : 1));
    },
    cheapest(state) {
      const sorted = this.sortedByPrice;
      return sorted && sorted.length ? sorted[0] : { price_formatted: '' };
    },
    expensive(state) {
      const sorted = this.sortedByPrice;
      return sorted && sorted.length ? sorted[sorted.length - 1] : { price_formatted: '' };
    },
    unavailableGenders(state) {
      const sizes = useSizesStore();
      return usePageStore().global.show_deals
        ? ['m', 'w'].filter(
          gender => sizes.availableSizes && !sizes.availableSizes[gender],
        )
        : [];
    },
    cheapestVersion(state) {
      let cheapestVersion = null;

      state.cheapestVersions.forEach(version => {
        if (!version.price) {
          return;
        }

        if (!cheapestVersion || cheapestVersion.price > version.price) {
          cheapestVersion = version;
        }
      });

      return cheapestVersion;
    },
    similarAndCheaperShoes(state) {
      return state.cheapestVersions
        .filter(offer => {
          let lowStockFilter = true;

          const releaseDateFilter =
            monthDiff(
              new Date(offer.product_created_at),
              new Date(useProductsStore().current.created_at_iso),
            ) <= 26;

          const priceDiscountFilter = offer.price / this.cheapest.price <= 0.9;

          if (!useSizesStore().size) {
            lowStockFilter = !offer.product_low_stock;
          }

          return releaseDateFilter && lowStockFilter && priceDiscountFilter;
        })
        .slice(0, state.similarCheaperLimit);
    },
  },

  actions: {
    setOffers(offers) {
      this.all = offers;
    },
    reset() {
      this.all = {};
      this.cheapestVersions = [];
    },
    setCheapestVersions(cheapestVersions) {
      this.cheapestVersions =
        cheapestVersions && cheapestVersions.length > 0 ? cheapestVersions : [];
    },

    // eslint-disable-next-line camelcase
    fill({ by_color, cheapest_version, gender }) {
      const main = useMainStore();

      this.setOffers(by_color);
      this.setCheapestVersions(cheapest_version);

      // When api responds with different gender,
      if (gender !== main.gender && gender !== 'u') {
        main.gender = gender;
        main.forcedGender = true;
      }
    },
    async getOffers({ productId, sizeId }) {
      const data = await $api(`/api/offers/per-color/${productId}`, {
        params: {
          gender: useMainStore().gender,
          size_id: sizeId || 0,
        },
      });

      // Update offers.
      if (data.hasOwnProperty('offers')) {
        this.setOffers(data.offers.by_color);
        this.setCheapestVersions(data.offers.cheapest_version);
      }

      // Update colors.
      if (data.hasOwnProperty('colors')) {
        useColorsStore().resetAndFill(data.colors);
      }
    },
    async initOffers({ productId, sizeId, comparisonProductIds }) {
      const data = await $api(`/api/product/page_data/${productId}`, {
        params: {
          gender: useMainStore().gender,
          size_id: sizeId || 0,
          'comparison_product_ids[]': comparisonProductIds,
          'includes[]': [
            'colors',
            'offers',
            'shops',
            'prices_data',
            'widths_available',
            'comparison_prices',
          ],
        },
      });

      this.fillInitialData(data);
    },
    fillInitialData(pageData) {
      if (pageData.shops) {
        useShopsStore().setShops(pageData.shops);
      }

      if (pageData.colors) {
        useColorsStore().resetAndFill(pageData.colors);
      }

      if (pageData.prices_data?.availability) {
        useSizesStore().fill({
          availableSizes: pageData.prices_data.availability,
        });
      }

      if (pageData.widths_available) {
        useWidthsStore().updateWidthsAvailable(pageData.widths_available);
      }

      if (pageData.offers) {
        this.fill(pageData.offers);
      }

      if (pageData.prices_data?.price_history_available) {
        usePriceHistoryStore().available = pageData.prices_data.price_history_available;
      }

      if (pageData.comparison_prices) {
        useSimpleComparisonStore().updateProductPrices(pageData.comparison_prices);
      }

      this.loaded = true;
    },
    // async fillSimilarAndCheaperShoes({ productId, sizeId }) {
    //   if (this.similarAndCheaperShoes.length < this.similarCheaperLimit) {
    //     const { data } = await $api(`/api/offers/same-brand-deals/${productId}`, {
    //       params: {
    //         gender: useMainStore().gender,
    //         size_id: sizeId || 0,
    //       },
    //     });
    //
    //     this.sameBrandCheaperOffers = data;
    //   }
    // },
  },
})
