export default {
  getPreferredLocale() {
    if (!window || !window.navigator) {
      return '';
    }

    if (navigator.languages && navigator.languages.length) {
      return navigator.languages[0];
    } else {
      return navigator.userLanguage || navigator.language || navigator.browserLanguage || '';
    }
  },
  getSelectedLocale() {
    const cookie = useCookie('selected_locale');
    return cookie && cookie.value ? cookie.value : '';
  },
  setSelectedLocale(locale) {
    const cookie = useCookie('selected_locale', {
      maxAge: 365 * 24 * 3600,
      path: '/',
    });
    cookie.value = locale;
  },
};
