<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 857.1 1000"
  >
    <path
      d="M500 768V661q0-8-5-13t-13-5H375q-8 0-13 5t-5 13v107q0 8 5 13t13 5h107q8 0 13-5t5-13zm143-375q0-49-31-91t-77-65-95-23q-136 0-207 119-9 13 4 24l74 55q4 4 10 4 9 0 14-7 30-38 48-51 19-14 48-14 27 0 48 15t21 33q0 21-11 34t-38 25q-35 15-65 48t-29 70v20q0 8 5 13t13 5h107q8 0 13-5t5-13q0-10 12-27t30-28q18-10 28-16t25-19 25-27 16-34 7-45zm214 107q0 117-57 215T644 871t-215 58-216-58T58 715 0 500t58-215 155-156 216-58 215 58 156 156 57 215z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#ccc',
    },
    width: {
      type: String,
      default: '13.7px',
    },
    height: {
      type: String,
      default: '16px',
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: relative;
  top: 2px;
}
</style>
