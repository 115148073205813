<template>
  <component
    :is="link ? 'a' : 'button'"
    class="buy_now_button"
    :class="{
      disabled: disabled,
      buy_now_button__white: white,
    }"
    rel="nofollow sponsored"
    :target="self ? '_self' : '_blank'"
    :title="title"
    :href="link"
  >
    <slot name="icon-start" />
    <slot>
      <span v-if="label" v-html="label"></span>
    </slot>
    <slot name="icon" v-if="arrow"> &nbsp; <AngleDoubleRightIcon /> </slot>
  </component>
</template>

<script>
export default {
  props: {
    link: {
      type: [String, Boolean],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    white: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    self: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
