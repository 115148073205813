<template>
  <div
    v-on-click-outside="close"
    class="drop-down-select"
    :class="{ open, 'drop-down-select__orange': themeOrange }"
  >
    <button class="btn btn-default" :disabled="isDisabled" @click.prevent="toggle">
      <!-- img -->
      <img
        v-if="!!selectedOption && displayImgSelected"
        :src="selectedOption.img"
        :alt="selectedOption.label"
      />
      <slot />
      <!-- img -->
      <slot name="label" :selected-option="selectedOption">
        <span
          ref="itemName"
          v-ellipsis-text
          class="item-value"
          v-html="
            selectedOption && selectedOption.label && showSelectedOption
              ? selectedOption.label
              : (selectPhrase ? selectPhrase : $t('generic.select_option'))
          "
        >
        </span>
      </slot>
      <span class="caret" />
    </button>
    <ul v-if="open" class="dropdown-menu" :style="{ 'min-width': dropdownMinWidth > 0 ? dropdownMinWidth+'px' : 'inherit' }">
      <li v-if="optionsLoading">
        <div class="loading" />
      </li>
      <template v-else>
        <li v-if="enableSearch" class="drop-down-search-container">
          <input
            v-model="query"
            v-debounce:200="onQueryChange"
            type="text"
            :placeholder="searchPlaceHolder ? searchPlaceHolder : $t('generic.search')"
          />
        </li>
        <li v-if="displayDropdownLabel">
          {{ dropDownLabel }}
        </li>
        <li
          v-for="option in options"
          :key="option[uniqueKey]"
          :id="'dropdown-select-option-' + option[uniqueKey]"
          :class="{
            active: selectedOption && option[valueProp] === selectedOption[valueProp],
            ...additionalOptionClasses(option),
          }"
          @click.prevent="selectOption(option, $event)"
          @mouseover="onMouseOver(option)"
          @mouseout="onMouseOut"
        >
          <slot name="option" :option="option">
            <!-- img -->
            <img
              v-if="displayImg && option.hasOwnProperty('img') && option.img"
              :src="option.img"
              :alt="option.label"
            />
            <!-- img -->
            <span class="drop-down-search-item-label">
              {{ option.label }}
            </span>
            <popover
              v-if="!$device.isMobileOrTablet && option.description"
              :target="'#dropdown-select-option-' + option[uniqueKey]"
              :trigger="'hover'"
              placement="top"
              :auto-placement="false"
              custom-class="rr_popover"
            >
              <template #popover>
                <span v-html="option.description" />
              </template>
            </popover>
          </slot>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import HelpCircledIcon from '@/components/icons/awesome-font/HelpCircledIcon';

export default {
  components: {
    HelpCircledIcon,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => {
        return {
          label: '',
          value: '',
        };
      },
    },
    displayImgSelected: {
      type: Boolean,
      default: false,
    },
    searchPlaceHolder: {
      type: String,
      default: '',
    },
    dropDownLabel: {
      type: String,
      default: '',
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    selectPhrase: {
      type: String,
      default: '',
    },
    showSelectedOption: {
      type: Boolean,
      default: true,
    },
    displayImg: {
      type: Boolean,
      default: true,
    },
    optionsLoading: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    stopEvent: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    uniqueKey: {
      type: String,
      default: 'id',
    },
    themeOrange: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    selectActiveOption: {
      type: Boolean,
      default: false,
    },
    dropdownMinWidth: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    'opened',
    'closed',
    'mouseOnOption',
    'mouseOutOption',
    'update:searchQuery',
    'update:modelValue',
  ],
  data() {
    return {
      query: '',
      open: false,
      selectedOption: null,
      loading: false,
    };
  },
  computed: {
    displayDropdownLabel() {
      return !!this.dropDownLabel && !this.query;
    },
    isDisabled() {
      return this.disabled || this.optionsLoading;
    },
  },
  watch: {
    searchQuery(value) {
      if (this.query !== this.value) {
        this.query = value;
      }
    },
    modelValue(sort) {
      this.selectedOption = sort;
    },
    isOpen(value) {
      this.open = value;
    },
  },
  created() {
    this.selectedOption = this.modelValue;
    this.query = this.searchQuery;
    this.open = this.isOpen;
  },
  methods: {
    toggle(event) {
      if (event && this.stopEvent) {
        event.stopPropagation();
      }
      if (this.open) {
        this.close();
      } else {
        this.doOpen();
      }
    },
    doOpen() {
      this.open = true;
      this.$emit('opened');
    },
    close() {
      this.open = false;
      this.$emit('closed');
    },
    selectOption(option, event) {
      if (this.stopEvent) {
        event.stopPropagation();
      }
      if (this.selectActiveOption && option.active === false) {
        return;
      }
      this.selectedOption = option;
      this.toggle();
      this.$emit('update:modelValue', this.selectedOption);
      if (this.query) {
        this.query = '';
      }
    },
    onQueryChange() {
      if (!this.enableSearch) return;
      this.$emit('update:searchQuery', this.query);
    },
    additionalOptionClasses(option) {
      return option?.classes || {};
    },
    onMouseOver(option) {
      this.$emit('mouseOnOption', option);
    },
    onMouseOut() {
      this.$emit('mouseOutOption');
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-down-select {
  position: relative;
  button {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    text-align: left;
    justify-content: space-between;
    border-radius: 2px;
    font-weight: 300;
    overflow: hidden;
    padding: 6px 8px;
    .item-value {
      display: inline-block;
      white-space: normal;
      font-weight: 300;
      max-height: 45px;
      white-space: nowrap;
      width: calc(100% - 8px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:focus,
    &:active {
      outline: none;
    }
    &:hover,
    &:focus {
      background-color: #fff;
      color: #f55310;
      border-color: #f55310;
    }
  }

  &.open button {
    &,
    &:hover,
    &:focus {
      background-color: #fff;
      color: #f55310;
      border-color: #f55310;
      box-shadow: inset 0 2px 6px rgba(204, 204, 204, 1);
    }
  }

  ul {
    background-color: #fff;
    list-style: none;
    margin: 2px 0 0 0;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    z-index: 1000;
    max-height: 350px;
    min-width: 160px;
    overflow-y: scroll;
    right: 0;
    left: auto;
    overflow-y: auto;
    @include compact_scrollbar;
    li.drop-down-search-container {
      input {
        border-radius: 4px;
        border: 1px solid $grey-dark;
        padding: 8px 10px;
        width: 100%;
        margin-top: 10px;
      }
    }
    li:not(drop-down-search-container) {
      margin: 2px 0 0;
      // padding: 3px 20px;
      display: block;
      color: #000;
      cursor: pointer;
      width: 100%;
      &.active,
      &:hover:not(.drop-down-search-container) {
        .drop-down-search-item-label {
          text-decoration: underline;
        }
      }
      &.loading-options {
        @extend %loading-placehodler;
        border: none;
        background-size: contain;
        height: 40px;
      }
    }
    li {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      padding: 6px 20px;
      text-align: left;

      .loading {
        height: 40px;
        background: url('#{$cdn_url}/img/spinner.gif') no-repeat;
        background-position: top center;
        background-size: contain;
      }
    }
  }

  // Orange theme.
  &.drop-down-select__orange {
    button {
      .caret {
        margin-left: 10px;
      }
    }
    ul {
      width: auto;
      padding: 0;
      left: 0;
      right: auto;
      display: table;
      li {
        padding: 6px 20px;
        margin: 0;
        &.active,
        &:hover {
          background-color: $orange;
          color: #fff;
          text-decoration: none;
          span {
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
</style>
