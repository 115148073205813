<template>
  <div class="video-preview">
    <TheImage :image="image" :profile="profile" />
    <span class="video-triangle"></span>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Object,
      required: true,
    },
    profile: {
      type: String,
      default: 'thumb-90',
    },
  },
};
</script>

<style lang="scss" scoped>
.video-preview {
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .video-triangle {
    position: absolute;
    bottom: 8%;
    left: 9%;
    border-left: 12px solid #fff;
    border-right: 0px solid transparent;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
}
</style>
