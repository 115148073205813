<template>
  <div class="image-gallery">
    <component
      :is="clickLink ? 'a' : 'div'"
      :href="clickLink ? clickLink : null"
      class="main-image-container"
      :class="{ 'single-image': isSingleImage }"
    >
      <div
        v-touch:swipe.left="nextImage"
        v-touch:swipe.right="previousImage"
        class="main-image"
        :style="{ 'max-width': currentImage.size === 600 ? '600px' : '100%', }"
      >
        <EmbededVideo v-if="currentImage.type=='video' && currentImage.html" :oembed-data="currentImage"></EmbededVideo>
        <LocalVideo
          v-else-if="currentImage.video_url"
          :src="currentImage.video_url"
          :key="currentImage.video_url"
        />
        <TheImage
          v-else
          :profile="currentImage.size === 600 || currentImage.width === 600 ? 'product' : profile"
          :image="currentImage"
          :title="currentImage.title"
          :lazy="lazyLoadAll"
          @click.native="zoom"
          :key="currentImage.url"
        />
      </div>
    </component>
    <div v-if="images.length > 1" class="image-previews">
      <div
        v-for="(image, imageIdx) in images"
        class="image-preview"
        :class="{ active: imageIdx === activeIndex }"
      >
        <ImageGalleryWistiaPreview
          v-if="image.thumbnail_url"
          :image="image"
          @click.native="goToImage(imageIdx)"
        ></ImageGalleryWistiaPreview>
        <ImageGalleryVideoPreview
          v-else-if="image.video_url"
          :image="image"
          @click.native="goToImage(imageIdx)"
        />
        <TheImage
          v-else
          profile="thumb-90"
          :image="image"
          :title="image.title"
          :lazy="lazyLoadAll"
          @click.native="goToImage(imageIdx)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { api as viewerApi } from 'v-viewer/dist/index.mjs'
import imageHelper from '~/utils/image';

import LocalVideo from '~/components/core/LocalVideo';
import EmbededVideo from '~/components/core/EmbededVideo';
import ImageGalleryVideoPreview from '~/components/core/ImageGalleryVideoPreview';
import ImageGalleryWistiaPreview from '~/components/core/ImageGalleryWistiaPreview';

const emits = defineEmits(['update:imageIdx'])

const props = defineProps({
  images: {
    type: Array,
    default: () => [],
  },
  profile: {
    type: String,
    default: 'product',
  },
  lazyLoadAll: {
    type: Boolean,
    default: true,
  },
  clickLink: {
    type: String,
    default: '',
  },
})

const viewer = ref(null);
const activeIndex = ref(0);
const currentImage = ref(props.images && props.images.length > 0 ? props.images[0] : { url: '' });

const isSingleImage = computed(() => props.images.length <= 1);

const imagesForZoom = computed(() => props.images.filter((image) => !image.video_url));

function previousImage() {
  goToImage(activeIndex.value - 1);
}

function nextImage() {
  goToImage(activeIndex.value + 1);
}

function goToImage(index) {
  if (index < 0 || !props.images || index > props.images.length - 1) {
    return;
  }
  activeIndex.value = index;
  currentImage.value = props.images.length > 0 ? props.images[activeIndex.value] : { url: '' };
  emits('update:imageIdx', index);
}

function videoPoster(image) {
  return imageHelper.getImageUrl(image, 'lg');
}

function zoom() {
  if (viewer.value) {
    viewer.value.destroy();
    viewer.value = null;
    return;
  }

  viewer.value = viewerApi({
    images: imagesForZoom.value.map((image) => {
      return {
        src: imageHelper.getImageUrl(image, 'sm'),
        zoom: imageHelper.getImageUrl(image, 'full'),
        alt: image.title,
      };
    }),
    options: {
      initialCoverage: 1,
      zoomRatio: 0.1,
      initialViewIndex: imagesForZoom.value.indexOf(props.images[activeIndex.value]),
      url: 'zoom',
      filter(image) {
        return !image.video_url;
      },
      transition: false,
      toolbar: {
        flipHorizontal: {
          show: false,
        },
        flipVertical: {
          show: false,
        },
        prev: {
          show: true,
        },
        oneToOne: {
          show: true,
        },
        play: {
          show: true,
        },
        next: {
          show: true,
        },
        reset: {
          show: true,
        },
        rotateLeft: {
          show: true,
        },
        rotateRight: {
          show: true,
        },
        zoomIn: {
          show: true,
        },
        zoomOut: {
          show: true,
        },
      },
      viewed: () => {
        const canvas = document.querySelector('.viewer-container .viewer-canvas');

        if (!canvas) {
          return;
        }

        canvas.addEventListener('pointerdown', (event) => {
          if (event.target.tagName === 'DIV'
            && viewer.value.pointers
            && Object.keys(viewer.value.pointers).length === 1
          ) {
            viewer.value.destroy();
            setTimeout(() => {
              viewer.value = null;
            }, 100);
          }
        })
      },
    },
  });
}

watch(props.images, (value) => goToImage(0));
</script>

<style lang="scss" scoped>
.image-gallery {
  .main-image-container {
    position: relative;

    .main-image {
      width: 100%;
      display: flex;
      aspect-ratio: 3/2;

      img {
        cursor: zoom-in;
        object-fit: contain;
        object-position: 50% 0;
        width: 100%;
      }
    }

    &.single-image {
      .main-image {
        aspect-ratio: unset;
      }
    }
  }

  .image-previews {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-gap: 10px;
    gap: 10px;
    overflow: auto;
    overscroll-behavior-x: contain;

    .image-preview {
      display: flex;
      flex: 1 0 auto;
      aspect-ratio: 3/2;
      align-items: center;
      overflow: hidden;
      max-width: 90px;
      max-height: 90px;
      cursor: pointer;
    }
  }
}
</style>
