import { nextTick } from "vue";
import { TOP_HEADER_OFFSET } from "~/constants/global";

class StickyBlock {
  targetEl;

  offset;

  currentWindowOffsetTop;

  initialElementOffset;

  vm;

  unsubscribers = [];

  constructor(el, offset, vm) {
    this.targetEl = el;
    this.offset = offset;
    this.vm = vm;
  }

  doBind() {
    if (this.unsubscribers.length > 0) {
      return;
    }

    const scrollListener = this.onScroll.bind(this);
    const resizeListener = this.onResize.bind(this);

    this.unsubscribers.push(() => {
      window.removeEventListener('scroll', scrollListener);
    });

    this.unsubscribers.push(() => {
      window.removeEventListener('resize', resizeListener);
    });

    nextTick(() => {
      window.addEventListener('scroll', scrollListener);
      window.addEventListener('resize', resizeListener);
    });
  }

  doUnbind() {
    this.unsubscribers.forEach(fn => fn());
    this.unsubscribers = [];
    this.unsetSticky();
  }

  onScroll(evtEventObject) {
    this.updateCurrentWindowOffset();
    this.updateTargetElement();
  }

  updateCurrentWindowOffset() {
    this.currentWindowOffsetTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  }

  onResize(evtEventObject) {
    this.resetTargetOffset();
    this.updateTargetElement();
  }

  resetTargetOffset() {
    this.initialElementOffset = undefined;
  }

  updateTargetElement() {
    requestAnimationFrame(() => {
      this.initializeElementOffset();

      if (this.shouldBeSticky()) {
        this.setSticky();
      } else {
        this.unsetSticky();
      }
    });
  }

  initializeElementOffset() {
    if (typeof this.initialElementOffset === 'undefined') {
      this.initialElementOffset = this.getAbsoluteOffsetTop();
    }
  }

  getAbsoluteOffsetTop() {
    let offset = 0;
    let currentEl = this.targetEl;

    do {
      if (!Number.isNaN(currentEl.offsetTop)) {
        offset += currentEl.offsetTop;
      }
    } while ((currentEl = currentEl.offsetParent));

    return offset;
  }

  shouldBeSticky() {
    return this.targetFitsIntoWindow()
      && this.targetEl.offsetHeight > 30
      && this.scrollOverlapsInitialPosition()
      && window && window.innerWidth > 991;
  }

  targetFitsIntoWindow() {
    return this.targetEl.offsetHeight <= (this.getWindowHeight() - (2*TOP_HEADER_OFFSET));
  }

  scrollOverlapsInitialPosition() {
    return this.currentWindowOffsetTop + this.offset > this.initialElementOffset;
  }
  setSticky() {
    if (!this.targetEl.classList.contains('sticky')) {
      this.targetEl.classList.add('sticky');
    }

    this.updateTargetElementPositioning();
  }

  updateTargetElementPositioning() {
    this.targetEl.style.marginTop = `${Math.abs(
      this.currentWindowOffsetTop - this.initialElementOffset + this.offset,
    )}px`;
  }

  unsetSticky() {
    this.targetEl.style.marginTop = '';

    if (this.targetEl.classList.contains('sticky')) {
      this.targetEl.classList.remove('sticky');
    }
  }

  getWindowHeight() {
    if (this.supportsInnerHeight()) {
      return this.getInnerHeight();
    }

    return this.getClientHeight();
  }

  getClientHeight() {
    return Math.max(
      window.document.body.clientHeight,
      window.document.documentElement.clientHeight,
    );
  }

  getInnerHeight() {
    return window.innerHeight;
  }

  supportsInnerHeight() {
    return window.innerHeight !== undefined;
  }
}

export default {
  mounted: function(el, binding, vnode) {
    if (typeof binding.value === 'undefined' || binding.value) {
      el['@rr-sticky-block'] = new StickyBlock(el, Number.parseInt(binding.arg), binding.instance);
      el['@rr-sticky-block'].doBind();
    }
  },

  updated: function(el, binding, vnode, oldVnode) {
    if (typeof binding.value === 'undefined' || binding.value) {
      if (!el['@rr-sticky-block']) {
        el['@rr-sticky-block'] = new StickyBlock(el, Number.parseInt(binding.arg), binding.instance);
      }

      el['@rr-sticky-block'].doBind();
    } else if (el['@rr-sticky-block']) {
      el['@rr-sticky-block'].doUnbind();
    }
  },

  unmounted: function(el, binding, vnode) {
    if (el['@rr-sticky-block']) {
      el['@rr-sticky-block'].doUnbind();
      el['@rr-sticky-block'] = undefined;
    }
  },
};
