<template>
  <div class="youtube-video" :class="{ 'youtube-video-overlay': overlay }">
    <div class="youtube-video-container">
      <div class="video-preview-wrapper" :class="{ 'video-playing': play }">
        <div
          v-if="!play || overlay"
          @click.prevent="playVideo($event)"
          @v-touch:tap="playVideo($event)"
        >
          <slot>
            <a :href="`https://www.youtube.com/watch?v=${videoUrl}`" class="embed-youtube-iframe">
              <img
                :alt="videoTitle"
                :src="defaultThumbLink"
                :srcset="defaultThumbLink + ' 640w, https://img.youtube.com/vi/' + videoUrl + '/' + 'maxresdefault.jpg 1280w'"
              />
              <span v-if="videoTitle" class="video-title">{{ videoTitle }}</span>
              <span v-if="videoDuration" class="video-duration">
                <IconDuration />
                {{ videoDuration }}</span
              >
              <span v-if="videoViews" class="views-count">
                <IconCount />
                {{ $filters.numberWithCommas(videoViews) }}</span
              >
              <slot name="play">
                <span class="play-button">
                  <span class="triangle" />
                </span>
              </slot>
            </a>
          </slot>
        </div>
        <template v-if="play">
          <div class="video-overlay" @click="stopPlaying" />
          <div class="iframe-container">
            <div class="stop-playing" @click="stopPlaying">
              <CrossIcon />
            </div>
            <iframe
              width="450"
              height="253"
              :src="`https://www.youtube.com/embed/${videoUrl}?autoplay=1&rel=0`"
              frameborder="0"
              allow="autoplay"
              allowfullscreen
            />
          </div>
        </template>
        <slot name="otherInformation" />
      </div>
      <div v-if="videoDetailsOn" class="video-details">
        <slot name="details">
          <span v-if="videoTitle" class="video-title">{{ videoTitle }}</span>
          <div class="video-stats">
            <span v-if="videoViews" class="views-count">
              <IconCount />
              {{ $filters.numberWithCommas(videoViews) }}</span
            >

            <span v-if="videoDuration" class="video-duration">
              <IconDuration />
              {{ videoDuration }}</span
            >
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import IconDuration from '@/components/icons/product/IconDuration';
import IconCount from '@/components/icons/product/IconCount';

import CrossIcon from '@/components/icons/awesome-font/CrossIcon';

export default {
  components: {
    IconDuration,
    IconCount,
    CrossIcon,
  },
  props: {
    videoUrl: {
      type: String,
      default: '',
    },
    videoTitle: {
      type: String,
      default: '',
    },
    videoDuration: {
      type: String,
      default: '',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
    videoViews: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: '100%',
    },
    videoDetailsOn: {
      type: Boolean,
      default: true,
    },
    thumbImage: {
      type: String,
      default: null,
    },
  },
  emits: ['played', 'stoped'],
  data() {
    return {
      videoDefaultLgDevices: '',
      popUpOnPlay: false,
      play: false,
    };
  },
  computed: {
    defaultThumbLink() {
      return this.thumbImage || `https://img.youtube.com/vi/${this.videoUrl}/sddefault.jpg`;
      //   return this.deviceService.isLargeSize && this.videoDefaultLgDevices
      //     ? this.videoDefaultLgDevices
      // return 'sddefault';
    },
    defaultThumbLinkHd() {},
  },
  methods: {
    playVideo() {
      this.play = true;
      this.$emit('played');
    },
    stopPlaying() {
      this.play = false;
      this.$emit('stoped');
    },
  },
};
</script>

<style lang="scss" scoped>
// Normal theme.
.youtube-video {
  .video-preview-wrapper {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
    display: inline-block;
    position: relative;
    margin-top: 15px;
    .iframe-container {
      height: 405px;
    }
    &:not(.video-playing) {
      &::before {
        padding-top: 56.2068966%;
        /* 16:9 ratio */
        display: block;
        content: '';
      }
      iframe {
        position: absolute !important;
      }
    }
    iframe {
      position: relative;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .video-overlay {
      display: none;
    }
    // .stop-playing {
    //   display: none;
    // }
    .embed-youtube-iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      display: inline-block;
      overflow: hidden;
      //height: 90px;
      img {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%) scale(1.02);
        -moz-transform: translateY(-50%) scale(1.02);
        -ms-transform: translateY(-50%) scale(1.02);
        -o-transform: translateY(-50%) scale(1.02);
        transform: translateY(-50%) scale(1.02);
        width: 100%;
      }
      .video-title,
      .views-count,
      .video-duration {
        padding: 1px 3px;
      }
      .video-title {
        position: absolute;
        top: 4px;
        left: 4px;
        color: $dark-blue-font-color;
        text-shadow: 1px 1px 1px $dark-blue;
        background-color: rgba(0, 0, 0, 0.7);
        max-width: 98%;
      }
      .views-count {
        position: absolute;
        bottom: 4px;
        left: 4px;
        color: $dark-blue-font-color;
        text-shadow: 1px 1px 1px $dark-blue;
        background-color: rgba(0, 0, 0, 0.7);
        i {
          color: $dark-blue-font-color;
        }
      }
      .video-duration {
        display: inline-block;
        margin: 0 auto;
        position: absolute;
        right: 4px;
        bottom: 4px;
        color: $dark-blue-font-color;
        text-shadow: 1px 1px 1px $dark-blue;
        background-color: rgba(0, 0, 0, 0.7);
      }
      .play-button {
        background-color: $orange-background;
        display: inline-block;
        width: 90px;
        height: 90px;
        border-radius: 45px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        &:hover {
          background-color: $hightlight-color;
        }
        .triangle {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          -moz-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
          -o-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          border-left: 35px solid #fff;
          border-right: 0px solid transparent;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          margin-left: 7px;
        }
      }
    }
  }
}

// small theme overlay
.youtube-video.youtube-video-overlay {
  .video-preview-wrapper {
    width: 120px;
    height: 90px;
    // // @media (max-width: $break_xs_under) {
    // width: 100%;
    // max-width: 100%;
    // height: auto;
    // // }
    &.video-playing {
      position: static;
    }

    // .video-overlay {
    //   display: none;
    // }
    // @media (min-width: $break_xs_upper) {
    .video-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      background: #000000;
      display: block;
      top: 0;
      left: 0;
      z-index: 999;
      opacity: 0.6;
    }
    .iframe-container {
      position: fixed;
      top: 50%;
      bottom: 0;
      right: 0;
      left: 50%;
      width: 680px;
      height: 380px;
      z-index: 9999999;
      transform: translate(-50%, -50%);
      // @media (max-width: 670px) and (min-width: $break_xs_upper) {
      @media (max-width: $break_sm_under) {
        width: calc(100% - 30px);
        height: 260px;
      }
      .stop-playing {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(10%, -110%);
        text-align: center;
      }
    }
    // }
    .embed-youtube-iframe {
      .video-title,
      .views-count,
      .video-duration {
        padding: 1px 3px;
        @media (min-width: $break_xs_upper) {
          display: none;
        }
        svg {
          position: relative;
          top: 1px;
        }
      }
      .play-button {
        background-color: $orange-background;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        // @media (max-width: $break_xs_under) {
        width: 90px;
        height: 90px;
        border-radius: 45px;
        // }
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        &:hover {
          background-color: $hightlight-color;
        }
        .triangle {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          -moz-transform: translateX(-50%) translateY(-50%);
          -ms-transform: translateX(-50%) translateY(-50%);
          -o-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          border-left: 20px solid #fff;
          border-right: 0px solid transparent;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          margin-left: 2px;

          @media (max-width: $break_xs_under) {
            border-left: 35px solid #fff;
            border-right: 0px solid transparent;
            border-top: 25px solid transparent;
            border-bottom: 25px solid transparent;
            margin-left: 7px;
          }
        }
      }
    }
  }
  .video-details {
    display: inline-block;
    vertical-align: top;
    margin-top: 15px;
    margin-left: 12px;
    width: calc(100% - 140px);
    // @media (max-width: $break_xs_under) {
    //   display: none;
    // }
    .video-title {
      display: block;
    }
    .video-stats {
      margin-top: 10px;
      .views-count {
        margin-right: 15px;
      }
      svg {
        position: relative;
        top: 1px;
        margin-right: 5px;
      }
    }
  }
}
</style>
