export default {
  getTestValue(test, value) {
    if (test.slug_raw === 'weight') {
      const weight = parseInt(value);
      return (weight * 0.035274).toFixed(2)
        + ' oz ('+weight.toFixed(0)+'g)';
    }

    if (test.type === 'bool') {
      return value && value !== '0';
    }

    if (test.type === 'option' && value) {
      const option = test.config && test.config.options
        ? test.config.options.find(o => o.value === value)
        : null;

      return option ? option.name : '';
    }

    if (test.type === 'float' || test.type === 'int') {
      return parseFloat(value).toFixed(1) + this.getUnits(test);
    }

    if (test.type === 'percent') {
      return value + this.getUnits(test);
    }

    return value;
  },
  getUnits(test) {
    if (test.slug_raw === 'weight') {
      return '';
    }

    if (test.type === 'percent') {
      return '%';
    }

    return (test.units.length > 1 ? ' ' : '') + test.units;
  },
}