<template>
  <div id="page" class="site">
    <div id="content" class="site-content">
      <slot />
    </div>
  </div>
</template>

<style scoped>
body {
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}
</style>
