export const useGuideBarsStore = defineStore('guideBars', {
  state: () => ({
    bars: {
      brandsReview: {
        max: 0,
        data: [],
      },
    },
  }),

  actions: {
    setBars({ name, data, max }) {
      this.bars.name = { max, data };
    },
    fill({ brands_comparison }) {
      if (!brands_comparison || !brands_comparison.data) {
        return;
      }

      const dataReview = brands_comparison.data
        .map(brand => {
          return {
            value: brand.review,
            label: brand.title,
            url: brand.url,
          };
        })
        .sort((a, b) => b.value - a.value);

      this.setBars({
        name: 'brandsReview',
        data: dataReview,
        max: brands_comparison.maxReview,
      });

      const dataPrices = brands_comparison.data
        .map(brand => {
          return {
            value: brand.price,
            valueDisplay: brand.price_formatted,
            label: brand.title,
            url: brand.url,
          };
        })
        .sort((a, b) => a.value - b.value);

      this.setBars({
        name: 'brandsPrice',
        data: dataPrices,
        max: brands_comparison.maxPrice,
      });
    },
  },
})
