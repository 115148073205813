import { useMainStore } from "~/stores/main";
import { useColorsStore } from "~/stores/colors";
import { useProductsStore } from "~/stores/products";

const systems = ['us', 'eu', 'uk'];
export const useSizesStore = defineStore('sizes', {
  state: () => ({
    grids: {},
    conversion: {},
    availableSizes: {
      m: [],
      w: [],
    },
    brandSizes: [],
    allBrandsSizes: null,
  }),
  getters: {
    formattedSizes(state) {
      const main = useMainStore();
      const colors = useColorsStore();
      const sizes = [];
      this.systems.forEach(system => {
        if (
          state.grids.hasOwnProperty(system) &&
          state.grids[system].hasOwnProperty(main.gender)
        ) {
          state.grids[system][main.gender].forEach(size => {
            const gender = main.gender;
            const active =
              state.availableSizes.hasOwnProperty(gender) &&
              state.availableSizes[gender].hasOwnProperty(main.width) &&
              state.availableSizes[gender][main.width].hasOwnProperty(
                colors.current.id,
              ) &&
              state.availableSizes[gender][main.width][colors.current.id].indexOf(
                size.id,
              ) > -1;
            sizes.push({
              name: size.name,
              value: size.slug,
              active: active,
              system: system,
            });
          });
        }
      });
      return sizes;
    },
    currentBrandSizesForGrid(state) {
      return this.brandSizesForGrid(state.brandSizes);
    },
    /**
     * Important: to be used to get current size FOR a brand
     * (as sizes are different from brand to brand)
     */
    sizeForCurrentBrand(state) {
      const main = useMainStore();
      if (!main.size) return '';
      const brandSize = state.brandSizes.find(
        size =>
          size.slugs.hasOwnProperty(main.gender) &&
          size.slugs[main.gender].includes(main.size),
      );
      return brandSize ? brandSize[`${main.sizeSystem + main.gender}_slug`] : '';
    },
    isSizeAvailable(state) {
      const main = useMainStore();
      const colors = useColorsStore();
      return size => {
        const gender = useProductsStore().current.is_unisex ? 'u' : main.gender;

        return (
          state.availableSizes.hasOwnProperty(gender) &&
          state.availableSizes[gender].hasOwnProperty(main.width) &&
          state.availableSizes[gender][main.width].hasOwnProperty(colors.current.id) &&
          state.availableSizes[gender][main.width][colors.current.id].indexOf(
            size.id,
          ) > -1
        );
      };
    },
    brandSizesForGrid(state) {
      const main = useMainStore();
      const products = useProductsStore();

      const systems = ['us', 'uk', 'eu'];
      return (brandSizes, gender, setActive = null, uniSex = null) => {
        const sizes = [];

        if (!gender) {
          gender = main.gender;
        }

        const unisexSizes = uniSex === null ? products.current.is_unisex : uniSex;
        systems.forEach(system => {
          brandSizes.forEach(size => {
            // Check availabiliy to see if this size is actice.
            const active = setActive !== null ? setActive : this.isSizeAvailable(size);
            let name = '';
            let value = '';

            if (unisexSizes && size[system + 'm'] !== size[system + 'w']) {
              const mSize = size[system + 'm'];
              const wSize = size[system + 'w'];

              if (mSize) {
                name = `M${mSize}`;
              }

              if (wSize) {
                if (name) {
                  name += '/';
                }
                name += `W${wSize}`;
              }

              value =
                size[`${system + gender}_slug`] || size[`${system}m_slug`] || size[`${system}w_slug`];
            } else {
              name = size[system + gender];
              value = size[`${system + gender}_slug`];
            }

            if (name && value) {
              sizes.push({
                name: name,
                value: value,
                active: active,
                system: system,
                size_brand_id: size.id,
              });
            }
          });
        });
        return sizes;
      };
    },
    brandSizesForChart(state) {
      const main = useMainStore();

      const systems = [...this.systems, 'mm', 'foot'];
      const sizes = [];
      state.brandSizes.forEach(size => {
        const sizeValues = [];
        systems.forEach(system => {
          const index = system === 'mm' || system === 'foot' ? system : system + main.gender;
          if (size.hasOwnProperty(index) && size[index]) {
            sizeValues.push(index === 'mm' ? size[index] / 10 : size[index]);
          }
        });
        if (sizeValues.length >= 4) {
          sizes.push({ values: sizeValues, active: this.isSizeAvailable(size) });
        }
      });
      return sizes;
    },
    systems(state) {
      return systems;
    },
    currentSizeName(state) {
      const main = useMainStore();
      return this.currentBrandSize.hasOwnProperty(main.sizeSystem + main.gender)
        ? this.currentBrandSize[main.sizeSystem + main.gender]
        : '';
    },
    currentBrandSize(state) {
      const main = useMainStore();

      return main.size
        ? state.brandSizes.find(
        size =>
          size.hasOwnProperty(`${main.sizeSystem + main.gender}_slug`) &&
          size[`${main.sizeSystem + main.gender}_slug`] &&
          size[`${main.sizeSystem + main.gender}_slug`] === main.size,
      ) || { id: '' }
        : { id: '' };
    },
    currentSizeValue(state) {
      const main = useMainStore();
      return main.size ? parseInt(main.size.split('_')[1], 10) / 10 : null;
    },
    hasFootLength(state) {
      return state.brandSizes.filter(size => size.foot).length > 0;
    },
  },

  actions: {
    fill(data) {
      if (data.system) {
        useMainStore().defaultSizeSystem = data.system;
      }
      if (data.brandSizes) {
        this.brandSizes = data.brandSizes || [];
      }
      if (data.conversion) {
        this.conversion = data.conversion;
      }
      if (data.availableSizes) {
        this.availableSizes = data.availableSizes;
      }
      if (data.grids) {
        this.grids = data.grids;
      }
    },
    selectById(sizeId) {
      const main = useMainStore();

      const newSize = this.brandSizes.find(size => size.id === sizeId);
      const slugAttribute = `${main.sizeSystem + main.gender}_slug`;

      if (newSize && newSize.hasOwnProperty(slugAttribute)) {
        main.setSize(newSize[slugAttribute]);
      }
    },
    /**
     * Convert size to a new system using generic grid size.
     */
    convert(newSystem) {
      const main = useMainStore();

      const indexOf = this.conversion[main.gender][main.sizeSystem].indexOf(
        this.currentSizeValue,
      );
      if (indexOf < 0) {
        return;
      }

      const sizeObj = this.grids?.[newSystem]?.[main.gender]?.[indexOf];

      if (sizeObj) {
        main.setSize({
          value: sizeObj.slug,
          converted: true,
        });
      }
    },
    /**
     * Convert size to a new system using brand sizes data.
     */
    convertSizeFromBrands({ newSystem, brandSizes }) {
      const main = useMainStore();

      // Use current state brand sizes.
      if (!brandSizes) {
        brandSizes = this.brandSizes;
      }

      const sizeObj = brandSizes.find(
        size =>
          size.hasOwnProperty(`${main.sizeSystem + main.gender}_slug`) &&
          size[`${main.sizeSystem + main.gender}_slug`] &&
          size[`${main.sizeSystem + main.gender}_slug`] === main.size,
      );
      if (
        sizeObj &&
        sizeObj.hasOwnProperty(`${newSystem + main.gender}_slug`) &&
        sizeObj[`${newSystem + main.gender}_slug`]
      ) {
        main.setSize({
          value: sizeObj[`${newSystem + main.gender}_slug`],
          converted: true,
        });
      }
    },
    /**
     * Convert size' gender to another one.
     * Example: US 8 men => US 8 Women Or more/less 1/2
     *          if 8 is not available.
     */
    convertGenderSizeFromBrands({ gender, brandSizes }) {
      const main = useMainStore();

      // Use current state brand sizes.
      if (!brandSizes) {
        brandSizes = this.brandSizes;
      }

      // Nothing to convert :)
      // Or, no brand size to use.
      if (!main.size || !brandSizes?.length) {
        return;
      }

      const availableCondition = true;

      const newGenderSize = brandSizes.find(size => {
        return (
          size &&
          size.hasOwnProperty(main.sizeSystem + gender) &&
          size[main.sizeSystem + gender] &&
          size[main.sizeSystem + gender] === this.currentSizeValue &&
          availableCondition
        );
      });

      // No need to convert.
      if (newGenderSize) {
        return;
      }

      // find out new size with more/less 1/2 in value.
      const newSize = brandSizes.find(size => {
        return (
          size &&
          size.hasOwnProperty(main.sizeSystem + gender) &&
          size[main.sizeSystem + gender] &&
          Math.abs(size[main.sizeSystem + gender] - this.currentSizeValue) === 0.5 &&
          availableCondition
        );
      });

      main.setSize({
        // @TODO: move current size inside this store and use initailState.
        value: newSize ? newSize[`${main.sizeSystem + gender}_slug`] : '',
        converted: true,
      });
    },
    async loadAllBrandsSizes() {
      const brandsSizes = await $api('/offers/sizes');
      this.allBrandsSizes = brandsSizes;
    },
  },
})
