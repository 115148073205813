<template>
  <div ref="wistia" class="wistia-wrapper">
    <div class="wistia_swatch" style="height:100%;left:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
<!--      <TheWistiaImage-->
<!--        :ratio="16 / 9"-->
<!--        :url="previewImage"-->
<!--        profile="product"-->
<!--        @click="openWistia"-->
<!--      />-->
    </div>
  </div>
</template>
<script setup>
import TheWistiaImage from '@/components/core/TheWistiaImage.vue';

const props = defineProps({
  'oembed-data': {
    type: Object,
    required: true,
  }
})

const previewImage = computed(() => props.oembedData.thumbnail_url + `&image_play_button=true&image_play_button_color=1a3338`)

const wistia = ref(null)

function openWistia() {
  //configure player
  window._wq = window._wq || [];

  if (window._wq.length == 0) {
    _wq.push({
      id: '_all',
      options: {
        // autoPlay: true,
        controlsVisibleOnLoad: true,
        copyLinkAndThumbnailEnabled: false,
        doNotTrack: false,
        // fitStrategy: 'fill', //options are: contain, fill, cover, none
        fullscreenButton: true,
        muted: false,
        playbackRateControl: true,
        playbar: true,
        playButton: true,
        playerColor: "1a3338",
        playlistLinks: false,
        playlistLoop: false,
        resumable: true,
        seo: true,
        settingsControl: true,
        silentAutoPlay: false,
        smallPlayButton: false,
        thumbnailAltText: true,
        videoFoam: true,
        volumeControl: true,
        wmode: false,
      }
    });
  }

  //create one global instance of Wistia player
  // let mainScript = document.getElementById('wistia-main-script');
  //
  // if (!mainScript) {
  //   let mainScript = document.createElement('script')
  //   mainScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
  //   mainScript.async = true
  //   mainScript.id = 'wistia-main-script'
  //   this.$refs['wistia'].appendChild(mainScript)
  // }

  //create wistia embed element
  let obj = document.createElement('div')
  obj.classList.add('wistia_embed')
  obj.classList.add('wistia_async_' + props.oembedData.video_id)

  //append wistia embed element to current component
  wistia.value.appendChild(obj)
}
onMounted(() => {
  openWistia()
})

</script>
<style lang="scss" scoped>
.wistia-wrapper {
  width: 100%;
}

.wistia_swatch {
  cursor: pointer;
}
</style>
