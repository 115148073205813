import debounce from '~/directives/debounce';
import {
  vOnClickOutside,
  vElementVisibility,
  vIntersectionObserver,
} from "@vueuse/components";
import Vue3TouchEvents from "vue3-touch-events";
import stickyBlock from "~/directives/stickyBlock";
import EllipsisText from "~/directives/ellipsisText";
import { popover } from "uiv";

export default defineNuxtPlugin((nuxtApp) => {
  // TODO: replace hardcoded debounce after plugin gets updated to v5 and check in production build
  nuxtApp.vueApp.directive('debounce', debounce({ lock: true }));

  /**
   * Third-party directives.
   */
  nuxtApp.vueApp.directive('on-click-outside', vOnClickOutside);
  nuxtApp.vueApp.directive('element-visibility', vElementVisibility);
  nuxtApp.vueApp.directive('intersection-observer', vIntersectionObserver);
  nuxtApp.vueApp.use(Vue3TouchEvents);
  nuxtApp.vueApp.directive('popover', popover);

  /**
   * RR Custom directives.
   */
  nuxtApp.vueApp.directive('ellipsis-text', EllipsisText);
  nuxtApp.vueApp.directive('sticky-block', stickyBlock);
})
