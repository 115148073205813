export const useGenericSizesStore = defineStore('genericSizes', {
  state: () => ({
    selectedSize: {},
    sizesMap: ['cm', 'in', 'usm', 'usw', 'uk', 'eu', 'mondo'],
    sizes: [
      [20, 7.87, 1.5, 2.5, 0.5, 33, 205],
      [20.1, 7.91, 1.5, 2.5, 0.5, 33, 205],
      [20.2, 7.95, 1.5, 2.5, 0.5, 33, 205],
      [20.3, 7.99, 2, 3, 1, 33.5, 205],
      [20.4, 8.03, 2, 3, 1, 33.5, 210],
      [20.5, 8.07, 2, 3, 1, 33.5, 210],
      [20.6, 8.11, 2, 3, 1, 33.5, 210],
      [20.7, 8.15, 2.5, 3.5, 1.5, 34, 210],
      [20.8, 8.19, 2.5, 3.5, 1.5, 34, 210],
      [20.9, 8.23, 2.5, 3.5, 1.5, 34, 215],
      [21, 8.27, 2.5, 3.5, 1.5, 34, 215],
      [21.1, 8.31, 3, 4, 2, 34, 215],
      [21.2, 8.35, 3, 4, 2, 34.5, 215],
      [21.3, 8.39, 3, 4, 2, 34.5, 215],
      [21.4, 8.43, 3, 4, 2, 34.5, 220],
      [21.5, 8.46, 3, 4, 2, 34.5, 220],
      [21.6, 8.5, 3.5, 4.5, 2.5, 35, 220],
      [21.7, 8.54, 3.5, 4.5, 2.5, 35, 220],
      [21.8, 8.58, 3.5, 4.5, 2.5, 35, 220],
      [21.9, 8.62, 3.5, 4.5, 2.5, 35.5, 225],
      [22, 8.66, 4, 5, 3, 35.5, 225],
      [22.1, 8.7, 4, 5, 3, 35.5, 225],
      [22.2, 8.74, 4, 5, 3, 36, 225],
      [22.3, 8.78, 4, 5, 3, 36, 225],
      [22.4, 8.82, 4.5, 5.5, 3.5, 36, 230],
      [22.5, 8.86, 4.5, 5.5, 3.5, 36.5, 230],
      [22.6, 8.9, 4.5, 5.5, 3.5, 36.5, 230],
      [22.7, 8.94, 4.5, 5.5, 3.5, 36.5, 230],
      [22.8, 8.98, 5, 6, 4, 36.5, 230],
      [22.9, 9.02, 5, 6, 4, 37, 235],
      [23, 9.06, 5, 6, 4, 37, 235],
      [23.1, 9.09, 5, 6, 4, 37, 235],
      [23.2, 9.13, 5, 6, 4, 37, 235],
      [23.3, 9.17, 5.5, 6.5, 4.5, 37.5, 235],
      [23.4, 9.21, 5.5, 6.5, 4.5, 37.5, 240],
      [23.5, 9.25, 5.5, 6.5, 4.5, 37.5, 240],
      [23.6, 9.29, 5.5, 6.5, 4.5, 38, 240],
      [23.7, 9.33, 6, 7, 5, 38, 240],
      [23.8, 9.37, 6, 7, 5, 38, 240],
      [23.9, 9.41, 6, 7, 5, 38.5, 245],
      [24, 9.45, 6, 7, 5, 38.5, 245],
      [24.1, 9.49, 6.5, 7.5, 5.5, 38.5, 245],
      [24.2, 9.53, 6.5, 7.5, 5.5, 39, 245],
      [24.3, 9.57, 6.5, 7.5, 5.5, 39, 245],
      [24.4, 9.61, 6.5, 7.5, 5.5, 39, 250],
      [24.5, 9.65, 7, 8, 6, 39, 250],
      [24.6, 9.69, 7, 8, 6, 39.5, 250],
      [24.7, 9.72, 7, 8, 6, 39.5, 250],
      [24.8, 9.76, 7, 8, 6, 39.5, 250],
      [24.9, 9.8, 7, 8, 6, 40, 255],
      [25, 9.84, 7.5, 8.5, 6.5, 40, 255],
      [25.1, 9.88, 7.5, 8.5, 6.5, 40, 255],
      [25.2, 9.92, 7.5, 8.5, 6.5, 40.5, 255],
      [25.3, 9.96, 7.5, 8.5, 6.5, 40.5, 255],
      [25.4, 10, 8, 9, 7, 40.5, 260],
      [25.5, 10.04, 8, 9, 7, 41, 260],
      [25.6, 10.08, 8, 9, 7, 41, 260],
      [25.7, 10.12, 8, 9, 7, 41, 260],
      [25.8, 10.16, 8.5, 9.5, 7.5, 41.5, 260],
      [25.9, 10.2, 8.5, 9.5, 7.5, 41.5, 265],
      [26, 10.24, 8.5, 9.5, 7.5, 41.5, 265],
      [26.1, 10.28, 8.5, 9.5, 7.5, 41.5, 265],
      [26.2, 10.31, 9, 10, 8, 42, 265],
      [26.3, 10.35, 9, 10, 8, 42, 265],
      [26.4, 10.39, 9, 10, 8, 42, 270],
      [26.5, 10.43, 9, 10, 8, 42, 270],
      [26.6, 10.47, 9.5, 10.5, 8.5, 42.5, 270],
      [26.7, 10.51, 9.5, 10.5, 8.5, 42.5, 270],
      [26.8, 10.55, 9.5, 10.5, 8.5, 42.5, 270],
      [26.9, 10.59, 9.5, 10.5, 8.5, 43, 275],
      [27, 10.63, 9.5, 10.5, 8.5, 43, 275],
      [27.1, 10.67, 10, 11, 9, 43, 275],
      [27.2, 10.71, 10, 11, 9, 43, 275],
      [27.3, 10.75, 10, 11, 9, 43.5, 280],
      [27.4, 10.79, 10, 11, 9, 43.5, 280],
      [27.5, 10.83, 10.5, 11.5, 9.5, 43.5, 280],
      [27.6, 10.87, 10.5, 11.5, 9.5, 44, 280],
      [27.7, 10.91, 10.5, 11.5, 9.5, 44, 280],
      [27.8, 10.94, 10.5, 11.5, 9.5, 44, 285],
      [27.9, 10.98, 11, 12, 10, 44.5, 285],
      [28, 11.02, 11, 12, 10, 44.5, 285],
      [28.1, 11.06, 11, 12, 10, 44.5, 285],
      [28.2, 11.1, 11, 12, 10, 45, 285],
      [28.3, 11.14, 11.5, 12.5, 10.5, 45, 290],
      [28.4, 11.18, 11.5, 12.5, 10.5, 45, 290],
      [28.5, 11.22, 11.5, 12.5, 10.5, 45, 290],
      [28.6, 11.26, 11.5, 12.5, 10.5, 45.5, 290],
      [28.7, 11.3, 11.5, 12.5, 10.5, 45.5, 295],
      [28.8, 11.34, 12, 13, 11, 45.5, 295],
      [28.9, 11.38, 12, 13, 11, 46, 295],
      [29, 11.42, 12, 13, 11, 46, 295],
      [29.1, 11.46, 12, 13, 11, 46, 295],
      [29.2, 11.5, 12.5, 13.5, 11.5, 46.5, 300],
      [29.3, 11.54, 12.5, 13.5, 11.5, 46.5, 300],
      [29.4, 11.57, 12.5, 13.5, 11.5, 46.5, 300],
      [29.5, 11.61, 12.5, 13.5, 11.5, 46.5, 300],
      [29.6, 11.65, 13, 14, 12, 47, 305],
      [29.7, 11.69, 13, 14, 12, 47, 305],
      [29.8, 11.73, 13, 14, 12, 47, 305],
      [29.9, 11.77, 13, 14, 12, 47.5, 305],
      [30, 11.81, 13.5, 14.5, 12.5, 47.5, 305],
      [30.1, 11.85, 13.5, 14.5, 12.5, 47.5, 310],
      [30.2, 11.89, 13.5, 14.5, 12.5, 48, 310],
      [30.3, 11.93, 13.5, 14.5, 12.5, 48, 310],
      [30.4, 11.97, 13.5, 14.5, 12.5, 48, 310],
      [30.5, 12.01, 14, 15, 13, 48, 310],
      [30.6, 12.05, 14, 15, 13, 48.5, 315],
      [30.7, 12.09, 14, 15, 13, 48.5, 315],
      [30.8, 12.13, 14, 15, 13, 48.5, 315],
      [30.9, 12.17, 14.5, 15.5, 13.5, 49, 315],
      [31, 12.2, 14.5, 15.5, 13.5, 49, 315],
      [31.1, 12.24, 14.5, 15.5, 13.5, 49, 320],
      [31.2, 12.28, 14.5, 15.5, 13.5, 49, 320],
      [31.3, 12.32, 15, 16, 14, 49.5, 320],
      [31.4, 12.36, 15, 16, 14, 49.5, 320],
      [31.5, 12.4, 15, 16, 14, 49.5, 320],
    ],
    genderFrom: 'm',
    genderTo: 'm',
    systemFrom: null,
    systemTo: null,
    sizeFrom: null,
    brandSizes: [],
    categoryLinks: [],
  }),

  getters: {
    sizesStructure(state) {
      return state.sizes.map(sizes => {
        const size = {};
        state.sizesMap.forEach((key, index) => {
          size[key] = sizes[index];
        });
        return size;
      });
    },
    convertedSize(state) {
      if (
        !state.genderFrom ||
        !state.systemFrom ||
        !state.sizeFrom ||
        !state.genderTo ||
        !state.systemTo
      ) {
        return null;
      }
      const sizeMapIndexFrom = state.sizesMap.indexOf(
        this.buildSizeMapIndex(state.systemFrom.value, state.genderFrom),
      );
      // const sizeMapIndexTo = state.sizesMap.indexOf(
      //   this.buildSizeMapIndex(state.systemTo.value, state.genderTo.value),
      // );
      return state.sizes.find(size => size[sizeMapIndexFrom] === state.sizeFrom.value);
      // return size ? size[sizeMapIndexTo] : null;
    },
    buildSizeMapIndex() {
      return (system, gender) => {
        return system + (system === 'us' && gender ? gender : '');
      };
    },
    hasFootLength(state) {
      return state.brandSizes.filter(size => size.foot).length > 0;
    },
  },
})
