<template>
  <div class="author-bio-compact">
    <div v-if="enableHeader" class="author-bio-header">
      <span class="author-header-text">{{ $t('generic.author') }}</span>
      <span class="author-bio-separator" />
    </div>
    <div class="author-bio-top">
      <div v-if="author.img" class="author-bio-image">
        <img
          loading="lazy"
          width="70"
          height="70"
          :src="author.img"
          :srcset="author.imgSm + ' 200w, ' + author.imgLg + ' 345w'"
          sizes="70px"
          :alt="author.name"
        />
      </div>
      <div class="author-bio-details">
        <div class="author-bio-name">{{ author.name }}</div>
        <div v-if="author.url" class="author-bio-url">
          <a class="author-social-link" :href="author.url">
            <ExternalLinkIcon /> {{ $t('author.all_articles') }}
          </a>
        </div>
      </div>
    </div>
    <div class="author-bio-content" v-html="author.bio"></div>
  </div>
</template>

<script>
import ExternalLinkIcon from '@/components/icons/social/ExternalLinkIcon';

export default {
  components: {
    ExternalLinkIcon,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.author-bio-compact {
  .author-bio-header {
    color: #4d4d4d;
    margin: -3px 0 15px;
    .author-header-text {
      font-size: 12px;
      font-weight: bold;
    }
    .author-bio-separator {
      width: calc(100% - 41px);
      border-bottom: 1px solid $grey-dark;
      height: 1px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .author-bio-top {
    display: flex;
    gap: 15px;
    align-items: center;

    .author-bio-image {
      max-width: 70px;
      max-height: 70px;
    }

    .author-bio-details {
      .author-bio-name {
        font-size: 24px;
        color: #000;
        &:focus,
        &:hover {
          color: #000;
        }
      }
      .author-bio-url {
        margin-top: 8px;

        .author-social-link {
          display: inline-block;
          color: #000;
          &:visited {
            color: #000;
          }
          &:hover,
          &:focus {
            color: #0000ee;
            text-decoration: none;
          }
        }
      }
    }
  }
  .author-bio-content {
    margin-top: 15px;
  }
}
</style>
