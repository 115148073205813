<template>
  <div class="author-bio" id="author-bio">
    <div v-if="enableHeader" class="author-bio-header">
      <span class="author-header-text">{{ $t('generic.author') }}</span>
      <span class="author-bio-separator" />
    </div>
    <div class="author-bio-container">
      <a v-if="author.url" class="author-bio-image" :href="author.url" :title="author.name">
        <img
          v-if="author.img"
          loading="lazy"
          width="345"
          height="345"
          :src="author.img"
          :srcset="author.imgSm + ' 200w, ' + author.imgLg + ' 345w'"
          sizes="(max-width: 480px) 345px, (min-width: 481px) 200px, 200px"
          :alt="author.name"
        />
      </a>
      <div v-else-if="author.img" class="author-bio-image">
        <img
          loading="lazy"
          width="345"
          height="345"
          :src="author.img"
          :srcset="author.imgSm + ' 200w, ' + author.imgLg + ' 345w'"
          sizes="(max-width: 480px) 345px, (min-width: 481px) 200px, 200px"
          :alt="author.name"
        />
      </div>
      <div class="author-bio-details">
        <a v-if="enableName && author.url" class="author-bio-name" :href="author.url">{{
          author.name
        }}</a>
        <div v-else-if="enableName" class="author-bio-name">{{ author.name }}</div>
        <div class="author-bio-content" v-html="author.bio"></div>
        <div class="author-social-links">
          <a class="author-social-link" v-if="author.email" :href="'mailto:' + author.email">
            <EmailIcon /> {{ $t('author.email') }}
          </a>
          <a class="author-social-link" v-if="author.youtube_url" :href="author.youtube_url">
            <YoutubeIcon /> Youtube
          </a>
          <a class="author-social-link" v-if="author.facebook_url" :href="author.facebook_url">
            <FacebookLetterIcon /> Facebook
          </a>
          <a class="author-social-link" v-if="author.instagram_url" :href="author.instagram_url">
            <InstagramIcon /> Instagram
          </a>
          <a class="author-social-link" v-if="author.twitter_url" :href="author.twitter_url">
            <TwitterIcon /> Twitter
          </a>
          <a class="author-social-link" v-if="author.linkedin_url" :href="author.linkedin_url">
            <LinkedInIcon /> LinkedIn
          </a>
          <a class="author-social-link" v-if="author.personal_url" :href="author.personal_url">
            <ExternalLinkIcon /> {{ $t('author.website') }}
          </a>
          <a class="author-social-link" v-if="enableName && author.url" :href="author.url">
            <ExternalLinkIcon /> {{ $t('author.all_articles') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmailIcon from '@/components/icons/social/EmailIcon';
import YoutubeIcon from '@/components/icons/social/YoutubeIcon';
import FacebookLetterIcon from '@/components/icons/social/FacebookLetterIcon';
import InstagramIcon from '@/components/icons/social/InstagramIcon';
import TwitterIcon from '@/components/icons/social/TwitterIcon';
import LinkedInIcon from '@/components/icons/social/LinkedInIcon';
import ExternalLinkIcon from '@/components/icons/social/ExternalLinkIcon';

export default {
  components: {
    EmailIcon,
    YoutubeIcon,
    InstagramIcon,
    FacebookLetterIcon,
    TwitterIcon,
    LinkedInIcon,
    ExternalLinkIcon,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    enableHeader: {
      type: Boolean,
      default: true,
    },
    enableName: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.author-bio {
  margin-top: 25px;
  .author-bio-header {
    color: #4d4d4d;
    margin-bottom: 15px;
    .author-header-text {
      font-size: 12px;
      font-weight: bold;
    }
    .author-bio-separator {
      width: calc(100% - 41px);
      border-bottom: 1px solid $grey-dark;
      height: 1px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .author-bio-container {
    .author-bio-image {
      display: inline-block;
      vertical-align: top;
      @media (max-width: $break_xs_under) {
        display: block;
      }
      img {
        height: 200px;
        width: 200px;
        @media (max-width: $break_xs_under) {
          height: auto;
          width: auto;
        }
      }
    }
    .author-bio-details {
      width: calc(100% - 220px);
      margin-left: 15px;
      display: inline-block;
      @media (max-width: $break_xs_under) {
        width: 100%;
        display: block;
        margin-left: 0;
        margin-top: 16px;
      }
      .author-bio-name {
        font-size: 24px;
        color: #000;
        &:focus,
        &:hover {
          color: #000;
        }
        @media (max-width: $break_xs_under) {
          display: inline-block;
          margin-bottom: 5px;
        }
      }
      .author-bio-content {
        margin-bottom: 10px;
      }
      .author-social-links {
        text-align: left;
        .author-social-link {
          display: inline-block;
          margin-right: 16px;
          color: #000;
          &:visited {
            color: #000;
          }
          &:hover,
          &:focus {
            color: #0000ee;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
