<template>
  <div
    class="see-more"
    :class="{
      collapsed: !expand && expandable,
      'no-gradient': noGradient,
    }"
    :style="styleObj"
  >
    <slot />
    <div v-if="expandable" @click.stop="toggle" class="btn-more-container">
      <div class="btn btn-expand btn-default" :class="{ w100: fullWidth, 'pull-left': pullLeft }">
        {{ startWord || $t('core.see_more.see') }}
        <span v-if="!expand" class="more">{{ $t('core.see_more.more') }}</span>
        <span v-else class="less">{{ $t('core.see_more.less') }}</span>
        {{ subject.toLowerCase() }}
        <i class="arrow" :class="{ reversed: expand }" />
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";

export default {
  props: {
    startWord: {
      type: [String, Boolean],
      default: '',
    },
    subject: {
      type: String,
      default: '',
    },
    maxHeight: {
      type: Number,
      default: 350,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    scrollIntoViewOffset: {
      type: Number,
      default: 0,
    },
    pullLeft: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    noGradient: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: '',
    },
  },
  emits: ['expanded', 'collapsed'],
  data() {
    return {
      expand: false,
      expandable: true,
      afterExpandFinished: 0,
    };
  },
  computed: {
    styleObj() {
      return {
        // TODO: Moncef, please check this solution, maybe some code is redundant now
        'max-height': this.expand ? `none` : `${this.maxHeight}px`,
      };
    },
  },
  updated() {
    nextTick(() => {
      setTimeout(() => {
        isExpandable(this);
      });
    });
  },
  mounted() {
    this.expand = this.open;

    if (`requestIdleCallback` in window) {
      requestIdleCallback(() => isExpandable(this, 50));
    } else {
      isExpandable(this, 50);
    }
  },
  watch: {
    open(value) {
      this.expand = value;
    },
    expand() {
      if (this.location === 'SimpleComparison') {
        this.$gaEvents.productSimilarShowMore(this.expand);
      }
      if (this.location === 'BuyingGuide') {
        this.$gaEvents.guideSimilarShowMore(this.expand);
      }
    },
  },
  methods: {
    toggle() {
      this.afterExpandFinished = false; // permit to loose max-height='none'
      this.expand = !this.expand;
      this.$emit(this.expand ? 'expanded' : 'collapsed');

      if (!this.expand) {
        this.$el.scrollIntoView();
        window.scrollBy(0, this.scrollIntoViewOffset);
      }

      if (this.subject === 'facts') {
        this.$gaEvents.productFactsExpand(this.expand);
      }
    },
  },
};

function isExpandable(vm, timeout = 0) {
  nextTick(() => {
    setTimeout(() => {
      if (vm.$el.scrollHeight > vm.maxHeight * 1.1) {
        vm.expandable = true;
      }
    });
  }, timeout);
}
</script>

<style lang="scss" scoped>
.see-more {
  position: relative;
  // overflow: unset;
  padding-bottom: 46px;
  transition: max-height 0.25s ease-in;
  &.collapsed:not(.no-gradient) {
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 140px;
      position: absolute;
      z-index: 1;
      bottom: 4px;
      background: white;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%);
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .btn-more-container {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    z-index: 1;
    @media (max-width: $break_sm_under) {
      margin-top: 15px;
    }
    .btn.btn-expand {
      border: 2px solid $theme-color;
      border-radius: 50px;
      padding: 10px 52px;
      color: $theme-color;
      &:hover {
        color: #fff;
        background: $theme-color;
      }
      .arrow {
        @extend %arrow;
      }
    }
  }
}
</style>
