export const useFactsStore = defineStore('facts', {
  state: () => ({
    features: [],
    scaleRanks: [],
    scoreDistribution: [],
  }),

  getters: {
    ranksNumValues(state) {
      return state.scaleRanks.filter(
        scale =>
          Object.hasOwnProperty.call(scale, 'labels') &&
          Array.isArray(scale.labels) &&
          scale.labels.length === 0,
      );
    },
    ranksMultiOptionsValues(state) {
      return state.scaleRanks.filter(
        scale =>
          Object.hasOwnProperty.call(scale, 'labels') &&
          !Array.isArray(scale.labels) &&
          Object.keys(scale.labels).length > 0 &&
          // Remove all facts that are present in size guide section.
          ['toebox', 'heel-fit', 'forefoot-fit', 'size', 'fit'].indexOf(scale.slug) === -1,
      );
    },
  },

  actions: {
    fill({ features, scaleRanks, scoreDistribution }) {
      // to pick up change as there is no unique key.
      this.features = [];

      this.features = features;

      this.scaleRanks = scaleRanks;

      this.scoreDistribution = scoreDistribution;
    },
  },
})
