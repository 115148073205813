import { GA_PROPERTY_ID } from '../constants/global';

export default function analyticsVariation(experimentId) {
  if (window.gaData && window.gaData[GA_PROPERTY_ID] && window.gaData[GA_PROPERTY_ID].experiments) {
    experimentId = Object.keys(window.gaData[GA_PROPERTY_ID].experiments)[0];
    return parseInt(window.gaData[GA_PROPERTY_ID].experiments[experimentId]);
  }

  if (window.google_optimize) {
    return parseInt(window.google_optimize.get(experimentId) ?? '0');
  }

  return 0;
}

export function analyticsVariationDataReady(callback) {
  if (window?.gaData?.[GA_PROPERTY_ID]) {
    callback?.();
  } else {
    setTimeout(() => analyticsVariationDataReady(callback), 500);
  }
}
