<template>
  <svg
    :class="{ rotate }"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
  >
    <g transform="translate(-59 -141)">
      <g
        :class="{ circle }"
        data-name="Ellipse 1"
        transform="translate(59 141)"
        :fill="circleColor"
      >
        <circle cx="15" cy="15" r="15" stroke="none" />
        <circle cx="15" cy="15" r="14.5" fill="none" />
      </g>
      <g transform="translate(67 149)">
        <path
          d="M13.85,1.385,12.465,0,6.925,5.54,1.385,0,0,1.385l5.54,5.54L0,12.465,1.385,13.85l5.54-5.54,5.54,5.54,1.385-1.385L8.31,6.925Z"
          :fill="fill"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#f55310',
    },
    height: {
      type: String,
      default: '40px',
    },
    width: {
      type: String,
      default: '40px',
    },
    rotate: {
      type: Boolean,
      default: false,
    },
    circleColor: {
      type: String,
      default: '#fff',
    },
    circle: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  position: relative;
  top: 2px;
  cursor: pointer;

  &.rotate {
    transform: rotate(45deg);
  }

  g.circle {
    stroke: #ccc;
    stroke-width: 1px;
  }

  &:hover,
  &:focus {
    g.circle {
      stroke: $orange-background;
    }
  }
}
</style>
