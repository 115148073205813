import ellipsisText from "../utils/ellipsisText";

const EllipsisText = {
    mounted(el, binding, vnode) {
        ellipsisText(el);
    },
    updated(el, binding, vnode) {
        ellipsisText(el);
    }
}

export default EllipsisText;