import sizeHelper from '~/utils/size';
import { useRankingFilterStore } from "~/stores/rankingFilter";
import { useWidthsStore } from "~/stores/widths";
import { useMainStore } from "~/stores/main";
import { useSizesStore } from "~/stores/sizes";

export const useRankingFilterSizeStore = defineStore('rankingFilterSize', {

  getters: {
    sizeFilterGroup(state) {
      return this.allSizeFiltersGroups.find(group => group.visible);
    },
    widthFilterGroup(state) {
      return useRankingFilterStore().groups.find(group => group.slug === 'width');
    },
    allSizeFiltersGroups(state) {
      return useRankingFilterStore().groups.filter(group => group.slug.slice(0, 4) === 'size');
    },
    sizeFilterGroupOptionsFormatted(state) {
      return availableSizes => {
        if (Array.isArray(availableSizes) && !availableSizes.length) {
          return [];
        }
        const filter = this.sizeFilterGroup;
        if (!filter) {
          return [];
        }
        return filter.options.map(size => {
          // Availibility to check from: input, or global filters counters.
          const active = availableSizes
            ? availableSizes.hasOwnProperty(size.slug) && availableSizes[size.slug]
            : useRankingFilterStore().optionCounter(size);

          return {
            name: size.name,
            value: size.slug,
            active,
            system: size.slug.split('_')[0],
          };
        });
      };
    },
    widthFilterGroupOptionsFormatted(state) {
      const rankingFilter = useRankingFilterStore();
      const widths = useWidthsStore();
      return availableWidths => {
        const filter = this.widthFilterGroup;
        if (!filter) {
          return [];
        }
        return filter.options
          .map(width => {
            // Availability from input, or global counters.
            const active =
              availableWidths && availableWidths.length
                ? availableWidths.indexOf(width.slug) > -1
                : rankingFilter.optionCounter(width);

            const widthName = widths.nameForCurrentGender(width.slug) || width.name;

            return active
              ? {
                name: widthName,
                label: widthName,
                value: width.slug,
                active,
                position: width.position,
              }
              : null;
          })
          .filter(w => w)
          .sort((a, b) => a.position - b.position);
      };
    },
    selectedSizeOption(state) {
      return (
        useRankingFilterStore().selectedOptions.find(
          option => option.hasOwnProperty('group') && option.group.slug.slice(0, 4) === 'size',
        ) || { slug: '' }
      );
    },
    selectedWidthOption(state) {
      return (
        useRankingFilterStore().selectedOptions.find(
          option => option.group.slug.slice(0, 5) === 'width',
        ) || { slug: '' }
      );
    },
    selectedGenderOption(state) {
      return (
        useRankingFilterStore().selectedOptions.find(
          option => option.group.slug === 'gender',
        ) || { slug: 'm' }
      );
    },
    selectedSizeOptionSystem(state) {
      const option = this.selectedSizeOption;
      return option && option.slug ? option.slug.split('_')[0] : useMainStore().defaultSizeSystem;
    },
  },

  actions: {
    switchSizeSystem(newSystem) {
      const rankingFilter = useRankingFilterStore();
      const results = rankingFilter.selectedFilterOptions('gender');
      if (!results || !Array.isArray(results) || !results.length) {
        return;
      }

      // Single choice filter should have one option
      const genderShortSlug = results[0].slug;

      // Get the new filter slug to be visible.
      const newSizeGroupSlug = `sizes-${genderShortSlug}-${newSystem}`;

      const newSizeGroup = this.allSizeFiltersGroups.find(
        group => group.slug === newSizeGroupSlug,
      );

      // toggle selected option.
      const currentSizeOption = this.selectedSizeOption;
      const currentSizeGroup = this.sizeFilterGroup;

      // toggle visible size filter.
      if (newSizeGroup) {
        rankingFilter.setFilterGroupVisibility({ group: currentSizeGroup, visible: false });
        rankingFilter.setFilterGroupVisibility({ group: newSizeGroup, visible: true });
      }

      if (currentSizeOption && currentSizeOption.slug) {
        // const index = currentSizeGroup.options.indexOf(currentSizeOption);
        const newSizeSlug = sizeHelper.convertSizeSlug(
          useSizesStore().conversion,
          currentSizeOption.slug,
          genderShortSlug,
          newSystem,
        );
        if (newSizeSlug) {
          const newSizeOption = newSizeGroup.options.find(option => option.slug === newSizeSlug);
          rankingFilter.removeSelectedOption(currentSizeOption);
          rankingFilter.addSelectedOption({ option: newSizeOption });
        }
      }
    },
    switchSizeGroupByGender(newGenderOption) {
      // Current size option and group.
      const currentSizeOption = this.selectedSizeOption;
      const currentSizeGroup = this.sizeFilterGroup;
      if (!currentSizeGroup) {
        return;
      }

      const rankingFilter = useRankingFilterStore();
      const currentSystem = currentSizeGroup.slug.split('-').slice(-1)[0];

      // Get the new filter slug to be visible.
      const newSizeGroupSlug = `sizes-${newGenderOption.slug}-${currentSystem}`;

      const newSizeGroup = this.allSizeFiltersGroups.find(
        group => group.slug === newSizeGroupSlug,
      );

      // toggle visible size filter.
      if (newSizeGroup) {
        rankingFilter.toggleFilterGroupVisibility(currentSizeGroup);
        rankingFilter.toggleFilterGroupVisibility(newSizeGroup);
      }

      if (currentSizeOption && currentSizeOption.slug) {
        const newSizeSlug = sizeHelper.convertSizeSlug(
          useSizesStore().conversion,
          currentSizeOption.slug,
          newGenderOption.slug,
          currentSystem,
        );
        const newSizeOption = newSizeGroup.options.find(option => option.slug === newSizeSlug);
        if (newSizeOption) {

          // Report only options changed by user.
          currentSizeOption.reportOptionChange = false;
          newSizeOption.reportOptionChange = false;

          rankingFilter.removeSelectedOption(currentSizeOption);
          rankingFilter.addSelectedOption({ option: newSizeOption });
        }
      }
    },
  },
})
