import { ref, onMounted, nextTick } from "vue";

export const useAfterScroll = () => {
  const rrScrolled = ref(false);

  onMounted(() => {
    nextTick(() => {
      setTimeout(() => {
        window.addEventListener('scroll', () => rrScrolled.value = true, {
          capture: true,
          once: true,
        });
      })
    })
  })

  return {
    rrScrolled,
  };
};
