const valueOnHundred = value => `${Math.round(value)}/100`;

const usersScore = value => valueOnHundred(value);

const expertScore = value => (value === 0 ? 'n/a' : valueOnHundred(value));

const formatters = {
  users_score: usersScore,
  expert_score: expertScore,
};

export default class {
  static formatValue(factSlug, value) {
    return formatters?.[factSlug]?.(value) || value;
  }
  static buildFactValueToDisplay(fact) {
    let value;

    const nonFeatureValue = fact?.value;
    const nonFeatureDoNotExistsOrEmpty =
      nonFeatureValue === '' ||
      nonFeatureValue === null ||
      nonFeatureValue === undefined ||
      (Array.isArray(nonFeatureValue) && nonFeatureValue.length === 0);
    if (nonFeatureDoNotExistsOrEmpty) return ['N/A'];

    // Several facts values types are sent from api
    // Need to check which fact so grab its correct
    // data to display.
    if (Array.isArray(nonFeatureValue)) {
      value = nonFeatureValue.map(v => {
        // Example of rating:
        //    "Plush (3/3)" in cushioning as there are three options "firm, balance,
        //     plush".This is to help the user that it's the highest
        //    rating in cushioning."
        const ratingStrength =
          fact?.max_strength && v.value ? ` (${v.value}/${fact.max_strength})` : '';
        return v.name + ratingStrength || 'N/A';
      });
    } else if (typeof nonFeatureValue === 'string') {
      value = [nonFeatureValue || 'N/A'];
    } else if (typeof nonFeatureValue === 'number') {
      value = [!isNaN(nonFeatureValue) ? nonFeatureValue + (fact.units || '') : 'N/A'];
    } else if (typeof nonFeatureValue === 'object') {
      value = [nonFeatureValue.name || 'N/A'];
    } else {
      value = [nonFeatureValue || 'N/A'];
    }

    return value;
  }
  static orderByPopularity(a, b) {
    if (a.position === null) {
      return 1;
      // eslint-disable-next-line no-else-return
    } else if (b.position === null) {
      return -1;
    }
    return a?.position - b?.position;
  }
}
