export default {
  convertSizeSlug(systemConversion, oldSlug, oldGender, newSystem, newGender) {
    const gender = newGender || oldGender;
    const nameParts = oldSlug.split('_');

    if (nameParts.length >= 2) {
      const oldSystem = nameParts[0];
      const oldSizeVal = parseInt(nameParts[1], 10);

      let newSizeVal = oldSizeVal;

      if (oldSystem !== newSystem) {
        // convert to base (`uk` since it is equal in both men and women, and easily converts to us)
        let baseSizeVal = oldSizeVal;

        if (oldSystem === 'us') {
          baseSizeVal = oldGender === 'w' ? oldSizeVal - 20 : oldSizeVal - 10;
        }

        if (oldSystem === 'eu') {
          const idx = systemConversion[oldGender].eu.indexOf(oldSizeVal / 10);
          baseSizeVal = 10 * systemConversion[oldGender].uk[idx];
        }

        // convert from base
        newSizeVal = baseSizeVal;

        if (newSystem === 'us') {
          newSizeVal = gender === 'w' ? baseSizeVal + 20 : baseSizeVal + 10;
        }

        if (newSystem === 'eu') {
          const idx = systemConversion[gender].uk.indexOf(baseSizeVal / 10);
          newSizeVal = 10 * systemConversion[gender].eu[idx];
        }
      }

      return `${newSystem}_${newSizeVal}`;
    }
    return '';
  },
};
