<template>
<div class="rr-slider">
  <component
    :is="slide.link ? 'a' : 'div'"
    v-for="slide in props.slides"
    :href="slide.link || null"
    class="rr-slider-slide"
  >
    <div v-if="slide.image" class="rr-slide-image">
      <TheImage profile="thumb-sm" :image="slide.image" />
    </div>
    <div v-if="slide.name" class="rr-slide-text" v-text="slide.name"></div>
    <div v-if="slide.date" class="rr-slide-date text-muted">
      <time :datetime="slide.date">{{ $d(slide.date, 'short', $i18n.localeProperties.language) }}</time>
    </div>
  </component>
</div>
</template>

<script setup>
const props = defineProps({
  slides: {
    type: Array,
    required: true,
  },
})
</script>

<style lang="scss">
.rr-slider {
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  @media (max-width: $break-sm_under) {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
  }
}
.rr-slider-slide {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  max-width: 160px;
  scroll-snap-align: center;
  scroll-snap-stop: always;

  &:focus, &:hover {
    text-decoration: none;
    .rr-slide-text {
      text-decoration: underline;
    }
  }
}
.rr-slide-text {
  color: $black-link-color;
}
.rr-slide-image {
  aspect-ratio: 3 / 2;
  margin-bottom: 10px;
}
</style>