import { TOP_HEADER_OFFSET } from '@/constants/global';
import { nextTick } from "vue";

export default {
  mounted(el, binding, vnode) {
    el.addEventListener(
      'click',
      () => {
        try {
          const offset = -TOP_HEADER_OFFSET * 1.5 - (binding.arg ?? 0);
          nextTick(() =>
            setTimeout(() => {
              document.getElementById(binding.value)?.scrollIntoView();
              window.scrollBy(0, offset);
            }),
          );
        } catch (e) {
          console.log(e);
        }
      },
      { capture: true },
    );
  },
};
