import { useMainStore } from "~/stores/main";
import { useExperimentStore } from "~/stores/experiment";

export const getHeaders = () => {
  const headers = {};
  const main = useMainStore();
  const experiment = useExperimentStore();

  headers['RR-Country'] = main.countryCode;
  headers['RR-Bot-Continent'] = main.botContinent;
  headers['RR-Locale'] = main.locale;
  headers['RR-User'] = `${main.gender};${main.width}`;

  if (main.size) {
    headers['RR-User'] += `;${main.size}`;
  }

  headers['RR-Ab'] = `${experiment.variation}`;

  // if (main.partnerId) {
  //   headers['RR-Partner'] = main.partnerId;
  // }

  if (main.isApiCacheDisabled) {
    headers['RR-Dc'] = '1';
  }

  return headers;
};

export const getBaseUrl = () => {
  const config = useRuntimeConfig();
  return process.server ? config.apiUrl : config.public.apiUrlBrowser;
};
